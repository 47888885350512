import { Icon } from '../Icon';

export const TypeAudioIcon: Icon = (props) => (
  <svg width="24" height="24" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18 2.28637C18 0.860305 16.7804 -0.217575 15.3774 0.0375277L6 2C4.91316 2.19761 4 3.43057 4 4.53522V18H6.00056V7.10667L16 5V15H18V2.28637Z"
      fill="#FFC295"
    />
    <circle cx="3.5" cy="17.5" r="3.5" fill="#F07A55" />
    <circle cx="14.5" cy="14.5" r="3.5" fill="#F07A55" />
  </svg>
);
