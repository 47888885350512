import { createContext, FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { LessonModel, LessonModuleModel, LessonResultModel, LessonTaskModel, LessonUnitModel } from '../models';
import { getLessonModuleByTask, getLessonUnitByModule, isLessonSolved, XApiMessageHandler } from '../utils/LessonUtils';
import { useXApi } from './XApiProvider';
import { ObjectType, Verb } from '../constants';
import { useIsomorphicLayoutEffect } from '../hooks';

type LessonContext = {
  role: 'teacher' | 'student';
  lesson?: LessonModel;
  solved: boolean;
  activeUnit?: LessonUnitModel;
  activeModule?: LessonModuleModel;
  activeTask?: LessonTaskModel;
  result: LessonResultModel;
  onUnitSelect: (unit?: LessonUnitModel) => void;
  onModuleSelect: (module?: LessonModuleModel) => void;
  onTaskSelect: (task?: LessonTaskModel, setModule?: boolean) => void;
  onTaskComplete: (task: LessonTaskModel) => void;
  onStart: () => void;
  onFinish: (force?: boolean) => void;
  onXApiMessage: XApiMessageHandler;
};

const LessonContext = createContext<LessonContext | undefined>(undefined);

type LessonContextProviderProps = {
  lesson?: LessonModel;
  task?: LessonTaskModel;
  role: 'teacher' | 'student';
  initialResult?: LessonResultModel;
  onResultChange?: (result: LessonResultModel) => void;
  onFinish?: () => void;
};

export const LessonContextProvider: FC<LessonContextProviderProps> = ({
  lesson,
  task,
  role,
  initialResult,
  onResultChange,
  onFinish,
  children
}) => {
  const { xApi } = useXApi();
  const loadedLesson = useRef<typeof lesson>(lesson);

  const [activeLesson, setActiveLesson] = useState(loadedLesson.current);
  const [activeUnit, setActiveUnit] = useState<LessonUnitModel>();
  const [activeModule, setActiveModule] = useState<LessonModuleModel>();
  const [activeTask, setActiveTask] = useState<LessonTaskModel | undefined>(task);
  const [result, setResult] = useState<LessonResultModel>({});

  const solved = useMemo(() => isLessonSolved(activeLesson, result), [activeLesson, result]);

  const handleUnitSelect = useCallback(
    (unit?: LessonUnitModel) => {
      const [firstModule] = unit?.modules || [];

      setActiveUnit(unit);
      setActiveModule(firstModule);
    },
    [activeModule]
  );

  const handleModuleSelect = useCallback(
    (module?: LessonModuleModel) => {
      const unit = getLessonUnitByModule(activeLesson, module);

      setActiveUnit(unit);
      setActiveModule(module);
      setActiveTask(undefined);
    },
    [activeLesson, activeModule]
  );

  const handleTaskSelect = useCallback(
    (task?: LessonTaskModel, setModule = false) => {
      if (setModule) {
        const module = getLessonModuleByTask(activeLesson, task);
        const unit = getLessonUnitByModule(activeLesson, module);

        setActiveUnit(unit);
        setActiveModule(module);
      }

      setActiveTask(task);
    },
    [activeLesson]
  );

  const handleTaskComplete = useCallback(
    (task: LessonTaskModel) => {
      const newResult = { ...result, [task.uid]: true };

      setResult(newResult);

      onResultChange?.(newResult);
    },
    [result, onResultChange]
  );

  const handleStart = useCallback(async () => {
    const [firstUnit] = activeLesson?.units || [];

    handleUnitSelect(firstUnit);

    if (!activeLesson) return;

    try {
      await xApi.send({
        role,
        verb: Verb.Opened,
        object: { type: ObjectType.Lesson, id: activeLesson.id }
      });
    } catch (e) {
      console.debug('[LessonContextProvider] Unable to send xAPI message.');
    }
  }, [activeLesson, handleUnitSelect, role, xApi]);

  const handleFinish = useCallback(
    (force = false) => {
      if (!force && !solved) {
        return;
      }

      setActiveUnit(undefined);
      setActiveModule(undefined);
      setActiveTask(undefined);

      onFinish?.();
    },
    [solved, onFinish]
  );

  const handleXApiMessage: XApiMessageHandler = useCallback(
    async (type, id, verb, info, result) => {
      try {
        await xApi.send({
          role,
          verb,
          object: { type, id, info },
          result
        });
      } catch (e) {
        console.debug('[LessonContextProvider] Unable to send xAPI message.');
      }
    },
    [role, xApi]
  );

  useIsomorphicLayoutEffect(() => {
    loadedLesson.current = lesson;

    setActiveLesson(loadedLesson.current);
    setResult(initialResult || {});
  }, [lesson, initialResult]);

  return (
    <LessonContext.Provider
      value={{
        lesson: activeLesson,
        role,
        solved,
        activeUnit,
        activeModule,
        activeTask,
        result,
        onUnitSelect: handleUnitSelect,
        onModuleSelect: handleModuleSelect,
        onTaskSelect: handleTaskSelect,
        onTaskComplete: handleTaskComplete,
        onStart: handleStart,
        onFinish: handleFinish,
        onXApiMessage: handleXApiMessage
      }}
    >
      {children}
    </LessonContext.Provider>
  );
};

export const useLesson = () => {
  const context = useContext(LessonContext);

  if (!context) {
    throw new Error('useLesson must be used within a LessonContextProvider.');
  }

  return context;
};
