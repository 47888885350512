import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.ProbabilityAndStatistics];

const ProbabilityAndStatisticsIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="0 0 240 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1970_14829)">
      <path
        d="M101.55 111.224H82.5891C80.0634 111.224 77.997 109.162 77.997 106.643V89.1922H70.5166C67.9909 89.1922 65.9245 87.131 65.9245 84.6118V65.1608H56.7583H35.5921C33.0665 65.1608 31 63.0996 31 60.5804C31 58.0612 33.0665 56 35.5921 56H57.0544H70.5166C73.0423 56 75.1088 58.0612 75.1088 60.5804V80.0314H82.5891C85.1148 80.0314 87.1813 82.0926 87.1813 84.6118V102.063H96.9577V84.6118C96.9577 82.0926 99.0242 80.0314 101.55 80.0314H122.214C124.74 80.0314 126.807 82.0926 126.807 84.6118C126.807 87.131 124.74 89.1922 122.214 89.1922H106.142V106.643C106.142 109.162 104.076 111.224 101.55 111.224Z"
        fill={primaryColor}
      />
      <path
        d="M43.1945 44.9579L44.9674 43.3368V167.147H56.7871V43.3368L58.5601 45.1053C59.7421 46.1368 61.2195 46.7263 62.697 46.7263C64.1745 46.7263 65.6519 46.1368 66.8339 44.9579C69.1978 42.6 69.1978 38.9158 66.8339 36.5579L55.0142 24.7684C52.6503 22.4105 48.9566 22.4105 46.5927 24.7684L34.773 36.5579C32.409 38.9158 32.409 42.6 34.773 44.9579C37.1369 47.3158 40.9783 47.3158 43.1945 44.9579Z"
        fill={tertiaryColor}
      />
      <path
        d="M193.19 166.194L194.811 167.967L72.0001 167.967L72.0001 179.787L194.811 179.787L193.042 181.56C192.011 182.742 191.421 184.22 191.421 185.697C191.421 187.174 192.011 188.652 193.19 189.834C195.547 192.198 199.232 192.198 201.59 189.834L213.379 178.014C215.737 175.65 215.737 171.957 213.379 169.593L201.59 157.773C199.232 155.409 195.547 155.409 193.19 157.773C190.832 160.137 190.832 163.978 193.19 166.194Z"
        fill={tertiaryColor}
      />
      <path
        d="M97.2341 180H54.1927C46.7766 180 44.8193 178.058 44.8193 170.661V128.706C44.8193 121.309 46.7766 119.367 54.1927 119.367H97.2341C104.65 119.367 105.607 121.309 105.607 128.706V170.661C105.607 178.058 104.65 180 97.2341 180Z"
        fill={secondaryColor}
      />
      <path
        d="M66.5 145C64 145 62 143 62 140.5C62 138 64 136 66.5 136C69 136 71 138 71 140.5C71 143 69 145 66.5 145Z"
        fill={primaryColor}
      />
      <path
        d="M66.5 161C64 161 62 159 62 156.5C62 154 64 152 66.5 152C69 152 71 154 71 156.5C71 159 69 161 66.5 161Z"
        fill={primaryColor}
      />
      <path
        d="M83.168 145C80.668 145 78.668 143 78.668 140.5C78.668 138 80.668 136 83.168 136C85.668 136 87.668 138 87.668 140.5C87.668 143 85.668 145 83.168 145Z"
        fill={primaryColor}
      />
      <path
        d="M83.5 161C81 161 79 159 79 156.5C79 154 81 152 83.5 152C86 152 88 154 88 156.5C88 159 86 161 83.5 161Z"
        fill={primaryColor}
      />
      <path
        d="M163.415 180H121.373C113.957 180 112 178.058 112 170.661V129.706C112 122.309 113.957 119.367 121.373 119.367H163.415C170.831 119.367 172.788 122.309 172.788 129.706V169.661C172.788 177.058 170.831 180 163.415 180Z"
        fill={primaryColor}
      />
      <path
        d="M132.5 163.615C130 163.615 128 161.615 128 159.115C128 156.615 130 154.615 132.5 154.615C135 154.615 137 156.615 137 159.115C137 161.615 135 163.615 132.5 163.615Z"
        fill={tertiaryColor}
      />
      <path
        d="M153.168 144C150.668 144 148.668 142 148.668 139.5C148.668 137 150.668 135 153.168 135C155.668 135 157.668 137 157.668 139.5C157.668 142 155.668 144 153.168 144Z"
        fill={tertiaryColor}
      />
      <path
        d="M142.834 153.307C140.334 153.307 138.334 151.307 138.334 148.807C138.334 146.307 140.334 144.307 142.834 144.307C145.334 144.307 147.334 146.307 147.334 148.807C147.334 151.307 145.334 153.307 142.834 153.307Z"
        fill={tertiaryColor}
      />
      <path
        d="M142.446 112.699C138.92 112.699 135.516 111.366 132.963 108.819L108.89 84.8084C106.337 82.2619 105 78.9877 105 75.3497C105 71.7118 106.337 68.4376 108.89 65.891L132.963 41.8805C135.516 39.3339 138.798 38 142.446 38C146.093 38 149.375 39.3339 151.928 41.8805L176.001 65.891C178.554 68.4376 179.891 71.7118 179.891 75.3497C179.891 78.9877 178.554 82.2619 176.001 84.8084L151.928 108.819C149.375 111.366 145.971 112.699 142.446 112.699Z"
        fill={secondaryColor}
      />
      <path
        d="M128.5 79C127.313 79 126.225 78.6022 125.335 77.7072C123.555 75.9171 123.555 73.1326 125.335 71.3425C127.115 69.5525 129.885 69.5525 131.665 71.3425C133.445 73.1326 133.445 75.9171 131.665 77.7072C130.775 78.6022 129.588 79 128.5 79Z"
        fill={primaryColor}
      />
      <path
        d="M142.5 93.1035C141.3 93.1035 140.2 92.6035 139.3 91.8035C138.4 91.0035 138 89.8035 138 88.6035C138 87.4035 138.5 86.3035 139.3 85.4035C140.1 84.5035 141.3 84.1035 142.5 84.1035C143.7 84.1035 144.8 84.6035 145.7 85.4035C146.6 86.3035 147 87.4035 147 88.6035C147 89.8035 146.5 90.9035 145.7 91.8035C144.8 92.7035 143.7 93.1035 142.5 93.1035Z"
        fill={primaryColor}
      />
      <path
        d="M142.5 65C141.3 65 140.2 64.5 139.3 63.7C138.4 62.9 138 61.7 138 60.5C138 59.3 138.5 58.2 139.3 57.3C140.1 56.4 141.3 56 142.5 56C143.7 56 144.8 56.5 145.7 57.3C146.6 58.2 147 59.3 147 60.5C147 61.7 146.5 62.8 145.7 63.7C144.8 64.5 143.7 65 142.5 65Z"
        fill={primaryColor}
      />
      <path
        d="M142.5 79C141.313 79 140.225 78.5 139.335 77.7C137.555 75.9 137.555 73.1 139.335 71.3C140.126 70.4 141.313 70 142.5 70C143.687 70 144.775 70.5 145.665 71.3C147.445 73.1 147.445 75.9 145.665 77.7C144.775 78.5 143.687 79 142.5 79Z"
        fill={primaryColor}
      />
      <path
        d="M156.003 79C154.816 79 153.728 78.6022 152.838 77.7072C151.058 75.9171 151.058 73.1326 152.838 71.3425C154.618 69.5525 157.388 69.5525 159.168 71.3425C160.948 73.1326 160.948 75.9171 159.168 77.7072C158.278 78.6022 157.19 79 156.003 79Z"
        fill={primaryColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1970_14829"
        x="1"
        y="23"
        width="244.147"
        height="228.607"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.4 0 0 0 0 0.588235 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1970_14829" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1970_14829" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default ProbabilityAndStatisticsIcon;
