import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Chemistry];

const ChemistryIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.8233 18.562C28.8234 18.9154 28.5401 19.2019 28.1908 19.202C27.8415 19.202 27.5583 18.9154 27.5583 18.562C27.5584 18.2087 27.8417 17.9219 28.1908 17.9219C28.54 17.9219 28.8232 18.2086 28.8233 18.562V18.562Z"
      fill={primaryColor}
    />
    <path
      d="M49.9616 17.7304C49.9621 19.7452 48.3277 21.3791 46.312 21.3793C44.2963 21.3793 42.6619 19.7452 42.6624 17.7304C42.6628 15.7156 44.2973 14.0803 46.312 14.0801C48.3267 14.0801 49.9611 15.7151 49.9616 17.7304V17.7304Z"
      fill={secondaryColor}
    />
    <path
      d="M30.8579 1.92049C30.8582 2.98052 30.0084 3.84011 28.9605 3.84024C27.9126 3.84024 27.0629 2.98052 27.0631 1.92049C27.0634 0.860464 27.9131 0.000124886 28.9605 0C30.0079 0 30.8577 0.860214 30.8579 1.92049Z"
      fill={tertiaryColor}
    />
    <path
      d="M16.1194 12.4817C16.1201 15.3084 13.8542 17.6007 11.0597 17.601C8.26526 17.601 5.99934 15.3084 6 12.4817C6.00066 9.65492 8.26657 7.36068 11.0597 7.36035C13.8529 7.36035 16.1188 9.65426 16.1194 12.4817V12.4817Z"
      fill={tertiaryColor}
    />
    <path
      d="M44.5719 35.0195L34.0549 15.4146V11.084H24.0598V15.4139L13.5423 35.0195C12.3371 37.266 13.9442 39.9996 16.4702 39.9996H41.6439C44.1699 39.9996 45.7771 37.266 44.5719 35.0195Z"
      fill={tertiaryColor}
    />
    <path
      d="M41.6436 40.0003H16.4699C13.9436 40.0003 12.3365 37.2669 13.5418 35.0208L18.9253 24.9867C19.5159 24.7569 20.1328 24.589 20.7725 24.4896C23.6504 24.0421 26.9388 25.9512 28.8701 27.0726C30.8003 28.1929 34.0898 30.1021 36.9676 29.6546C38.3814 29.4348 39.6828 28.8824 40.839 28.0626L44.5717 35.0208C45.777 37.2669 44.1699 40.0003 41.6436 40.0003Z"
      fill={primaryColor}
    />
    <path
      d="M41.6436 40.0003H16.4699C13.9436 40.0003 12.3365 37.2669 13.5418 35.0208L17.1741 28.2504C18.2604 28.9674 19.469 29.4525 20.7725 29.6546C23.6504 30.1021 26.9388 28.1929 28.8701 27.0726C30.8003 25.9512 34.0898 24.0421 36.9676 24.4896C37.7843 24.6166 38.5638 24.8542 39.2985 25.1911L44.5717 35.0208C45.777 37.2669 44.1699 40.0003 41.6436 40.0003V40.0003Z"
      fill={secondaryColor}
    />
    <path
      d="M34.0484 31.0117C34.5007 31.0117 34.8672 30.6408 34.8672 30.1831C34.8672 29.7255 34.5007 29.3545 34.0484 29.3545C33.5962 29.3545 33.2296 29.7255 33.2296 30.1831C33.2296 30.6408 33.5962 31.0117 34.0484 31.0117Z"
      fill={quaternaryColor}
    />
    <path
      d="M37.805 36.2295C38.2572 36.2295 38.6238 35.8585 38.6238 35.4009C38.6238 34.9433 38.2572 34.5723 37.805 34.5723C37.3528 34.5723 36.9862 34.9433 36.9862 35.4009C36.9862 35.8585 37.3528 36.2295 37.805 36.2295Z"
      fill={tertiaryColor}
    />
    <path
      d="M25.4912 34.5596C25.9434 34.5596 26.31 34.1886 26.31 33.731C26.31 33.2733 25.9434 32.9023 25.4912 32.9023C25.039 32.9023 24.6724 33.2733 24.6724 33.731C24.6724 34.1886 25.039 34.5596 25.4912 34.5596Z"
      fill={tertiaryColor}
    />
    <path
      d="M19.2298 36.0205C19.682 36.0205 20.0486 35.6495 20.0486 35.1919C20.0486 34.7343 19.682 34.3633 19.2298 34.3633C18.7776 34.3633 18.411 34.7343 18.411 35.1919C18.411 35.6495 18.7776 36.0205 19.2298 36.0205Z"
      fill={primaryColor}
    />
    <path
      d="M10.6762 29.051C10.4596 29.051 10.2432 28.9684 10.0778 28.8033L8.30086 27.0262C7.97035 26.6957 7.97035 26.1599 8.30086 25.8295L10.0778 24.0525C10.4083 23.7221 10.944 23.7221 11.2745 24.0525L13.0514 25.8295C13.3819 26.16 13.3819 26.6958 13.0514 27.0262L11.2745 28.8033C11.1093 28.9684 10.8929 29.051 10.6762 29.051Z"
      fill={tertiaryColor}
    />
    <path
      d="M42.4965 8.97775C42.3458 8.97775 42.1952 8.9203 42.0802 8.80539L40.8441 7.56919C40.6142 7.33925 40.6142 6.96651 40.8441 6.73669L42.0802 5.50049C42.3101 5.27067 42.6827 5.27067 42.9127 5.50049L44.1488 6.73669C44.3787 6.96662 44.3787 7.33937 44.1488 7.56919L42.9127 8.80539C42.7978 8.92018 42.6471 8.97775 42.4965 8.97775Z"
      fill={tertiaryColor}
    />
    <rect x="21.7391" y="8.29395" width="14.5985" height="2.96532" rx="1.48266" fill={tertiaryColor} />
  </svg>
);

export default ChemistryIcon;
