export enum LessonTaskPlaybackMethodCode {
  Video = 'video',
  Audio = 'audio',
  Test = 'test',
  Simulator = 'simulator',
  Interaction = 'interaction',
  Text = 'text',
  MiniGames = 'minigames',
  Image = 'image'
}
