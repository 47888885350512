import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Maths];

const MathsIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.99414 37.5931C7.90456 37.5924 7.78511 37.5913 7.69522 37.5609C7.39571 37.4692 7.15505 37.289 7.00338 37.0501L2.65856 29.5001C2.35464 28.963 2.52734 28.3112 3.06163 27.9891L47.9572 2.47906C48.2245 2.33287 48.5229 2.30573 48.8221 2.36768C49.1216 2.45933 49.3623 2.63956 49.5142 2.90811L53.859 10.4582C54.163 10.9953 53.9903 11.6471 53.4557 11.9395L8.56006 37.4495C8.38177 37.5371 8.20318 37.5949 7.99414 37.5931Z"
      fill={primaryColor}
    />
    <path
      d="M46.5606 3.07804L47.6482 4.93903C47.7688 5.11925 47.9481 5.24018 48.1558 5.24184C48.2448 5.24255 48.3631 5.21361 48.4515 5.15457C48.7462 4.97766 48.8314 4.61983 48.6506 4.3495L47.5629 2.48851L46.5606 3.07804Z"
      fill={secondaryColor}
    />
    <path
      d="M45.5434 6.14218C45.6639 6.32238 45.8432 6.44331 46.0509 6.44496C46.14 6.44567 46.2583 6.41674 46.3467 6.3577C46.6415 6.18081 46.7267 5.82301 46.5458 5.5527L45.4579 3.69187L44.4258 4.28112L45.5434 6.14218Z"
      fill={secondaryColor}
    />
    <path
      d="M43.4389 7.34453C43.5595 7.52474 43.7387 7.64566 43.9464 7.64732C44.0355 7.64803 44.1538 7.6191 44.2422 7.56005C44.537 7.38316 44.6222 7.02535 44.4413 6.75504L43.3834 4.92429L42.3513 5.51355L43.4389 7.34453Z"
      fill={secondaryColor}
    />
    <path
      d="M9.67086 27.7927C9.75985 27.7934 9.87817 27.7645 9.96652 27.7054C10.2612 27.5285 10.3464 27.1706 10.1656 26.9003L8.92693 24.769L7.89515 25.3584L9.16345 27.4899C9.284 27.6701 9.4929 27.7913 9.67086 27.7927Z"
      fill={secondaryColor}
    />
    <path
      d="M6.8523 26.0014L5.82056 26.5908L7.05884 28.6922C7.17939 28.8725 7.35862 28.9934 7.56623 28.9951C7.65521 28.9958 7.77354 28.9668 7.86188 28.9078C8.15657 28.7309 8.24174 28.373 8.06092 28.1026L6.8523 26.0014Z"
      fill={secondaryColor}
    />
    <path
      d="M4.74788 27.2034L3.74597 27.793L4.95438 29.8943C5.0749 30.0746 5.2541 30.1956 5.46168 30.1972C5.55065 30.1979 5.66895 30.169 5.75728 30.1099C6.05192 29.933 6.13708 29.5751 5.95629 29.3047L4.74788 27.2034Z"
      fill={secondaryColor}
    />
    <path
      d="M13.3728 25.0836C13.4934 25.2639 13.6726 25.3848 13.8802 25.3865C13.9692 25.3872 14.0875 25.3582 14.1759 25.2992C14.4706 25.1223 14.5557 24.7644 14.3749 24.494L13.1363 22.3628L12.1045 22.9521L13.3728 25.0836Z"
      fill={secondaryColor}
    />
    <path
      d="M11.2681 26.2867C11.3886 26.467 11.5679 26.5879 11.7755 26.5896C11.8645 26.5903 11.9828 26.5614 12.0711 26.5023C12.3658 26.3254 12.451 25.9675 12.2702 25.6972L11.0315 23.5659L9.99976 24.1552L11.2681 26.2867Z"
      fill={secondaryColor}
    />
    <path
      d="M26.308 19.3638L16.7837 19.2844C15.8015 19.2762 14.9897 18.4644 14.9797 17.4804L14.8827 7.93851C14.8727 6.95451 15.6681 6.15612 16.6503 6.16431L26.1746 6.24373C27.1567 6.25192 27.9685 7.06372 27.9785 8.04772L28.0756 17.5896C28.0856 18.5736 27.2902 19.372 26.308 19.3638Z"
      fill={secondaryColor}
    />
    <path
      d="M26.3075 33.7944L16.7832 33.715C15.801 33.7068 14.9892 32.895 14.9792 31.911L14.8822 22.3692C14.8722 21.3852 15.6676 20.5868 16.6498 20.595L26.1741 20.6744C27.1563 20.6826 27.968 21.4944 27.9781 22.4784L28.0751 32.0202C28.0851 33.0042 27.2897 33.8026 26.3075 33.7944Z"
      fill={tertiaryColor}
    />
    <path
      d="M40.7395 19.3638L31.2152 19.2844C30.233 19.2762 29.4213 18.4644 29.4112 17.4804L29.3142 7.93851C29.3042 6.95451 30.0996 6.15612 31.0818 6.16431L40.6061 6.24373C41.5883 6.25192 42.4001 7.06372 42.4101 8.04772L42.5071 17.5896C42.5171 18.5736 41.7217 19.372 40.7395 19.3638Z"
      fill={tertiaryColor}
    />
    <path
      d="M40.7395 33.7945L31.2152 33.715C30.233 33.7068 29.4213 32.895 29.4112 31.911L29.3142 22.3692C29.3042 21.3852 30.0996 20.5868 31.0818 20.595L40.6061 20.6744C41.5883 20.6826 42.4001 21.4944 42.4101 22.4784L42.5071 32.0202C42.5171 33.0043 41.7217 33.8026 40.7395 33.7945Z"
      fill={secondaryColor}
    />
    <path
      d="M38.605 12.208L33.2034 12.1667C32.8733 12.1642 32.6061 12.4214 32.6097 12.7382C32.6132 13.0551 32.8861 13.3164 33.2162 13.3189L38.6177 13.3602C38.9478 13.3627 39.215 13.1055 39.2115 12.7887C39.208 12.4718 38.9351 12.2105 38.605 12.208Z"
      fill={secondaryColor}
    />
    <path
      d="M24.1513 12.1893L22.0679 12.172L22.0467 10.0847C22.0433 9.75669 21.7727 9.48609 21.4453 9.48336C21.118 9.48063 20.8528 9.74676 20.8561 10.0748L20.8774 12.162L18.7939 12.1447C18.4665 12.1419 18.2014 12.4081 18.2047 12.7361C18.2081 13.0641 18.4787 13.3347 18.8061 13.3374L20.8895 13.3548L20.9107 15.4421C20.9141 15.7701 21.1847 16.0407 21.5121 16.0434C21.8395 16.0461 22.1046 15.78 22.1013 15.452L22.08 13.3647L24.1635 13.3821C24.4909 13.3848 24.756 13.1187 24.7527 12.7907C24.7493 12.4627 24.4787 12.1921 24.1513 12.1893Z"
      fill={primaryColor}
    />
    <path
      d="M35.7704 26.8857C36.346 26.8907 36.808 26.4276 36.8023 25.8514C36.7967 25.2752 36.3256 24.8041 35.75 24.7991C35.1745 24.7941 34.7125 25.2571 34.7181 25.8333C34.7237 26.4095 35.1949 26.8807 35.7704 26.8857Z"
      fill={primaryColor}
    />
    <path
      d="M35.7704 29.8925C36.346 29.8975 36.808 29.4344 36.8023 28.8582C36.7967 28.282 36.3256 27.8109 35.75 27.8059C35.1745 27.8009 34.7125 28.264 34.7181 28.8402C34.7237 29.4164 35.1949 29.8875 35.7704 29.8925Z"
      fill={primaryColor}
    />
    <path
      d="M21.6421 28.6875C22.3821 28.6939 22.9761 28.0986 22.9689 27.3578C22.9616 26.617 22.3559 26.0112 21.6159 26.0048C20.8759 25.9984 20.2819 26.5937 20.2891 27.3345C20.2964 28.0754 20.9021 28.6811 21.6421 28.6875Z"
      fill={secondaryColor}
    />
    <path
      d="M51.8763 24.7723C50.7206 24.7626 49.7925 23.8283 49.7807 22.6624C49.7774 22.3336 50.0414 22.0668 50.3673 22.0695C50.6933 22.0723 50.9627 22.3435 50.9661 22.6724C50.9712 23.1805 51.3604 23.5724 51.8642 23.5766C52.3383 23.5806 52.7489 23.1655 52.7441 22.6872C52.7389 22.1791 52.3498 21.7872 51.846 21.783L50.3643 21.7706C50.0383 21.7679 49.7689 21.4966 49.7656 21.1678L49.7474 19.3742C49.7441 19.0454 50.008 18.7786 50.334 18.7813L53.2974 18.8061C53.6234 18.8089 53.8928 19.0801 53.8961 19.4089C53.8995 19.7378 53.6355 20.0046 53.3095 20.0018L50.9388 19.982L50.9449 20.5799L51.8339 20.5873C52.9896 20.597 53.9176 21.5313 53.9294 22.6972C53.941 23.8331 53.0024 24.7817 51.8763 24.7723Z"
      fill={tertiaryColor}
    />
    <path
      d="M45.863 24.7723C44.7073 24.7626 43.7792 23.8283 43.7674 22.6624L43.7492 20.8689C43.7374 19.703 44.6467 18.7841 45.8024 18.7937C46.9581 18.8034 47.8862 19.7378 47.898 20.9036L47.9161 22.6972C47.928 23.863 47.0187 24.782 45.863 24.7723ZM45.8145 19.9894C45.3107 19.9852 44.9294 20.3706 44.9346 20.8788L44.9528 22.6724C44.9579 23.1805 45.3471 23.5724 45.8509 23.5766C46.3546 23.5808 46.7359 23.1954 46.7308 22.6872L46.7126 20.8937C46.7075 20.3855 46.3183 19.9937 45.8145 19.9894Z"
      fill={tertiaryColor}
    />
    <path
      d="M48.8449 24.4844C48.8151 24.4841 48.7556 24.4836 48.7258 24.4834C48.6961 24.4832 48.6363 24.4528 48.6065 24.4526C48.5768 24.4523 48.5467 24.4223 48.5166 24.3922C48.4866 24.3621 48.4565 24.3321 48.4268 24.3318C48.3065 24.2115 48.2455 24.0619 48.244 23.9128C48.2437 23.883 48.243 23.8233 48.2427 23.7935C48.2424 23.7637 48.2719 23.7341 48.2713 23.6744C48.3007 23.6448 48.3004 23.615 48.3299 23.5854C48.3593 23.5559 48.3888 23.5263 48.3885 23.4965C48.5357 23.3486 48.7434 23.2906 48.9225 23.3518C48.9523 23.352 49.0121 23.3823 49.0418 23.3826C49.0719 23.4126 49.1016 23.4129 49.1317 23.443C49.1618 23.473 49.1918 23.5031 49.2216 23.5033C49.2516 23.5334 49.2817 23.5635 49.282 23.5933C49.3121 23.6234 49.3124 23.6532 49.3424 23.6833C49.3427 23.7131 49.3728 23.7432 49.3734 23.8028C49.3737 23.8327 49.3743 23.8923 49.3746 23.9221C49.3762 24.0713 49.3182 24.2199 49.2004 24.3382C49.1709 24.3678 49.1415 24.3974 49.1117 24.3971C49.0823 24.4267 49.0525 24.4265 49.0231 24.456C48.9933 24.4558 48.9639 24.4854 48.9044 24.4849C48.9341 24.4851 48.8746 24.4846 48.8449 24.4844Z"
      fill={tertiaryColor}
    />
    <path
      d="M10.667 10.0527C10.343 10.0499 10.0753 9.77807 10.0721 9.44867L10.0425 6.3943L9.57588 6.8693C9.3426 7.10681 8.98918 7.10372 8.75123 6.86211C8.51329 6.6205 8.5098 6.26116 8.74308 6.02365L10.2011 4.53926C10.3761 4.36113 10.6111 4.3033 10.8479 4.42513C11.0549 4.51676 11.2042 4.72766 11.2066 4.96722L11.2502 9.45895C11.2534 9.78834 10.991 10.0555 10.667 10.0527Z"
      fill={tertiaryColor}
    />
    <path
      d="M11.8658 19.0727L8.90136 19.0472C8.57527 19.0444 8.30581 18.7731 8.30256 18.4444L8.29961 18.1455C8.28809 16.9798 9.19791 16.0612 10.354 16.0711C10.858 16.0754 11.2395 15.6902 11.2345 15.1821C11.2322 14.943 11.1412 14.733 10.9615 14.5521C10.7819 14.3713 10.5735 14.2798 10.3363 14.2778C9.83236 14.2735 9.45083 14.6587 9.45585 15.1668C9.4591 15.4956 9.19496 15.7623 8.86887 15.7595C8.54278 15.7567 8.27332 15.4854 8.27007 15.1566C8.25855 13.991 9.16837 13.0723 10.3245 13.0823C10.8581 13.0868 11.3938 13.3006 11.7833 13.7224C12.1725 14.1142 12.415 14.6543 12.4203 15.1923C12.4318 16.3579 11.522 17.2766 10.3659 17.2666C9.98048 17.2633 9.65676 17.4996 9.54172 17.8573L11.854 17.8771C12.1801 17.8799 12.4495 18.1512 12.4528 18.48C12.456 18.8088 12.1919 19.0755 11.8658 19.0727Z"
      fill={tertiaryColor}
    />
    <path
      d="M13.0613 12.158L7.65965 12.1161C7.32955 12.1135 7.05663 11.8523 7.05317 11.5357C7.04971 11.219 7.31696 10.9621 7.64706 10.9646L13.0487 11.0065C13.3788 11.0091 13.6518 11.2703 13.6552 11.5869C13.6587 11.9035 13.3914 12.1605 13.0613 12.158Z"
      fill={tertiaryColor}
    />
    <path
      d="M50.1935 29.5959L48.9966 29.5862C48.7572 29.5843 48.5456 29.3763 48.5431 29.1404C48.5406 28.9046 48.7479 28.6999 48.9873 28.7019L50.1842 28.7115C50.4236 28.7135 50.6352 28.9215 50.6377 29.1573C50.6402 29.3932 50.4329 29.5978 50.1935 29.5959Z"
      fill={secondaryColor}
    />
    <path
      d="M52.006 30.7991C51.7706 30.797 51.5626 30.5856 51.5603 30.346L51.5414 28.4291L51.4249 28.5479C51.2501 28.7261 50.9854 28.7238 50.8071 28.5426C50.6288 28.3614 50.6261 28.0918 50.8009 27.9136L51.6746 27.0227C51.7911 26.9039 51.9965 26.8458 52.1444 26.9369C52.3216 26.9983 52.4116 27.1788 52.4134 27.3585L52.4429 30.3536C52.4452 30.5932 52.2414 30.8011 52.006 30.7991Z"
      fill={secondaryColor}
    />
    <path
      d="M5.08871 21.4857C4.99942 21.4849 4.93934 21.4247 4.9385 21.3353L4.9357 21.0371L4.92731 20.1425L4.91891 19.2479C4.91667 19.0093 4.70639 18.7987 4.4683 18.7965C4.34925 18.7955 4.23077 18.854 4.14232 18.9427C3.99268 18.8519 3.78379 18.7904 3.57546 18.7885C2.83143 18.7817 2.2418 19.3728 2.2488 20.1183L2.2572 21.0129C2.26419 21.7584 2.86501 22.3601 3.60904 22.3669C3.90666 22.3696 4.17367 22.2825 4.41008 22.1057C4.59032 22.2863 4.82925 22.3779 5.0971 22.3803C5.33519 22.3825 5.54156 22.1756 5.53932 21.937C5.53709 21.6985 5.3268 21.4879 5.08871 21.4857ZM3.60065 21.4723C3.36256 21.4701 3.15227 21.2595 3.15003 21.0209L3.14164 20.1263C3.1394 19.8878 3.34577 19.6809 3.58386 19.6831C3.82195 19.6852 4.03223 19.8958 4.03447 20.1344L4.04287 21.029C4.04511 21.2676 3.83874 21.4744 3.60065 21.4723Z"
      fill={secondaryColor}
    />
  </svg>
);

export default MathsIcon;
