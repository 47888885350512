import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.RussianLanguage];

const RussianLanguageIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="0 0 240 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1761_12524)">
      <path d="M227.682 124.047H190.671V191H227.682V124.047Z" fill={secondaryColor} />
      <path
        d="M214.728 143.991H219.141C219.853 143.991 220.422 143.421 220.422 142.709V134.019C220.422 133.307 219.853 132.737 219.141 132.737H214.728C214.016 132.737 213.447 133.307 213.447 134.019V142.709C213.447 143.421 214.016 143.991 214.728 143.991Z"
        fill={primaryColor}
      />
      <path
        d="M199.07 143.991H203.482C204.194 143.991 204.764 143.421 204.764 142.709V134.019C204.764 133.307 204.194 132.737 203.482 132.737H199.07C198.358 132.737 197.788 133.307 197.788 134.019V142.709C197.788 143.421 198.358 143.991 199.07 143.991Z"
        fill={primaryColor}
      />
      <path d="M227.682 168.208H190.671V191H227.682V168.208Z" fill={tertiaryColor} />
      <path d="M227.682 152.538H190.671V175.33H227.682V152.538Z" fill={tertiaryColor} />
      <path
        d="M213.447 62.7928C213.447 62.223 209.177 62.7928 209.177 62.7928C209.177 62.7928 204.906 62.223 204.906 62.7928L199.212 95.5569H209.177H219.141L213.447 62.7928Z"
        fill={secondaryColor}
      />
      <path d="M200.493 88.4341L199.212 95.5567H209.177H219.141L217.86 88.4341H200.493Z" fill={tertiaryColor} />
      <path
        d="M222.273 96.9812C222.131 96.1265 221.419 95.5566 220.565 95.5566H209.177H197.788C196.934 95.5566 196.222 96.1265 196.08 96.9812L190.671 124.047H209.177H227.682L222.273 96.9812Z"
        fill={secondaryColor}
      />
      <path
        d="M209.177 54.2454C212.321 54.2454 214.871 51.6942 214.871 48.5472C214.871 45.4003 212.321 42.8491 209.177 42.8491C206.032 42.8491 203.482 45.4003 203.482 48.5472C203.482 51.6942 206.032 54.2454 209.177 54.2454Z"
        fill={tertiaryColor}
      />
      <path d="M212.023 51.3965H206.329V62.7927H212.023V51.3965Z" fill={tertiaryColor} />
      <path
        d="M214.871 61.3678V58.5188C214.871 57.8065 214.301 57.0942 213.447 57.0942H204.906C204.194 57.0942 203.482 57.664 203.482 58.5188V61.3678C203.482 62.0801 204.052 62.7924 204.906 62.7924H213.589C214.301 62.7924 214.871 62.2225 214.871 61.3678Z"
        fill={tertiaryColor}
      />
      <path
        d="M190.813 113.791L177.859 102.679L165.047 113.791C161.488 116.355 159.353 120.058 159.353 124.047C159.353 131.882 167.61 138.292 177.859 138.292C188.108 138.292 196.365 131.882 196.365 124.047C196.365 120.058 194.23 116.497 190.813 113.791Z"
        fill={secondaryColor}
      />
      <path
        d="M190.671 134.019H165.047C164.335 134.019 163.624 134.589 163.624 135.444V141.142C163.624 141.854 164.193 142.566 165.047 142.566H190.671C191.382 142.566 192.094 141.996 192.094 141.142V135.444C192.094 134.589 191.525 134.019 190.671 134.019Z"
        fill={tertiaryColor}
      />
      <path d="M190.671 142.566H165.047V168.207H190.671V142.566Z" fill={primaryColor} />
      <path
        d="M177.859 156.812C174.727 156.812 172.165 159.376 172.165 162.51V191H183.553V162.51C183.553 159.376 180.991 156.812 177.859 156.812Z"
        fill={primaryColor}
      />
      <path
        d="M189.247 156.812C186.115 156.812 183.553 159.376 183.553 162.51V191H194.941V162.51C194.941 159.376 192.379 156.812 189.247 156.812Z"
        fill={primaryColor}
      />
      <path
        d="M166.471 156.812C163.339 156.812 160.777 159.376 160.777 162.51V191H172.165V162.51C172.165 159.376 169.602 156.812 166.471 156.812Z"
        fill={primaryColor}
      />
      <path d="M194.941 169.632H160.777V191H194.941V169.632Z" fill={secondaryColor} />
      <path
        d="M165.047 113.791C163.339 115.073 161.915 116.64 160.919 118.349H194.799C193.802 116.64 192.379 115.073 190.813 113.791L186.97 110.514H168.891L165.047 113.791Z"
        fill={primaryColor}
      />
      <path
        d="M159.638 126.184C160.207 129.176 162.058 131.882 164.762 134.019H191.24C193.945 131.882 195.653 129.176 196.365 126.184H159.638Z"
        fill={primaryColor}
      />
      <path
        d="M253.448 113.791L240.494 102.679L227.682 113.791C224.124 116.355 221.988 120.058 221.988 124.047C221.988 131.882 230.245 138.292 240.494 138.292C250.744 138.292 259 131.882 259 124.047C259 120.058 256.865 116.497 253.448 113.791Z"
        fill={secondaryColor}
      />
      <path
        d="M231.241 136.44V110.799L227.682 113.79C224.124 116.354 221.988 120.058 221.988 124.047C221.988 129.318 225.689 133.876 231.241 136.44Z"
        fill={primaryColor}
      />
      <path
        d="M253.306 134.019H227.682C226.971 134.019 226.259 134.589 226.259 135.444V141.142C226.259 141.854 226.828 142.566 227.682 142.566H253.306C254.018 142.566 254.729 141.996 254.729 141.142V135.444C254.729 134.589 254.16 134.019 253.306 134.019Z"
        fill={tertiaryColor}
      />
      <path d="M253.306 142.566H227.682V168.207H253.306V142.566Z" fill={secondaryColor} />
      <path
        d="M240.494 156.812C237.362 156.812 234.8 159.376 234.8 162.51V191H246.188V162.51C246.188 159.376 243.626 156.812 240.494 156.812Z"
        fill={primaryColor}
      />
      <path
        d="M229.106 156.812C225.974 156.812 223.412 159.376 223.412 162.51V191H234.8V162.51C234.8 159.376 232.238 156.812 229.106 156.812Z"
        fill={primaryColor}
      />
      <path d="M257.577 169.632H223.412V191H257.577V169.632Z" fill={secondaryColor} />
      <path
        d="M32.6588 40C23.9753 40 17 46.9802 17 55.6698V74.1887H48.3176V55.6698C48.3176 46.9802 41.3424 40 32.6588 40Z"
        fill={secondaryColor}
      />
      <path
        d="M160.777 40H32.6589C41.3425 40 48.3178 46.9802 48.3178 55.6698V156.811V175.33C48.3178 183.877 55.2931 190.858 63.8342 191H63.9766H176.435V74.1887V55.6698C176.435 46.9802 169.46 40 160.777 40Z"
        fill={tertiaryColor}
      />
      <path
        d="M176.435 156.812H79.6354V175.33C79.6354 184.02 72.6601 191 63.9766 191H192.094C200.778 191 207.753 184.02 207.753 175.33V156.812H176.435Z"
        fill={secondaryColor}
      />
      <path
        d="M109.529 94.1321H106.825C105.828 94.1321 104.974 93.5623 104.689 92.5651L94.0128 60.6557C93.8705 60.2283 93.7281 59.6585 93.7281 59.0887V55.6699H96.5752C97.4293 55.6699 97.9987 55.1 97.9987 54.2453C97.9987 53.3906 97.4293 52.8208 96.5752 52.8208H79.6352C78.7811 52.8208 78.2117 53.3906 78.2117 54.2453C78.2117 55.1 78.7811 55.6699 79.6352 55.6699H82.4822V59.9434L66.9658 83.7331C63.9764 88.434 65.827 94.7019 70.9517 96.8387C75.0799 98.5482 79.9199 96.9812 82.0552 92.9925L89.3152 80.3142L93.8705 94.1321H91.0234C90.1693 94.1321 89.5999 94.7019 89.5999 95.5566C89.5999 96.4114 90.1693 96.9812 91.0234 96.9812H109.529C110.383 96.9812 110.953 96.4114 110.953 95.5566C110.953 94.7019 110.383 94.1321 109.529 94.1321ZM80.4893 87.5793C79.2081 89.7161 76.5034 90.4283 74.3681 89.4312C71.9481 88.1491 70.9517 85.0151 72.5175 82.7359L84.0481 64.6444L87.7493 75.6132L80.4893 87.5793Z"
        fill={primaryColor}
      />
      <path
        d="M128.035 84.1605H112.376C111.665 84.1605 110.953 83.5907 110.953 82.736V79.8869C110.953 79.1747 111.522 78.4624 112.376 78.4624H128.035C128.747 78.4624 129.459 79.0322 129.459 79.8869V82.736C129.459 83.5907 128.889 84.1605 128.035 84.1605Z"
        fill={primaryColor}
      />
      <path
        d="M162.058 68.4907H138.142C137.288 68.4907 136.576 69.203 136.576 70.0577V72.7643C136.576 78.1775 140.989 82.5936 146.399 82.5936H147.965L143.125 84.1605C138.427 85.7275 135.153 90.1436 135.153 95.2719C135.153 96.1266 135.865 96.8388 136.719 96.8388H143.552C144.406 96.8388 145.118 96.1266 145.118 95.2719V93.1351C145.118 90.1436 146.826 87.5794 149.53 86.4398L155.082 84.1605V95.4143C155.082 96.269 155.794 96.9813 156.648 96.9813H161.915C162.769 96.9813 163.481 96.269 163.481 95.4143V70.0577C163.623 69.203 162.912 68.4907 162.058 68.4907ZM155.082 78.8898C155.082 79.4596 154.655 79.887 154.086 79.887H152.52C149.246 79.887 146.683 77.1803 146.683 74.0464V72.4794C146.683 71.9096 147.11 71.4822 147.68 71.4822H154.228C154.798 71.4822 155.225 71.9096 155.225 72.4794V78.8898H155.082Z"
        fill={primaryColor}
      />
      <path
        d="M159.353 119.774H68.2471C65.1153 119.774 62.553 117.21 62.553 114.076C62.553 110.942 65.1153 108.377 68.2471 108.377H159.353C162.485 108.377 165.047 110.942 165.047 114.076C165.047 117.21 162.485 119.774 159.353 119.774Z"
        fill={primaryColor}
      />
      <path
        d="M162.2 134.019H65.4C63.8342 134.019 62.553 132.737 62.553 131.17C62.553 129.603 63.8342 128.321 65.4 128.321H162.2C163.766 128.321 165.047 129.603 165.047 131.17C165.047 132.737 163.766 134.019 162.2 134.019Z"
        fill={secondaryColor}
      />
      <path
        d="M162.2 145.415H65.4C63.8342 145.415 62.553 144.133 62.553 142.566C62.553 140.999 63.8342 139.717 65.4 139.717H162.2C163.766 139.717 165.047 140.999 165.047 142.566C165.047 144.133 163.766 145.415 162.2 145.415Z"
        fill={secondaryColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1761_12524"
        x="-13"
        y="40"
        width="302"
        height="211"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.155833 0 0 0 0 0 0 0 0 0 0.354167 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1761_12524" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1761_12524" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default RussianLanguageIcon;
