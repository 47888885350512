import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Algebra];

const AlgebraIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M29.6032 13.0519C29.0485 13.0519 28.6243 12.6406 28.6243 12.1027V10.5206C28.6243 9.98264 29.0485 9.57129 29.6032 9.57129C30.1579 9.57129 30.5821 9.98264 30.5821 10.5206V12.1027C30.5821 12.6406 30.1579 13.0519 29.6032 13.0519Z"
      fill={secondaryColor}
    />
    <path
      d="M29.6032 17.1857C29.0485 17.1857 28.6243 16.7744 28.6243 16.2365V14.6543C28.6243 14.1164 29.0485 13.7051 29.6032 13.7051C30.1579 13.7051 30.5821 14.1164 30.5821 14.6543V16.2365C30.5821 16.7744 30.1579 17.1857 29.6032 17.1857Z"
      fill={secondaryColor}
    />
    <path
      d="M29.6032 21.3185C29.0485 21.3185 28.6243 20.9072 28.6243 20.3693V18.7872C28.6243 18.2492 29.0485 17.8379 29.6032 17.8379C30.1579 17.8379 30.5821 18.2492 30.5821 18.7872V20.3693C30.5821 20.9072 30.1579 21.3185 29.6032 21.3185Z"
      fill={secondaryColor}
    />
    <path
      d="M29.6032 25.4523C29.0485 25.4523 28.6243 25.041 28.6243 24.5031V22.9209C28.6243 22.383 29.0485 21.9717 29.6032 21.9717C30.1579 21.9717 30.5821 22.383 30.5821 22.9209V24.5031C30.5821 25.041 30.1579 25.4523 29.6032 25.4523Z"
      fill={secondaryColor}
    />
    <path
      d="M29.6032 29.5851C29.0485 29.5851 28.6243 29.1738 28.6243 28.6359V27.0538C28.6243 26.5158 29.0485 26.1045 29.6032 26.1045C30.1579 26.1045 30.5821 26.5158 30.5821 27.0538V28.6359C30.5821 29.1738 30.1579 29.5851 29.6032 29.5851Z"
      fill={secondaryColor}
    />
    <path
      d="M29.6032 33.2838C29.0485 33.2838 28.6243 32.8879 28.6243 32.3702V31.152C28.6243 30.6342 29.0485 30.2383 29.6032 30.2383C30.1579 30.2383 30.5821 30.6342 30.5821 31.152V32.3702C30.5821 32.8879 30.1579 33.2838 29.6032 33.2838Z"
      fill={secondaryColor}
    />
    <path
      d="M29.4555 33.9811C19.9837 33.9811 12.2542 23.749 11.7474 10.8877C11.7157 10.1591 12.2859 9.58887 13.0145 9.58887C13.7114 9.58887 14.2499 10.1274 14.2816 10.8243C14.7251 22.2918 21.3458 31.4468 29.4555 31.4468C37.5651 31.4468 44.1858 22.2918 44.6293 10.8243C44.661 10.1274 45.1995 9.58887 45.8965 9.58887C46.6251 9.58887 47.1953 10.1908 47.1636 10.8877C46.6567 23.749 38.9272 33.9811 29.4555 33.9811Z"
      fill={secondaryColor}
    />
    <path
      d="M42.1584 37.6237L43.2671 36.2933C43.4888 36.0082 43.4572 35.628 43.1721 35.4063C42.887 35.1845 42.5068 35.2162 42.2851 35.5013L41.3347 36.6417L40.3844 35.5013C40.1626 35.2162 39.7508 35.1845 39.4974 35.4063C39.2123 35.628 39.1806 36.0398 39.4024 36.2933L40.5111 37.6237L39.4024 38.9542C39.1806 39.2393 39.2123 39.6195 39.4974 39.8412C39.6241 39.9362 39.7508 39.9996 39.9092 39.9996C40.0993 39.9996 40.2893 39.9362 40.3844 39.7779L41.3347 38.6374L42.2851 39.7779C42.4118 39.9362 42.6019 39.9996 42.7602 39.9996C42.887 39.9996 43.0454 39.9679 43.1721 39.8412C43.4572 39.6195 43.4888 39.2076 43.2671 38.9542L42.1584 37.6237Z"
      fill={secondaryColor}
    />
    <path
      d="M10.1951 6.5481C9.91003 6.35803 9.52989 6.42139 9.30814 6.70649L8.23109 8.19537L7.15403 6.70649C6.96396 6.42139 6.55214 6.35803 6.26704 6.5481C5.98193 6.73817 5.91858 7.14999 6.10865 7.43509L7.59752 9.46249V10.5396C7.59752 10.888 7.88263 11.1731 8.23109 11.1731C8.57955 11.1731 8.86465 10.888 8.86465 10.5396V9.46249L10.3218 7.40341C10.5436 7.14999 10.4802 6.73817 10.1951 6.5481Z"
      fill={secondaryColor}
    />
    <path
      d="M39.5926 27.6462H36.4248C36.0764 27.6462 35.7913 27.3611 35.7913 27.0126V26.6958C35.7913 25.4604 36.7733 24.4784 38.0087 24.4784C38.5473 24.4784 38.9591 24.0666 38.9591 23.528C38.9591 23.2746 38.864 23.0528 38.674 22.8628C38.4839 22.6727 38.2622 22.5777 38.0087 22.5777C37.4702 22.5777 37.0584 22.9895 37.0584 23.528C37.0584 23.8765 36.7733 24.1616 36.4248 24.1616C36.0764 24.1616 35.7913 23.8765 35.7913 23.528C35.7913 22.2926 36.7733 21.3105 38.0087 21.3105C38.5789 21.3105 39.1491 21.5323 39.561 21.9758C39.9728 22.4193 40.2262 22.9578 40.2262 23.528C40.2262 24.7635 39.2442 25.7455 38.0087 25.7455C37.5969 25.7455 37.2485 25.9989 37.1217 26.3791H39.5926C39.9411 26.3791 40.2262 26.6642 40.2262 27.0126C40.2262 27.3611 39.9411 27.6462 39.5926 27.6462Z"
      fill={tertiaryColor}
    />
    <path
      d="M42.7606 23.2107H41.1766C40.9866 23.2107 40.8599 23.084 40.8599 22.8939V22.7355C40.8599 22.1336 41.3667 21.6268 41.9686 21.6268C42.127 21.6268 42.2537 21.5634 42.3487 21.4367C42.4438 21.31 42.4755 21.1833 42.4438 21.0249C42.4121 20.8665 42.2854 20.7398 42.127 20.7081C41.9686 20.6764 41.8102 20.7081 41.7152 20.8032C41.5885 20.8982 41.5251 21.0249 41.5251 21.1833C41.5251 21.3734 41.3984 21.5001 41.2083 21.5001C41.0183 21.5001 40.8915 21.3734 40.8915 21.1833C40.8915 20.8348 41.0499 20.5181 41.335 20.2963C41.6201 20.0746 41.9686 20.0112 42.3171 20.1062C42.6972 20.2013 43.014 20.5181 43.109 20.8982C43.204 21.2467 43.1407 21.5951 42.9189 21.8802C42.6972 22.1653 42.3804 22.3237 42.032 22.3237C41.8102 22.3237 41.6518 22.4504 41.5885 22.6405H42.8239C43.014 22.6405 43.1407 22.7672 43.1407 22.9573C43.1407 23.1473 42.9506 23.2107 42.7606 23.2107Z"
      fill={tertiaryColor}
    />
    <path
      d="M49.3484 31.9491L46.8076 29.4008C46.2995 28.8911 45.5055 28.8911 44.9973 29.4008C44.4892 29.9104 44.4892 30.7068 44.9973 31.2164L45.3784 31.5987H14.1588V4.39578L14.5399 4.77802C14.794 5.001 15.1116 5.12841 15.4292 5.12841C15.7468 5.12841 16.0644 5.001 16.3185 4.74617C16.8266 4.23651 16.8266 3.44018 16.3185 2.93052L13.7777 0.382242C13.2696 -0.127414 12.4756 -0.127414 11.9674 0.382242L9.42665 2.93052C8.91849 3.44018 8.91849 4.23651 9.42665 4.74617C9.9348 5.25583 10.7288 5.25583 11.2369 4.74617L11.6181 4.36393V31.5668H10.3477C9.64896 31.5668 9.07729 32.1402 9.07729 32.8409C9.07729 33.5417 9.64896 34.1151 10.3477 34.1151H11.6181V35.7078C11.6181 36.4085 12.1897 36.9819 12.8884 36.9819C13.5871 36.9819 14.1588 36.4085 14.1588 35.7078V34.1151H45.3784L44.9973 34.4973C44.4892 35.007 44.4892 35.8033 44.9973 36.313C45.2514 36.5678 45.569 36.6952 45.8866 36.6952C46.2042 36.6952 46.5218 36.5678 46.7759 36.313L49.3166 33.7647C49.8565 33.255 49.8565 32.4269 49.3484 31.9491Z"
      fill={tertiaryColor}
    />
    <path
      d="M24.2285 14.3408H18.2097C17.9246 14.3408 17.6712 14.4992 17.5128 14.721L12.9828 22.4504L11.3038 19.9162C11.0504 19.536 10.5752 19.441 10.1951 19.6944C9.81496 19.9479 9.71992 20.423 9.97335 20.8032L12.3492 24.3828C12.5076 24.6046 12.761 24.7313 13.0145 24.7313C13.0145 24.7313 13.0145 24.7313 13.0461 24.7313C13.3312 24.7313 13.5847 24.5729 13.7114 24.3511L18.6849 15.9564H24.2285C24.672 15.9564 25.0205 15.6079 25.0205 15.1645C25.0205 14.721 24.672 14.3408 24.2285 14.3408Z"
      fill={secondaryColor}
    />
    <path
      d="M21.029 16.749C19.4768 16.749 18.2413 18.0162 18.2413 19.5367C18.2413 21.0889 19.5085 22.3244 21.029 22.3244C21.4408 22.3244 21.8526 22.2294 22.2011 22.071C22.1694 22.3561 22.0427 22.5778 21.8526 22.7996C21.4092 23.2431 20.6172 23.2431 20.142 22.7996C19.8252 22.4828 19.3184 22.4828 19.0016 22.7996C18.6848 23.1163 18.6848 23.6232 19.0016 23.94C19.5401 24.4785 20.2371 24.7636 20.9973 24.7636C21.7576 24.7636 22.4545 24.4785 22.9931 23.94C23.5316 23.4014 23.8167 22.7045 23.8167 21.9442V19.5367C23.8167 17.9845 22.5496 16.749 21.029 16.749ZM21.029 20.7405C20.3638 20.7405 19.8252 20.2019 19.8252 19.5367C19.8252 18.8715 20.3638 18.3329 21.029 18.3329C21.6943 18.3329 22.2328 18.8715 22.2328 19.5367C22.2328 20.2019 21.6943 20.7405 21.029 20.7405Z"
      fill={secondaryColor}
    />
  </svg>
);

export default AlgebraIcon;
