import { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserName } from './UserName';
import { useAuth } from '../providers';
import classNames from 'classnames';

export const Header = forwardRef<HTMLDivElement>((_, ref) => {
  const { user, logout } = useAuth();
  const location = useLocation();

  return (
    <div ref={ref} className="navbar fixed-top d-flex align-items-center">
      <div className="container d-flex flex-wrap align-items-center">
        <div className="navbar-brand me-5 my-2">
          <Link to="/">ECL Player</Link>
        </div>

        {user && (
          <div className="d-flex flex-grow-1 flex-wrap align-items-center justify-content-between">
            <nav className="navbar-nav me-auto my-2">
              <Link to="/lessons" className={classNames({ active: location.pathname.startsWith('/lessons') })}>
                Уроки
              </Link>
              <Link to="/tasks" className={classNames({ active: location.pathname.startsWith('/tasks') })}>
                Задания
              </Link>
            </nav>
            <div className="d-flex flex-wrap align-items-center my-2">
              <UserName user={user} size="sm" className="me-5" />
              <button className="btn btn-primary" onClick={logout}>
                Выйти
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
