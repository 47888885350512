import { Icon } from '../Icon';

export const TypeTrainerIcon: Icon = (props) => (
  <svg width="24" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.00073 1.99896V10.999L14.0007 12.999V3.99896L7.00073 1.99896Z" fill="#5A46A8" />
    <path
      d="M6.99976 2C7.88274 2.20302 13.1149 3.79661 13.9998 4L20.9998 2L13.9998 0C13.122 0.214762 7.88328 1.78382 6.99976 2Z"
      fill="#E5DCF8"
    />
    <path d="M14.0001 4V13L21.0001 11C21.0001 10.755 21.0001 2.24492 21.0001 2L14.0001 4Z" fill="#E5DCF8" />
    <path d="M14.0001 13V22L21.0001 24V15L14.0001 13Z" fill="#5A46A8" />
    <path
      d="M14.0001 13C14.883 13.203 20.1152 14.7966 21.0001 15L28.0001 13L21.0001 11C20.1223 11.2148 14.8836 12.7838 14.0001 13Z"
      fill="#E5DCF8"
    />
    <path d="M21.0001 15V24L28.0001 22C28.0001 21.755 28.0001 13.2449 28.0001 13L21.0001 15Z" fill="#E5DCF8" />
    <path d="M-0.000244141 13V22L6.99976 24V15L-0.000244141 13Z" fill="#5A46A8" />
    <path
      d="M-0.000244141 13C0.88274 13.203 6.11494 14.7966 6.99976 15L13.9998 13L6.99976 11C6.12204 11.2148 0.883278 12.7838 -0.000244141 13Z"
      fill="#E5DCF8"
    />
    <path d="M6.99976 15V24L13.9998 22C13.9998 21.755 13.9998 13.2449 13.9998 13L6.99976 15Z" fill="#E5DCF8" />
  </svg>
);
