import { useEffect, useState } from 'react';
import { useOptimizedResize } from './useOptimezedResize';
import gridBreakpoints from '../styles/theme/grid-breakpoints.module.scss';

export enum MediaBreakpoint {
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl',
  Xxl = 'xxl',
  Xxxl = 'xxxl'
}

const mediaBreakpoints = Object.entries(gridBreakpoints).reduce((breakpoints, [key, value]) => {
  breakpoints[key as MediaBreakpoint] = parseInt(value, 10);

  return breakpoints;
}, {} as { [K in MediaBreakpoint]: number });

export const useMediaBreakpoint = (lowerThreshold = 0, upperThreshold = 0) => {
  const [width] = useOptimizedResize();
  const [breakpoint, setBreakpoint] = useState(MediaBreakpoint.Xs);

  useEffect(() => {
    setBreakpoint(computeMediaBreakpoint(width, lowerThreshold, upperThreshold));
  }, [lowerThreshold, upperThreshold, width]);

  return breakpoint;
};

const computeMediaBreakpoint = (width: number, lowerThreshold = 0, upperThreshold = 0) => {
  if (width <= mediaBreakpoints[MediaBreakpoint.Sm] - upperThreshold) {
    return MediaBreakpoint.Xs;
  }

  if (
    width >= mediaBreakpoints[MediaBreakpoint.Sm] - lowerThreshold &&
    width <= mediaBreakpoints[MediaBreakpoint.Md] - upperThreshold
  ) {
    return MediaBreakpoint.Sm;
  }

  if (
    width >= mediaBreakpoints[MediaBreakpoint.Md] - lowerThreshold &&
    width <= mediaBreakpoints[MediaBreakpoint.Lg] - upperThreshold
  ) {
    return MediaBreakpoint.Md;
  }

  if (
    width >= mediaBreakpoints[MediaBreakpoint.Lg] - lowerThreshold &&
    width <= mediaBreakpoints[MediaBreakpoint.Xl] - upperThreshold
  ) {
    return MediaBreakpoint.Lg;
  }

  if (
    width >= mediaBreakpoints[MediaBreakpoint.Xl] - lowerThreshold &&
    width <= mediaBreakpoints[MediaBreakpoint.Xxl] - upperThreshold
  ) {
    return MediaBreakpoint.Xl;
  }

  if (
    width >= mediaBreakpoints[MediaBreakpoint.Xxl] - lowerThreshold &&
    width <= mediaBreakpoints[MediaBreakpoint.Xxxl] - upperThreshold
  ) {
    return MediaBreakpoint.Xxl;
  }

  return MediaBreakpoint.Xxxl;
};

const compareMediaBreakpoints = (a: MediaBreakpoint, b: MediaBreakpoint) => {
  if (a === b) return 0;

  switch (a) {
    case MediaBreakpoint.Xs:
      return -1;
    case MediaBreakpoint.Sm:
      return [MediaBreakpoint.Xs].includes(b) ? 1 : -1;
    case MediaBreakpoint.Md:
      return [MediaBreakpoint.Xs, MediaBreakpoint.Sm].includes(b) ? 1 : -1;
    case MediaBreakpoint.Lg:
      return [MediaBreakpoint.Xs, MediaBreakpoint.Sm, MediaBreakpoint.Md].includes(b) ? 1 : -1;
    case MediaBreakpoint.Xl:
      return [MediaBreakpoint.Xs, MediaBreakpoint.Sm, MediaBreakpoint.Md, MediaBreakpoint.Lg].includes(b) ? 1 : -1;
    case MediaBreakpoint.Xxl:
      return [
        MediaBreakpoint.Xs,
        MediaBreakpoint.Sm,
        MediaBreakpoint.Md,
        MediaBreakpoint.Lg,
        MediaBreakpoint.Xl
      ].includes(b)
        ? 1
        : -1;
    case MediaBreakpoint.Xxxl:
      return [
        MediaBreakpoint.Xs,
        MediaBreakpoint.Sm,
        MediaBreakpoint.Md,
        MediaBreakpoint.Lg,
        MediaBreakpoint.Xl,
        MediaBreakpoint.Xxl
      ].includes(b)
        ? 1
        : -1;
  }
};

export const useMediaBreakpointDown = (breakpoint: MediaBreakpoint, lowerThreshold = 0, upperThreshold = 0.02) => {
  const mediaBp = useMediaBreakpoint(lowerThreshold, upperThreshold);

  return compareMediaBreakpoints(mediaBp, breakpoint) <= 0;
};

export const useMediaBreakpointUp = (breakpoint: MediaBreakpoint, lowerThreshold = 0.02, upperThreshold = 0) => {
  const mediaBp = useMediaBreakpoint(lowerThreshold, upperThreshold);

  return compareMediaBreakpoints(mediaBp, breakpoint) >= 0;
};
