import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Maths];

const MathsIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="0 0 240 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1970_14746)">
      <path
        d="M29.9762 193.034C29.5869 193.034 29.0677 193.035 28.6784 192.905C27.3805 192.515 26.3422 191.737 25.6933 190.698L7.13401 157.863C5.83615 155.527 6.61487 152.671 8.951 151.244L205.187 38.0708C206.355 37.4218 207.652 37.292 208.95 37.5516C210.248 37.941 211.286 38.7197 211.935 39.8877L230.495 72.7235C231.793 75.0596 231.014 77.9149 228.678 79.2127L32.4422 192.386C31.6634 192.775 30.8847 193.034 29.9762 193.034Z"
        fill={primaryColor}
      />
      <path
        d="M197.789 42.3535L202.461 50.4002C202.98 51.1789 203.759 51.6981 204.668 51.6981C205.057 51.6981 205.576 51.5683 205.966 51.3087C207.263 50.53 207.653 48.9726 206.874 47.8045L202.202 39.7578L197.789 42.3535Z"
        fill={secondaryColor}
      />
      <path
        d="M193.376 55.5916C193.895 56.3703 194.674 56.8895 195.583 56.8895C195.972 56.8895 196.491 56.7597 196.88 56.5001C198.178 55.7214 198.568 54.164 197.789 52.9959L193.117 44.9492L188.574 47.5449L193.376 55.5916Z"
        fill={secondaryColor}
      />
      <path
        d="M184.291 60.7831C184.811 61.5618 185.589 62.081 186.498 62.081C186.887 62.081 187.406 61.9512 187.796 61.6916C189.093 60.9129 189.483 59.3555 188.704 58.1874L184.162 50.2705L179.619 52.8662L184.291 60.7831Z"
        fill={secondaryColor}
      />
      <path
        d="M38.5424 149.037C38.9317 149.037 39.4508 148.907 39.8402 148.648C41.1381 147.869 41.5274 146.312 40.7487 145.143L35.4275 135.929L30.885 138.524L36.336 147.739C36.8551 148.518 37.7636 149.037 38.5424 149.037Z"
        fill={secondaryColor}
      />
      <path
        d="M26.4722 141.25L21.9297 143.846L27.2509 152.931C27.77 153.709 28.5487 154.229 29.4572 154.229C29.8466 154.229 30.3657 154.099 30.7551 153.839C32.053 153.06 32.4423 151.503 31.6636 150.335L26.4722 141.25Z"
        fill={secondaryColor}
      />
      <path
        d="M17.3873 146.441L12.9746 149.037L18.166 158.122C18.6852 158.901 19.4639 159.42 20.3724 159.42C20.7617 159.42 21.2809 159.29 21.6702 159.031C22.9681 158.252 23.3574 156.694 22.5787 155.526L17.3873 146.441Z"
        fill={secondaryColor}
      />
      <path
        d="M54.5059 137.356C55.0251 138.135 55.8038 138.654 56.7123 138.654C57.1016 138.654 57.6208 138.524 58.0101 138.265C59.308 137.486 59.6973 135.929 58.9186 134.761L53.5974 125.546L49.0549 128.142L54.5059 137.356Z"
        fill={secondaryColor}
      />
      <path
        d="M45.421 142.548C45.9401 143.326 46.7188 143.846 47.6273 143.846C48.0167 143.846 48.5358 143.716 48.9252 143.456C50.223 142.678 50.6124 141.12 49.8337 139.952L44.5125 130.737L39.97 133.333L45.421 142.548Z"
        fill={secondaryColor}
      />
      <path
        d="M110.314 112.697L68.7822 112.697C64.4993 112.697 60.9951 109.193 60.9951 104.91L60.9951 63.3789C60.9951 59.096 64.4993 55.5918 68.7822 55.5918L110.314 55.5918C114.596 55.5918 118.101 59.096 118.101 63.3789L118.101 104.91C118.101 109.193 114.596 112.697 110.314 112.697Z"
        fill={secondaryColor}
      />
      <path
        d="M110.314 174.994L68.7822 174.994C64.4993 174.994 60.9951 171.49 60.9951 167.207L60.9951 125.676C60.9951 121.393 64.4993 117.889 68.7822 117.889L110.314 117.889C114.596 117.889 118.101 121.393 118.101 125.676L118.101 167.207C118.101 171.49 114.596 174.994 110.314 174.994Z"
        fill={tertiaryColor}
      />
      <path
        d="M172.61 112.697L131.079 112.697C126.796 112.697 123.292 109.193 123.292 104.91L123.292 63.3789C123.292 59.096 126.796 55.5918 131.079 55.5918L172.61 55.5918C176.893 55.5918 180.398 59.096 180.398 63.3789L180.398 104.91C180.398 109.193 176.893 112.697 172.61 112.697Z"
        fill={tertiaryColor}
      />
      <path
        d="M172.61 174.994L131.079 174.994C126.796 174.994 123.292 171.49 123.292 167.207L123.292 125.676C123.292 121.393 126.796 117.889 131.079 117.889L172.61 117.889C176.893 117.889 180.398 121.393 180.398 125.676L180.398 167.207C180.398 171.49 176.893 174.994 172.61 174.994Z"
        fill={secondaryColor}
      />
      <path
        d="M163.526 81.5488L140.164 81.5488C138.737 81.5488 137.569 82.7169 137.569 84.1445C137.569 85.5722 138.737 86.7402 140.164 86.7402L163.526 86.7402C164.953 86.7402 166.121 85.5722 166.121 84.1445C166.121 82.7169 164.953 81.5488 163.526 81.5488Z"
        fill={secondaryColor}
      />
      <path
        d="M101.229 81.5488L92.1436 81.5488L92.1436 72.4639C92.1436 71.0362 90.9755 69.8682 89.5479 69.8682C88.1202 69.8682 86.9522 71.0362 86.9522 72.4639L86.9522 81.5488L77.8672 81.5488C76.4396 81.5488 75.2715 82.7169 75.2715 84.1446C75.2715 85.5722 76.4396 86.7403 77.8672 86.7403L86.9522 86.7403L86.9522 95.8252C86.9522 97.2529 88.1202 98.421 89.5479 98.421C90.9755 98.421 92.1436 97.2529 92.1436 95.8252L92.1436 86.7403L101.229 86.7403C102.656 86.7403 103.824 85.5722 103.824 84.1446C103.824 82.7169 102.656 81.5488 101.229 81.5488Z"
        fill={primaryColor}
      />
      <path
        d="M151.196 145.144C153.705 145.144 155.739 143.11 155.739 140.601C155.739 138.092 153.705 136.059 151.196 136.059C148.687 136.059 146.654 138.092 146.654 140.601C146.654 143.11 148.687 145.144 151.196 145.144Z"
        fill={primaryColor}
      />
      <path
        d="M151.196 158.122C153.705 158.122 155.739 156.088 155.739 153.58C155.739 151.071 153.705 149.037 151.196 149.037C148.687 149.037 146.654 151.071 146.654 153.58C146.654 156.088 148.687 158.122 151.196 158.122Z"
        fill={primaryColor}
      />
      <path
        d="M90.1968 152.931C93.4223 152.931 96.0371 150.316 96.0371 147.09C96.0371 143.865 93.4223 141.25 90.1968 141.25C86.9713 141.25 84.3564 143.865 84.3564 147.09C84.3564 150.316 86.9713 152.931 90.1968 152.931Z"
        fill={secondaryColor}
      />
      <path
        d="M220.631 136.059C215.569 136.059 211.546 132.036 211.546 126.974C211.546 125.547 212.714 124.378 214.142 124.378C215.569 124.378 216.738 125.547 216.738 126.974C216.738 129.181 218.425 130.868 220.631 130.868C222.708 130.868 224.525 129.051 224.525 126.974C224.525 124.768 222.837 123.081 220.631 123.081L214.142 123.081C212.714 123.081 211.546 121.913 211.546 120.485L211.546 112.698C211.546 111.27 212.714 110.102 214.142 110.102L227.12 110.102C228.548 110.102 229.716 111.27 229.716 112.698C229.716 114.125 228.548 115.293 227.12 115.293L216.738 115.293L216.738 117.889L220.631 117.889C225.693 117.889 229.716 121.913 229.716 126.974C229.716 131.906 225.563 136.059 220.631 136.059Z"
        fill={tertiaryColor}
      />
      <path
        d="M194.674 136.059C189.612 136.059 185.589 132.036 185.589 126.974L185.589 119.187C185.589 114.125 189.612 110.102 194.674 110.102C199.736 110.102 203.759 114.125 203.759 119.187L203.759 126.974C203.759 132.036 199.736 136.059 194.674 136.059ZM194.674 115.293C192.468 115.293 190.781 116.981 190.781 119.187L190.781 126.974C190.781 129.181 192.468 130.868 194.674 130.868C196.88 130.868 198.568 129.181 198.568 126.974L198.568 119.187C198.568 116.981 196.88 115.293 194.674 115.293Z"
        fill={tertiaryColor}
      />
      <path
        d="M207.653 134.761C207.523 134.761 207.263 134.761 207.133 134.761C207.004 134.761 206.744 134.632 206.614 134.632C206.485 134.632 206.355 134.502 206.225 134.372C206.095 134.242 205.965 134.112 205.836 134.112C205.316 133.593 205.057 132.944 205.057 132.295C205.057 132.166 205.057 131.906 205.057 131.776C205.057 131.646 205.187 131.517 205.187 131.257C205.316 131.127 205.316 130.998 205.446 130.868C205.576 130.738 205.706 130.608 205.706 130.478C206.355 129.829 207.263 129.57 208.042 129.829C208.172 129.829 208.431 129.959 208.561 129.959C208.691 130.089 208.821 130.089 208.95 130.219C209.08 130.349 209.21 130.478 209.34 130.478C209.47 130.608 209.599 130.738 209.599 130.868C209.729 130.998 209.729 131.127 209.859 131.257C209.859 131.387 209.989 131.517 209.989 131.776C209.989 131.906 209.989 132.166 209.989 132.295C209.989 132.944 209.729 133.593 209.21 134.112C209.08 134.242 208.95 134.372 208.821 134.372C208.691 134.502 208.561 134.502 208.431 134.632C208.302 134.632 208.172 134.761 207.912 134.761C208.042 134.761 207.782 134.761 207.653 134.761Z"
        fill={tertiaryColor}
      />
      <path
        d="M42.8252 72.4642C41.3976 72.4642 40.2295 71.2961 40.2295 69.8685L40.2295 56.6304L38.1529 58.7069C37.1147 59.7452 35.5572 59.7452 34.5189 58.7069C33.4807 57.6686 33.4807 56.1112 34.5189 55.0729L41.0082 48.5837C41.7869 47.805 42.8252 47.5454 43.8635 48.0645C44.772 48.4539 45.4209 49.3624 45.4209 50.4007L45.4209 69.8685C45.4209 71.2961 44.2529 72.4642 42.8252 72.4642Z"
        fill={tertiaryColor}
      />
      <path
        d="M48.0166 111.4L35.0381 111.4C33.6105 111.4 32.4424 110.232 32.4424 108.804L32.4424 107.506C32.4424 102.445 36.4657 98.4214 41.5274 98.4214C43.7337 98.4214 45.4209 96.7342 45.4209 94.5278C45.4209 93.4896 45.0316 92.5811 44.2529 91.8024C43.4741 91.0236 42.5656 90.6343 41.5274 90.6343C39.321 90.6343 37.6338 92.3215 37.6338 94.5278C37.6338 95.9555 36.4657 97.1236 35.0381 97.1236C33.6105 97.1236 32.4424 95.9555 32.4424 94.5278C32.4424 89.4662 36.4657 85.4429 41.5274 85.4429C43.8635 85.4429 46.1996 86.3514 47.8868 88.1684C49.5741 89.8556 50.6123 92.1917 50.6123 94.5278C50.6123 99.5895 46.589 103.613 41.5274 103.613C39.8401 103.613 38.4125 104.651 37.8934 106.209L48.0166 106.209C49.4443 106.209 50.6123 107.377 50.6123 108.804C50.6123 110.232 49.4443 111.4 48.0166 111.4Z"
        fill={tertiaryColor}
      />
      <path
        d="M53.2081 81.5493L29.8467 81.5493C28.419 81.5493 27.251 80.3813 27.251 78.9536C27.251 77.526 28.419 76.3579 29.8467 76.3579L53.2081 76.3579C54.6357 76.3579 55.8038 77.526 55.8038 78.9536C55.8038 80.3813 54.6357 81.5493 53.2081 81.5493Z"
        fill={tertiaryColor}
      />
      <path
        d="M213.493 156.825L208.302 156.825C207.263 156.825 206.355 155.916 206.355 154.878C206.355 153.84 207.263 152.931 208.302 152.931L213.493 152.931C214.531 152.931 215.44 153.84 215.44 154.878C215.44 155.916 214.531 156.825 213.493 156.825Z"
        fill={secondaryColor}
      />
      <path
        d="M221.28 162.016C220.242 162.016 219.334 161.108 219.334 160.069L219.334 151.763L218.814 152.282C218.036 153.061 216.868 153.061 216.089 152.282C215.31 151.504 215.31 150.336 216.089 149.557L219.982 145.663C220.502 145.144 221.41 144.885 222.059 145.274C222.838 145.533 223.227 146.312 223.227 147.091L223.227 160.069C223.227 161.108 222.319 162.016 221.28 162.016Z"
        fill={secondaryColor}
      />
      <path
        d="M18.815 121.782C18.4256 121.782 18.166 121.523 18.166 121.133L18.166 119.835L18.166 115.942L18.166 112.048C18.166 111.01 17.2575 110.102 16.2193 110.102C15.7001 110.102 15.181 110.361 14.7916 110.75C14.1427 110.361 13.2342 110.102 12.3257 110.102C9.08106 110.102 6.48535 112.697 6.48535 115.942L6.48535 119.835C6.48535 123.08 9.08106 125.676 12.3257 125.676C13.6235 125.676 14.7916 125.286 15.8299 124.508C16.6086 125.286 17.6469 125.676 18.815 125.676C19.8532 125.676 20.7617 124.767 20.7617 123.729C20.7617 122.691 19.8532 121.782 18.815 121.782ZM12.3257 121.782C11.2874 121.782 10.3789 120.874 10.3789 119.835L10.3789 115.942C10.3789 114.904 11.2874 113.995 12.3257 113.995C13.364 113.995 14.2725 114.904 14.2725 115.942L14.2725 119.835C14.2725 120.874 13.364 121.782 12.3257 121.782Z"
        fill={secondaryColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1970_14746"
        x="-23.5146"
        y="37.4263"
        width="284.635"
        height="215.608"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.4 0 0 0 0 0.588235 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1970_14746" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1970_14746" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default MathsIcon;
