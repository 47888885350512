import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.GeneralHistory];

const GeneralHistoryIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="0 0 240 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1970_13548)">
      <path d="M164.722 108.492V190.879H71.9136L71.9136 108.492H164.722Z" fill={secondaryColor} />
      <path d="M68.7773 108.842H168.271V119.172H68.7773V108.842Z" fill={primaryColor} />
      <path
        d="M187.828 87.2562C184.788 91.7055 178.218 105.346 167.112 108.919H68.5429C57.4374 105.346 50.8668 91.7055 47.8271 87.2562V46.707H187.828V87.2562Z"
        fill={secondaryColor}
      />
      <path
        d="M103.431 191H91.8516L91.8516 142.795C91.8516 139.202 94.4437 136.288 97.6411 136.288C100.839 136.288 103.431 139.202 103.431 142.795L103.431 191Z"
        fill={primaryColor}
      />
      <path
        d="M125.698 191H113.228L113.228 142.795C113.228 139.202 116.02 136.288 119.463 136.288C122.906 136.288 125.698 139.202 125.698 142.795L125.698 191Z"
        fill={primaryColor}
      />
      <path
        d="M147.075 191H135.496V142.795C135.496 139.202 138.088 136.288 141.286 136.288C144.483 136.288 147.075 139.202 147.075 142.795L147.075 191Z"
        fill={primaryColor}
      />
      <path
        d="M103.736 113.599H91.6565V106.472C91.6565 102.651 94.3606 99.5537 97.6962 99.5537C101.032 99.5537 103.736 102.651 103.736 106.472L103.736 113.599Z"
        fill={primaryColor}
      />
      <path
        d="M125.48 113.599H113.401V106.472C113.401 102.651 116.105 99.5537 119.44 99.5537C122.776 99.5537 125.48 102.651 125.48 106.472L125.48 113.599Z"
        fill={primaryColor}
      />
      <path
        d="M147.223 113.599H135.144L135.144 106.472C135.144 102.651 137.848 99.5537 141.184 99.5537C144.519 99.5537 147.223 102.651 147.223 106.472V113.599Z"
        fill={primaryColor}
      />
      <path
        d="M179.688 39.2969H57.9711C40.1167 39.2969 25.2344 53.2195 25.0028 70.7204C24.7674 88.5024 39.4012 102.99 57.4867 102.99C72.2299 102.99 84.6779 93.362 88.648 80.1704C89.6847 76.7257 92.844 74.3163 96.5075 74.3163H140.662C144.254 74.3163 147.476 76.6027 148.472 79.9864C152.506 93.6954 165.682 103.605 181.111 102.96C197.794 102.262 211.361 89.0185 212.139 72.6665C213.007 54.3856 198.145 39.2969 179.688 39.2969V39.2969Z"
        fill={tertiaryColor}
      />
      <path
        d="M180.444 48.1821H58.6349C46.2586 48.1821 35.8928 58.1751 35.6582 70.8045C35.418 83.7273 45.6477 94.3179 58.2557 94.3179C66.1663 94.3179 73.1371 90.1468 77.1748 83.8473C78.5861 81.6455 77.0664 78.724 74.4885 78.724H74.4863C73.3997 78.724 72.385 79.2812 71.7926 80.2109C68.6938 85.0723 63.1553 88.1709 56.9603 87.6748C49.0725 87.0434 42.7207 80.5141 42.1542 72.4597C41.4765 62.8296 48.9614 54.7725 58.2557 54.7725H180.141C188.957 54.7725 196.375 61.8572 196.584 70.8528C196.798 80.1191 189.476 87.7266 180.444 87.7266C174.792 87.7266 169.812 84.7445 166.928 80.2425C166.326 79.3031 165.312 78.724 164.212 78.724C161.639 78.724 160.111 81.6394 161.519 83.8376C165.763 90.4645 173.251 94.7388 181.665 94.2845C193.232 93.6601 202.569 83.9962 203.028 72.1837C203.539 59.0407 193.211 48.1821 180.444 48.1821Z"
        fill={primaryColor}
      />
      <path
        d="M156.632 62.0293H78.2618C76.6214 62.0293 75.2915 63.3238 75.2915 64.9205C75.2915 66.5172 76.6214 67.8117 78.2618 67.8117L156.632 67.8117C158.272 67.8117 159.602 66.5172 159.602 64.9205C159.602 63.3238 158.272 62.0293 156.632 62.0293Z"
        fill={secondaryColor}
      />
      <path
        d="M59.288 78.4845C63.9385 78.4845 67.7085 74.8148 67.7085 70.2881C67.7085 65.7614 63.9385 62.0918 59.288 62.0918C54.6374 62.0918 50.8674 65.7614 50.8674 70.2881C50.8674 74.8148 54.6374 78.4845 59.288 78.4845Z"
        fill={secondaryColor}
      />
      <path
        d="M179.506 78.4903C184.157 78.4903 187.927 74.8207 187.927 70.294C187.927 65.7673 184.157 62.0977 179.506 62.0977C174.856 62.0977 171.086 65.7673 171.086 70.294C171.086 74.8207 174.856 78.4903 179.506 78.4903Z"
        fill={secondaryColor}
      />
      <path
        d="M201.226 99.2069C180.658 113.848 170.609 119.57 163.829 151.58C181.864 152.785 204.445 145.907 225.291 131.353C246.137 116.799 260.952 97.628 271.116 85.1063C253.172 83.6327 222.072 84.6529 201.226 99.2069Z"
        fill={tertiaryColor}
      />
      <path
        d="M155.51 165.405C154.669 165.164 153.828 164.922 153.434 164.219C152.281 163.004 152.581 161.026 154.116 159.993C154.116 159.993 174.294 136.08 190.34 124.17C206.241 112.367 241.124 97.3128 239.397 100.44C237.67 103.567 211.136 118.072 195.49 129.575C179.26 141.507 157.886 164.613 157.886 164.613C157.077 165.265 156.434 165.375 155.51 165.405Z"
        fill={primaryColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1970_13548"
        x="-5"
        y="39"
        width="306.116"
        height="212"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.504167 0 0 0 0 0.1815 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1970_13548" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1970_13548" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default GeneralHistoryIcon;
