import { CommonPlayerProps } from './CommonPlayerProps';
import { LessonModel } from '../models';
import { LessonContextProvider } from '../providers';
import { Lesson } from './Lesson';
import { PlayerWrapper } from './PlayerWrapper';

export type LessonPlayerProps = CommonPlayerProps & {
  lesson: LessonModel;
};

export const LessonPlayer = ({ lesson, role, fetcher, onBackClick }: LessonPlayerProps) => {
  return (
    <PlayerWrapper fetcher={fetcher}>
      <LessonContextProvider lesson={lesson} role={role}>
        <Lesson onBackClick={onBackClick} />
      </LessonContextProvider>
    </PlayerWrapper>
  );
};
