import { Badge } from 'react-bootstrap';

type LessonKeywordBadgeProps = {
  keyword: { id: number; name: string };
};

export const LessonKeywordBadge = ({ keyword }: LessonKeywordBadgeProps) => {
  const { name } = keyword;

  return (
    <Badge bg="blue" className="badge-lg mb-2 lesson-keyword__badge">
      {name}
    </Badge>
  );
};
