import { Icon } from '../Icon';

export const TypeInteractiveIcon: Icon = (props) => (
  <svg width="24" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 13C15.7614 13 18 10.7614 18 8C18 5.23858 15.7614 3 13 3C10.2386 3 8 5.23858 8 8C8 10.7614 10.2386 13 13 13Z"
      fill="#E5DCF8"
    />
    <path
      d="M20 4C21.1046 4 22 3.10457 22 2C22 0.895431 21.1046 0 20 0C18.8954 0 18 0.895431 18 2C18 3.10457 18.8954 4 20 4Z"
      fill="#E5DCF8"
    />
    <path
      d="M3.5 7C5.433 7 7 5.433 7 3.5C7 1.567 5.433 0 3.5 0C1.567 0 0 1.567 0 3.5C0 5.433 1.567 7 3.5 7Z"
      fill="#E5DCF8"
    />
    <path
      d="M3.5 15C4.32843 15 5 14.3284 5 13.5C5 12.6716 4.32843 12 3.5 12C2.67157 12 2 12.6716 2 13.5C2 14.3284 2.67157 15 3.5 15Z"
      fill="#E5DCF8"
    />
    <path
      d="M19.4483 12.3529C18.7533 12.3529 18.374 12.7553 18.374 13.4118V15.5294V12.3529C18.374 11.7512 18.0578 11.2941 17.2997 11.2941C16.6047 11.2941 16.2254 11.6965 16.2254 12.3529V15.5294V11.2941C16.2254 10.6923 15.9093 10.2353 15.1511 10.2353C14.4562 10.2353 14.0769 10.6376 14.0769 11.2941V15.5294V15.4715V7.05882C14.0769 6.45705 13.7607 6 13.0026 6C12.3076 6 11.9283 6.4055 11.9283 7.06198V17.6471C11.9283 17.6471 10.854 18.1765 9.77971 17.6471C8.70543 17.1176 6.62065 15.9318 5.48344 16.5882C4.09351 17.4088 5.92469 18.648 8.13552 20.5531C9.77974 21.8824 12.6874 23.6171 16.2254 24C17.2997 24 19.4483 24 20.5225 21.8824C21.5968 19.7647 20.5225 13.4118 20.5225 13.4118C20.5225 13.4118 20.3714 12.3529 19.4483 12.3529Z"
      fill="#9467F3"
    />
  </svg>
);
