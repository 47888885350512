export enum Verb {
  Answered = 'answered',
  Completed = 'completed',
  // Failed = 'failed',
  Favorited = 'favorited',
  Hinted = 'hinted',
  Launched = 'launched',
  Lost = 'lost',
  Passed = 'passed',
  Paused = 'paused',
  Played = 'played',
  // Resumed = 'resumed',
  // Scored = 'scored',
  Seeked = 'seeked',
  Skipped = 'skipped',
  SpeedChanged = 'speed-changed',
  Started = 'started',
  SubtitlesChanged = 'subtitles-changed',
  // Suspended = 'suspended',
  Terminated = 'terminated',
  Active = 'active',
  Inactive = 'inactive',
  Opened = 'opened'
}
