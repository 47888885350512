import React, { VFC } from 'react';
import { useFixedTopHeader } from '@ru-edu/ecl-player';
import { useAuth } from './providers';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Header } from './header';
import { LoadingIndicator } from './LoadingIndicator';
import { Error404 } from './error';
import { LoginForm } from './login';
import { LessonList, TaskList } from './list';
import { LessonDetails, TaskDetails } from './details';

export const App: VFC = () => {
  const location = useLocation();
  const { loading, user } = useAuth();
  const { headerRef, contentRef } = useFixedTopHeader([user, location]);

  return (
    <div ref={contentRef} className="ecl-player-example">
      {loading ? (
        <LoadingIndicator />
      ) : user ? (
        <Routes>
          <Route path="/" element={<Navigate to="/lessons" />} />
          <Route
            path="/lessons"
            element={
              <div className="example-inner">
                <Header ref={headerRef} />
                <LessonList />
              </div>
            }
          />
          <Route path="/lessons/:uid" element={<LessonDetails />} />
          <Route
            path="/tasks"
            element={
              <div className="example-inner">
                <Header ref={headerRef} />
                <TaskList />
              </div>
            }
          />
          <Route path="/tasks/:uid" element={<TaskDetails />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      ) : (
        <LoginForm />
      )}
    </div>
  );
};
