import { Icon } from '../Icon';

export const TypeTextIcon: Icon = (props) => (
  <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y="21" width="21" height="20" rx="4" transform="rotate(-90 0 21)" fill="#D9F1FF" />
    <rect x="4" y="4" width="12" height="2" rx="1" fill="#125694" />
    <rect x="4" y="8" width="12" height="2" rx="1" fill="#125694" />
    <rect x="4" y="12" width="6" height="2" rx="1" fill="#125694" />
  </svg>
);
