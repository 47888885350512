import { Icon } from '../Icon';

export const TypeVideoIcon: Icon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="24" height="21" rx="4" fill="#F9EAC4" />
    <path
      d="M8 6.64368C8 5.89416 8.79408 5.41105 9.45971 5.75561L16.384 9.33997C17.0844 9.70252 17.1091 10.6953 16.4276 11.0922L9.50324 15.1246C8.83658 15.5128 8 15.0319 8 14.2604V6.64368Z"
      fill="#FEAE02"
    />
  </svg>
);
