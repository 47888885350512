import { Button } from 'react-bootstrap';
import { useLesson } from '../../providers';
import { ArrowLineIcon } from '../../assets/icons';
import { getLessonTasks } from '../../utils/LessonUtils';

export const LessonNextButton = () => {
  const { lesson, activeTask, onTaskSelect, onFinish } = useLesson();

  const tasks = getLessonTasks(lesson);
  const taskIdx = tasks.findIndex((task) => task === activeTask);
  const isTaskLast = taskIdx === tasks.length - 1;

  const handleNextClick = () => {
    if (taskIdx < tasks.length - 1) {
      return onTaskSelect(tasks[taskIdx + 1], true);
    }

    onFinish(true);
  };

  return (
    <Button
      variant="outline-light"
      onClick={handleNextClick}
      className={`btn-next text-${isTaskLast ? 'dangerous' : 'primary'}`}
    >
      <span>{isTaskLast ? 'Выйти' : 'Вперёд'}</span>
      <ArrowLineIcon style={{ transform: 'rotate(180deg)' }} />
    </Button>
  );
};
