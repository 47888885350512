import classNames from 'classnames';
import { EomType } from '../../models';
import { TaskPlaybackMethodIcon } from '../TaskPlaybackMethodIcon';

type LessonTaskTypeProps = {
  type: EomType;
  className?: string;
};

export const LessonTaskType = ({ type, className }: LessonTaskTypeProps) => (
  <div className={classNames('lesson-task__playback-method', className)}>
    <TaskPlaybackMethodIcon code={type.taskPlaybackMethod?.code} />
    {type.name}
  </div>
);
