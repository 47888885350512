import { ObjectType } from '@ru-edu/ecl-player';
import { useRequest } from '../../hooks';
import { ListItem } from './ListItem';
import { useLessonApi } from '../providers';
import { LoadingIndicator } from '../LoadingIndicator';

type ListProps = {
  kind: ObjectType;
  baseUrl?: string;
};

export const List = ({ kind, baseUrl }: ListProps) => {
  const { lessonApi } = useLessonApi();
  const { result, loading } = useRequest(() => lessonApi.getList({ kind, limit: 60 }), [kind, lessonApi]);

  if (loading) return <LoadingIndicator />;

  return (
    <div className="container d-block d-sm-grid grid-sm-2 grid-lg-3 grid-xl-4 my-5">
      {result?.map((lesson) => (
        <ListItem key={lesson.uid} result={lesson} baseUrl={baseUrl} />
      ))}
    </div>
  );
};
