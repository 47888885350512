import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Physics];

const PhysicsIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M29.4857 37.0283C39.7108 37.0283 47.9998 28.7392 47.9998 18.5141C47.9998 8.28906 39.7108 0 29.4857 0C19.2606 0 10.9716 8.28906 10.9716 18.5141C10.9716 28.7392 19.2606 37.0283 29.4857 37.0283Z"
      fill={tertiaryColor}
    />
    <path
      d="M22.1713 39.9998C29.9979 39.9998 36.3426 33.6551 36.3426 25.8285C36.3426 18.0019 29.9979 11.6572 22.1713 11.6572C14.3447 11.6572 8 18.0019 8 25.8285C8 33.6551 14.3447 39.9998 22.1713 39.9998Z"
      fill={tertiaryColor}
    />
    <path
      d="M23.5429 18.5132C27.5824 18.5132 30.8571 15.2385 30.8571 11.199C30.8571 7.15945 27.5824 3.88477 23.5429 3.88477C19.5033 3.88477 16.2286 7.15945 16.2286 11.199C16.2286 15.2385 19.5033 18.5132 23.5429 18.5132Z"
      fill={quaternaryColor}
    />
    <path
      d="M36.3427 30.8569C40.3822 30.8569 43.6569 27.6334 43.6569 23.657C43.6569 19.6806 40.3822 16.457 36.3427 16.457C32.3031 16.457 29.0284 19.6806 29.0284 23.657C29.0284 27.6334 32.3031 30.8569 36.3427 30.8569Z"
      fill={quaternaryColor}
    />
    <path
      d="M35.7823 25.382C36.2624 25.864 36.2624 26.6902 35.7823 27.1721L29.1305 33.8162C28.959 33.9883 28.7876 34.126 28.6162 34.2982C24.3988 38.0505 17.9183 37.8784 13.8724 33.8162C9.82641 29.754 9.65497 23.2477 13.3923 18.9789C13.5295 18.8068 13.7009 18.6347 13.8724 18.4626L20.4899 11.8185C20.97 11.3365 21.7929 11.3365 22.2729 11.8185L25.5645 15.1233C26.0446 15.6053 26.0446 16.4315 25.5645 16.9134L19.5642 22.9723C19.187 23.3509 18.8784 23.8329 18.707 24.3149C18.2612 25.5886 18.5355 27.0689 19.5642 28.0672C20.5928 29.1 22.0329 29.3754 23.3015 28.9278C23.7816 28.7557 24.2616 28.4803 24.6388 28.0672L30.6734 22.0428C31.1535 21.5608 31.9764 21.5608 32.4564 22.0428L35.7823 25.382Z"
      fill={secondaryColor}
    />
    <path
      d="M23.5701 19.018L18.4612 13.8886L20.4842 11.8576C20.9642 11.3756 21.7871 11.3756 22.2671 11.8576L25.5588 15.1624C26.0388 15.6443 26.0388 16.4705 25.5588 16.9525L23.5701 19.018Z"
      fill={primaryColor}
    />
    <path
      d="M33.7535 29.2426L28.6447 24.1133L30.6676 22.0822C31.1477 21.6002 31.9706 21.6002 32.4506 22.0822L35.7422 25.387C36.2223 25.8689 36.2223 26.6951 35.7422 27.1771L33.7535 29.2426Z"
      fill={primaryColor}
    />
    <path
      d="M39.2907 19.7446L37.0097 20.7466C36.5463 20.9255 36.0473 20.7466 35.8335 20.2814C35.6553 19.8162 35.8335 19.3152 36.2968 19.1005L38.5779 18.0985C39.0413 17.9196 39.5402 18.0985 39.7541 18.5637C39.9323 19.0289 39.7541 19.5657 39.2907 19.7446Z"
      fill={primaryColor}
    />
    <path
      d="M38.685 21.9624L36.404 22.9644C35.9406 23.1433 35.4416 22.9644 35.2278 22.4992C35.0496 22.034 35.2278 21.533 35.6911 21.3183L37.9722 20.3163C38.4355 20.1374 38.9345 20.3163 39.1484 20.7815C39.3622 21.2467 39.1484 21.7477 38.685 21.9624Z"
      fill={primaryColor}
    />
    <path
      d="M28.6523 9.67138L26.3712 10.6733C25.9079 10.8523 25.4089 10.6733 25.1951 10.2081C25.0169 9.74295 25.1951 9.24196 25.6584 9.02726L27.9395 8.02529C28.4028 7.84637 28.9018 8.02529 29.1157 8.49049C29.2939 8.95569 29.1157 9.49246 28.6523 9.67138Z"
      fill={primaryColor}
    />
    <path
      d="M28.0466 11.8921L25.7655 12.8941C25.3022 13.073 24.8032 12.8941 24.5893 12.4289C24.4111 11.9637 24.5893 11.4627 25.0527 11.248L27.3338 10.246C27.7971 10.0671 28.2961 10.246 28.5099 10.7112C28.7238 11.1764 28.5099 11.6774 28.0466 11.8921Z"
      fill={primaryColor}
    />
    <path
      d="M33.3744 9.08011C34.0634 9.08011 34.6219 8.51937 34.6219 7.82765C34.6219 7.13594 34.0634 6.5752 33.3744 6.5752C32.6855 6.5752 32.127 7.13594 32.127 7.82765C32.127 8.51937 32.6855 9.08011 33.3744 9.08011Z"
      fill={primaryColor}
    />
    <path
      d="M37.8295 8.36497C38.2232 8.36497 38.5424 8.04455 38.5424 7.64928C38.5424 7.25402 38.2232 6.93359 37.8295 6.93359C37.4358 6.93359 37.1167 7.25402 37.1167 7.64928C37.1167 8.04455 37.4358 8.36497 37.8295 8.36497Z"
      fill={primaryColor}
    />
    <path
      d="M37.8296 14.4476C38.8138 14.4476 39.6117 13.6465 39.6117 12.6584C39.6117 11.6702 38.8138 10.8691 37.8296 10.8691C36.8454 10.8691 36.0475 11.6702 36.0475 12.6584C36.0475 13.6465 36.8454 14.4476 37.8296 14.4476Z"
      fill={primaryColor}
    />
  </svg>
);

export default PhysicsIcon;
