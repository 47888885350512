import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';

type CollapsibleItemContext = {
  collapsed: boolean;
  toggle: () => void;
};

const CollapsibleItemContext = createContext<CollapsibleItemContext | undefined>(undefined);

type CollapsibleItemProviderProps = {
  collapsed?: boolean;
};

export const CollapsibleItemProvider: FC<CollapsibleItemProviderProps> = ({
  collapsed: initiallyCollapsed,
  children
}) => {
  const [collapsed, setCollapsed] = useState(initiallyCollapsed || false);

  const handleToggle = useCallback(() => setCollapsed((collapsed) => !collapsed), []);

  useEffect(() => setCollapsed(initiallyCollapsed || false), [initiallyCollapsed]);

  return (
    <CollapsibleItemContext.Provider value={{ collapsed, toggle: handleToggle }}>
      {children}
    </CollapsibleItemContext.Provider>
  );
};

export const useCollapsibleItem = () => {
  const context = useContext(CollapsibleItemContext);

  if (!context) {
    throw new Error('useCollapsibleItem must be used within a CollapsibleItemProvider.');
  }

  return context;
};
