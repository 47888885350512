import classNames from 'classnames';
import { FC } from 'react';
import { Collapse } from 'react-bootstrap';
import { useCollapsibleItem } from './CollapsibleItemProvider';

type CollapsibleBodyProps = {
  className?: string;
};

export const CollapsibleBody: FC<CollapsibleBodyProps> = ({ children, className }) => {
  const { collapsed } = useCollapsibleItem();

  return (
    <Collapse in={!collapsed}>
      <div className="accordion-collapse">
        <div className={classNames('accordion-body', className)}>{children}</div>
      </div>
    </Collapse>
  );
};
