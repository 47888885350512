import { MathJax } from 'better-react-mathjax';
import { Badge } from 'react-bootstrap';
import { LessonBasicConceptModel } from '../../models';
import { TooltipTrigger } from '../TooltipTrigger';
import { Text } from '../Text';
import { InfoFillIcon } from '../../assets/icons';

type LessonBasicConceptBadgeProps = {
  basicConcept: LessonBasicConceptModel;
};

export const LessonBasicConceptBadge = ({ basicConcept }: LessonBasicConceptBadgeProps) => {
  const { name, definition, source, sourceLink } = basicConcept;

  return (
    <Badge bg="blue" className="badge-lg mb-2 lesson-basic-concept__badge">
      {name}
      {definition && (
        <TooltipTrigger
          id={basicConcept.uid}
          header={name.toUpperCase()}
          icon={<InfoFillIcon />}
          placement="right-start"
          title={`Дополнительная информация о «${name}»`}
        >
          <MathJax>
            <Text className="lesson-basic-concept__description">{definition}</Text>
          </MathJax>
          {source && (
            <div className="lesson-basic-concept__source">
              Источник:{' '}
              {sourceLink ? (
                <a href={sourceLink} target="_blank" rel="noreferrer">
                  {source}
                </a>
              ) : (
                source
              )}
            </div>
          )}
        </TooltipTrigger>
      )}
    </Badge>
  );
};
