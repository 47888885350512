import { useLesson } from '../../../providers';
import { CollapsibleList } from '../../collapsible-list';
import { LessonDetailsNavigationUnit } from './LessonDetailsNavigationUnit';

export const LessonDetailsNavigation = () => {
  const { lesson } = useLesson();

  return (
    <section className="lesson-details__navigation mt-45">
      <div className="d-flex flex-row flex-wrap align-items-center justify-content-between mb-3 mb-lg-4">
        <h2 className="h1 mb-0 subheader-lg-1 subheader-md-2">Этапы урока</h2>
      </div>

      <CollapsibleList>
        {lesson?.units.map(
          (unit) => unit && <LessonDetailsNavigationUnit key={unit.uid} name={unit.unitType.name} unit={unit} />
        )}
      </CollapsibleList>
    </section>
  );
};
