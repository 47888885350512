import { forwardRef, useMemo } from 'react';
import { DataNode } from 'domhandler';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

export type TextProps = {
  imageBaseUrl?: string;
  children?: string;
  className?: string;
};

export const Text = forwardRef<HTMLDivElement, TextProps>(function Text({ children, className, imageBaseUrl }, ref) {
  const content = useMemo(() => {
    if (!children) return null;

    const options: HTMLReactParserOptions = {
      replace: (node) => {
        if (node instanceof DataNode && !node.data.replace(/\u200B/g, ' ').trim()) return <></>;

        if (!(node instanceof Element)) return node;

        if (['link', 'meta', 'script', 'style'].includes(node.name)) return <></>;

        if (['html', 'body'].includes(node.name)) return <>{domToReact(node.children, options)}</>;

        if (node.name === 'img' && node.attribs) {
          const { src } = node.attribs;

          node.attribs.src = getImageSrc(src, imageBaseUrl);

          return node;
        }
      },
      trim: true
    };

    return parse(children, options);
  }, [children, imageBaseUrl]);

  return (
    <div ref={ref} className={className}>
      {content}
    </div>
  );
});

const getImageSrc = (src: string, imageBaseUrl?: string) => {
  if (!imageBaseUrl || src.startsWith('http://') || src.startsWith('https://') || src.startsWith('data:')) {
    return src;
  }

  return `${imageBaseUrl}${src.startsWith('/') ? src : `/${src}`}`;
};
