import { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { EomComponentProps } from './EomComponentProps';
import { useTestingSystemApi } from '../../../providers';
import { useLoadScript } from '../../../hooks';

export type TestingPlayerOptions = {
  scriptUrl: string;
  apiUrl: string;
};

export type EomTestingPlayerProps = Omit<EomComponentProps, 'testingPlayerOptions'> & {
  testingPlayerOptions: TestingPlayerOptions;
};

export const EomTestingPlayer = ({ task, testingPlayerOptions }: EomTestingPlayerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { testingSystemApi } = useTestingSystemApi();
  const { ready } = useLoadScript(testingPlayerOptions.scriptUrl, false, true);
  const [token, setToken] = useState<string>();

  useEffect(() => {
    testingSystemApi
      .getToken(task.number)
      .then((token) => setToken(token))
      .catch(() => setToken(undefined));
  }, [task.number, testingSystemApi]);

  useEffect(() => {
    const containerElement = containerRef.current;

    if (!containerElement || !ready || !token) {
      return;
    }

    // @ts-ignore
    const testingPlayer = new TestingPlayer({ url: options.apiUrl });

    testingPlayer.init({ token }).then(() => testingPlayer.start(containerElement));
  }, [ready, containerRef, token]);

  if (!ready) {
    return <Spinner animation="grow" variant="light" className="align-self-center mt-5" />;
  }

  return <div ref={containerRef} className="flex-grow-1 overflow-auto" />;
};
