import { createContext, FC, useContext, useMemo } from 'react';
import { IXApi, XApi } from '../api/xapi';
import { Fetcher } from '../api/core';

type XApiContext = {
  xApi: IXApi;
};

const XApiContext = createContext<XApiContext | undefined>(undefined);

type XApiProviderProps = {
  fetcher: Fetcher;
};

export const XApiProvider: FC<XApiProviderProps> = ({ fetcher, children }) => {
  const contextValue = useMemo(() => ({ xApi: new XApi(fetcher) }), [fetcher]);

  return <XApiContext.Provider value={contextValue}>{children}</XApiContext.Provider>;
};

export const useXApi = () => {
  const context = useContext(XApiContext);

  if (!context) {
    throw new Error('useXApi must be used within a XApiProvider.');
  }

  return context;
};
