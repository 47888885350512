import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.RussianSpiritualAndMoralCulture];

const RussianSpiritualAndMoralCultureIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="261" height="213" viewBox="0 -20 261 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1115_301237)">
      <path
        d="M99.3706 18.7766L105.343 46.9178L96.2402 50.1862L90.6465 46.9178L94.885 26.9476L96.6191 18.7766C97.0599 18.8832 97.5212 18.9409 97.9949 18.9409C98.4686 18.9409 98.9299 18.8832 99.3706 18.7766Z"
        fill={tertiaryColor}
      />
      <path
        d="M108.695 45.6951L109.893 55.0962L108.909 55.2427L98.5042 60.2874L87.078 55.2427L86.0977 55.0962L87.2922 45.6951H90.6451H105.342H108.695Z"
        fill={secondaryColor}
      />
      <path
        d="M109.897 55.0962L111.132 64.7904L97.4778 69.391L84.8652 64.7904L85.8579 57.0013L86.0968 55.0962H86.1009L87.0813 55.2427H108.912L109.897 55.0962Z"
        fill={tertiaryColor}
      />
      <path
        d="M97.9967 10.2278C100.615 10.2278 102.734 12.5121 102.734 15.3347C102.734 17.762 101.161 19.7981 99.0516 20.3123V20.3159C98.7138 20.3976 98.36 20.442 97.9967 20.442C97.6334 20.442 97.28 20.3976 96.9418 20.3159V20.3123C94.8353 19.7981 93.2598 17.7624 93.2598 15.3347C93.2598 12.5121 95.3819 10.2278 97.9967 10.2278Z"
        fill={tertiaryColor}
      />
      <path
        d="M114.125 80.922L123.78 155.646H80.4384L72.7871 104.011C74.3657 102.055 82.1382 80.922 82.1382 80.922V62.3157H84.8883H111.38H114.125V80.922Z"
        fill={secondaryColor}
      />
      <path
        d="M175.869 37.87L169.969 23.1771V15.7507C169.969 14.8189 169.199 14.0635 168.25 14.0635C167.301 14.0635 166.532 14.8189 166.532 15.7507V23.1735L160.63 37.87H158.17V54.9754H168.25H178.33V37.87H175.869Z"
        fill={primaryColor}
      />
      <path
        d="M168.296 55.7686C166.59 55.7686 165.207 54.2774 165.207 52.4381V47.1091C165.207 45.2698 166.59 43.7786 168.296 43.7786C170.003 43.7786 171.386 45.2698 171.386 47.1091V52.4381C171.386 54.2779 170.003 55.7686 168.296 55.7686Z"
        fill={quaternaryColor}
      />
      <path d="M148.688 99.7229V138.087V155.977H187.821V138.087V99.7229H148.688Z" fill={primaryColor} />
      <path d="M197.304 102.28H191.375V115.065H197.304V102.28Z" fill={primaryColor} />
      <path
        d="M120.708 100.67H130.419H139.199V101.133V155.646H122.718H111.924V101.133V100.67H120.708Z"
        fill={primaryColor}
      />
      <path d="M85.4803 100.498V155.404H57.4707V100.498V100.032H85.4803V100.498Z" fill={primaryColor} />
      <path
        d="M217.465 155.977H190.963H187.818V141.641V131.333V108.672H194.318H200.724H207.129H213.535H217.465V155.977Z"
        fill={primaryColor}
      />
      <path
        d="M183.111 82.3663V52.4185H179.464H168.257H157.05H153.402V82.3663H148.688V99.7229H149.335H158.034H168.065H178.096H187.178H187.821V82.3663H183.111Z"
        fill={primaryColor}
      />
      <path
        d="M140.045 100.671C140.396 100.671 140.732 100.502 140.931 100.202C142.091 98.458 142.753 96.4865 142.753 94.3964C142.753 80.3329 131.962 79.9002 126.151 72.5439C123.52 75.8759 119.863 77.7885 116.65 80.1945C112.778 83.0894 109.549 86.7069 109.549 94.3964C109.549 96.486 110.211 98.458 111.371 100.202C111.571 100.502 111.907 100.671 112.257 100.671H140.045Z"
        fill={tertiaryColor}
      />
      <path
        d="M86.0537 101.002C87.4171 99.1458 88.2045 97.0068 88.2045 94.7272C88.2045 91.9751 87.7894 89.7423 87.0797 87.8816C85.7557 84.3895 83.3898 82.1956 80.7667 80.2743C77.6413 77.9852 74.1424 76.0942 71.6022 72.8748C69.1493 75.9774 65.8122 77.8511 62.774 80.032C59.9184 82.0788 57.3285 84.4025 55.9795 88.2754C55.3569 90.0538 55 92.1655 55 94.7272C55 96.8467 55.6807 98.8446 56.8719 100.606C57.0421 100.858 57.3256 101.002 57.6211 101.002H86.0537Z"
        fill={tertiaryColor}
      />
      <path
        d="M168.25 56.2542C173.821 56.2542 178.33 61.1153 178.33 67.1214C178.33 73.1201 173.821 77.9886 168.25 77.9886C162.686 77.9886 158.17 73.1201 158.17 67.1214C158.17 61.1153 162.686 56.2542 168.25 56.2542Z"
        fill={secondaryColor}
      />
      <path
        d="M168.252 62.6465C170.546 62.6465 172.403 64.6481 172.403 67.1212C172.403 69.5912 170.546 71.596 168.252 71.596C165.961 71.596 164.102 69.5912 164.102 67.1212C164.102 64.6481 165.961 62.6465 168.252 62.6465Z"
        fill={tertiaryColor}
      />
      <path d="M171.216 136.233V155.977H126.152V135.521H143.363H154.252H171.216V136.233Z" fill={primaryColor} />
      <path
        d="M132.078 94.3964C132.078 96.6725 131.8 98.8145 131.314 100.671H120.987C120.5 98.8145 120.219 96.6725 120.219 94.3964C120.219 80.3329 124.075 79.9002 126.148 72.5439C128.226 79.9002 132.078 80.3329 132.078 94.3964Z"
        fill={secondaryColor}
      />
      <path
        d="M85.7298 100.67H86.0671C87.4219 98.7466 88.2042 96.5297 88.2042 94.167C88.2042 91.3145 87.7918 89.0002 87.0866 87.0716L86.7608 87.2152C69.8524 86.3182 64.0789 79.1421 64.0789 79.1421L62.9365 78.9358C60.0992 81.0572 57.5258 83.4657 56.1855 87.4798L56.6557 87.6637C56.6557 87.6637 69.0276 98.4277 85.7298 100.67Z"
        fill={secondaryColor}
      />
      <path
        d="M71.476 125.386C69.7699 125.386 68.3867 123.895 68.3867 122.056V115.839C68.3867 113.999 69.7699 112.508 71.476 112.508C73.1821 112.508 74.5653 113.999 74.5653 115.839V122.056C74.5653 123.896 73.1821 125.386 71.476 125.386Z"
        fill={quaternaryColor}
      />
      <path
        d="M98.4076 89.2571C96.7015 89.2571 95.3184 87.7659 95.3184 85.9266V79.7095C95.3184 77.8701 96.7015 76.3789 98.4076 76.3789C100.114 76.3789 101.497 77.8701 101.497 79.7095V85.9266C101.497 87.7664 100.114 89.2571 98.4076 89.2571Z"
        fill={quaternaryColor}
      />
      <path
        d="M98.4076 109.807C96.7015 109.807 95.3184 108.315 95.3184 106.476V100.259C95.3184 98.4197 96.7015 96.9285 98.4076 96.9285C100.114 96.9285 101.497 98.4197 101.497 100.259V106.476C101.497 108.316 100.114 109.807 98.4076 109.807Z"
        fill={quaternaryColor}
      />
      <path
        d="M125.701 125.386C123.995 125.386 122.611 123.895 122.611 122.056V115.839C122.611 113.999 123.995 112.508 125.701 112.508C127.407 112.508 128.79 113.999 128.79 115.839V122.056C128.79 123.896 127.407 125.386 125.701 125.386Z"
        fill={quaternaryColor}
      />
      <path
        d="M157.703 126.257C155.996 126.257 154.613 124.766 154.613 122.926V116.709C154.613 114.87 155.996 113.379 157.703 113.379C159.409 113.379 160.792 114.87 160.792 116.709V122.926C160.792 124.766 159.409 126.257 157.703 126.257Z"
        fill={secondaryColor}
      />
      <path
        d="M168.374 126.257C166.668 126.257 165.285 124.766 165.285 122.926V116.709C165.285 114.87 166.668 113.379 168.374 113.379C170.081 113.379 171.464 114.87 171.464 116.709V122.926C171.464 124.766 170.081 126.257 168.374 126.257Z"
        fill={secondaryColor}
      />
      <path
        d="M179.048 126.257C177.342 126.257 175.959 124.766 175.959 122.926V116.709C175.959 114.87 177.342 113.379 179.048 113.379C180.754 113.379 182.138 114.87 182.138 116.709V122.926C182.138 124.766 180.754 126.257 179.048 126.257Z"
        fill={secondaryColor}
      />
      <path d="M207.977 102.28H200.861V115.065H207.977V102.28Z" fill={primaryColor} />
      <path d="M217.466 102.28H211.537V115.065H217.466V102.28Z" fill={primaryColor} />
      <path
        d="M181.967 95.8876L181.967 89.4951H175.963V95.8876H171.219L171.219 89.4951H165.29L165.29 95.8876H160.546L160.546 89.4951H154.617L154.617 95.8876H148.688L148.688 101.002H154.149H161.222H164.522H171.595H174.895H181.967H187.821L187.821 95.8876H181.967Z"
        fill={secondaryColor}
      />
      <path
        d="M168.298 0L171.254 6.45688L177.863 7.49247L173.08 12.5185L174.209 19.6153L168.298 16.2643L162.386 19.6153L163.515 12.5185L158.732 7.49247L165.342 6.45688L168.298 0Z"
        fill={secondaryColor}
      />
      <path
        d="M208.12 79.5074C189.614 94.4033 180.558 100.199 174.757 133.252C191.137 134.729 211.556 127.897 230.315 113.095C249.075 98.2929 262.309 78.6338 271.396 65.7996C255.094 64.0454 226.88 64.7053 208.12 79.5074Z"
        fill={tertiaryColor}
      />
      <path
        d="M167.238 148.089C166.47 147.828 165.702 147.568 165.335 146.834C164.272 145.56 164.523 143.514 165.909 142.463C165.909 142.463 184.008 117.943 198.479 105.806C212.819 93.7792 244.398 78.6244 242.861 81.8429C241.324 85.0613 217.337 99.753 203.225 111.472C188.587 123.63 169.392 147.299 169.392 147.299C168.662 147.964 168.078 148.07 167.238 148.089Z"
        fill={secondaryColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1115_301237"
        x="51"
        y="0"
        width="224.396"
        height="163.977"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1115_301237" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1115_301237" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default RussianSpiritualAndMoralCultureIcon;
