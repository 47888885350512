import classNames from 'classnames';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Button, OverlayTrigger, OverlayTriggerProps, Tooltip } from 'react-bootstrap';
import { InfoIcon } from '../assets/icons';
import { MediaBreakpoint, useMediaBreakpointDown } from '../hooks';
import { Modal } from './modal';

type TooltipTriggerProps = {
  id?: string;
  header?: ReactNode;
  icon?: ReactNode;
  placement?: OverlayTriggerProps['placement'];
  title?: string;
};

export const TooltipTrigger: FC<TooltipTriggerProps> = ({ id, header, icon, placement, title, children }) => {
  const [show, setShow] = useState(false);
  const isMobile = useMediaBreakpointDown(MediaBreakpoint.Sm);
  const container = useRef<HTMLElement | null>(null);

  useEffect(() => {
    container.current = document.querySelector('.ecl-container');

    return () => {
      container.current = null;
    };
  }, []);

  const handleOverlayToggle = useCallback(() => setShow((prevShow) => !prevShow), []);

  const trigger = (
    <Button
      variant="link"
      className="p-0 ms-2"
      title={title}
      role="tooltip"
      onClick={isMobile ? handleOverlayToggle : undefined}
    >
      {icon || <InfoIcon />}
    </Button>
  );

  if (isMobile) {
    return (
      <>
        {trigger}
        <Modal
          show={show}
          centered={true}
          scrollable={true}
          className={classNames('modal--type2 lesson-basic-concept__modal', { 'modal--border': !!header })}
          onHide={handleOverlayToggle}
        >
          {header && (
            <Modal.Header closeButton={true} className="p-3 fz-md">
              <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
          )}
          <Modal.Body className={classNames('p-3', { scrollbar: !!header })}>{children}</Modal.Body>
        </Modal>
      </>
    );
  }

  return (
    <OverlayTrigger
      container={container.current}
      trigger="click"
      placement={placement}
      rootClose={true}
      flip={true}
      show={show}
      onToggle={handleOverlayToggle}
      overlay={<Tooltip className="lesson-basic-concept__tooltip">{children}</Tooltip>}
    >
      {trigger}
    </OverlayTrigger>
  );
};
