import { useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { useLesson } from '../../providers';
import { LessonHeader } from '../LessonHeader';
import { LessonModuleContent } from './LessonModuleContent';
import { LessonModuleNavigation } from './LessonModuleNavigation';
import { LessonModuleFooter } from './LessonModuleFooter';
import { useFixedTopHeader } from '../../hooks';

export const LessonModule = () => {
  const { onModuleSelect } = useLesson();
  const { headerRef, contentRef, footerRef } = useFixedTopHeader();

  const handleBackLinkClick = useCallback(() => onModuleSelect(), [onModuleSelect]);

  return (
    <Container ref={contentRef} className="lesson-module d-flex flex-column flex-grow-1">
      <LessonHeader ref={headerRef} title="На страницу урока" onBackClick={handleBackLinkClick} />

      <section className="lesson-module__content-wrapper d-flex flex-grow-1">
        <LessonModuleContent />
        <LessonModuleNavigation />
      </section>
      <LessonModuleFooter ref={footerRef} />
    </Container>
  );
};
