import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.ClassHour];

const ClassHourIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="-30 -20 240 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1102_298739)">
      <path
        d="M88.3504 116.341H175.214C176.632 116.341 177.696 115.284 177.696 113.873V108.233C177.696 106.822 176.632 105.765 175.214 105.765H33.3958C24.8868 105.765 18.1504 112.463 18.1504 120.924V143.84C18.1504 151.949 24.8868 158.647 33.3958 158.647H175.214C176.632 158.647 177.696 157.59 177.696 156.179V150.539C177.696 149.128 176.632 148.071 175.214 148.071H88.3504C84.8049 148.071 81.9686 145.25 81.9686 141.725V122.687C81.9686 119.162 84.8049 116.341 88.3504 116.341Z"
        fill={primaryColor}
      />
      <path
        d="M89.7673 95.1885H2.90369C1.48551 95.1885 0.421875 96.2462 0.421875 97.6564V103.297C0.421875 104.707 1.48551 105.765 2.90369 105.765H144.722C153.231 105.765 159.967 99.0666 159.967 90.6054V67.6896C159.967 59.2284 153.231 52.53 144.722 52.53H2.90369C1.48551 52.8825 0.421875 53.9402 0.421875 55.3504V60.9912C0.421875 62.4014 1.48551 63.459 2.90369 63.459H89.7673C93.3128 63.459 96.1491 66.2794 96.1491 69.8049V88.8426C96.1491 92.3681 93.3128 95.1885 89.7673 95.1885Z"
        fill={tertiaryColor}
      />
      <path
        d="M141.752 68.3146C141.752 66.1054 139.962 64.3146 137.752 64.3146H8.48014C4.89204 74.4382 4.89204 84.5618 8.48014 94.6854H137.752C139.962 94.6854 141.752 92.8945 141.752 90.6854V68.3146Z"
        fill={quaternaryColor}
      />
      <path
        d="M107.496 42.306H20.6322C19.214 42.306 18.1504 43.3636 18.1504 44.7738V50.4146C18.1504 51.8248 19.214 52.8825 20.6322 52.8825H162.45C170.96 52.8825 177.696 46.184 177.696 37.7228V15.1596C177.696 6.69845 170.96 0 162.45 0H20.6322C19.214 0 18.1504 1.05765 18.1504 2.46785V8.10865C18.1504 9.51885 19.214 10.5765 20.6322 10.5765H107.496C111.041 10.5765 113.878 13.3969 113.878 16.9224V35.9601C113.878 39.4856 111.041 42.306 107.496 42.306Z"
        fill={primaryColor}
      />
      <path
        d="M154.276 14.7191C154.276 12.51 152.485 10.7191 150.276 10.7191H29.7718C26.4198 21.4382 26.4198 32.1573 29.7718 42.8764H150.276C152.485 42.8764 154.276 41.0856 154.276 38.8764V14.7191Z"
        fill={quaternaryColor}
      />
      <path
        d="M36.2012 144.281C36.2012 146.49 37.992 148.281 40.2012 148.281H169.473C173.062 137.562 173.062 126.843 169.473 116.124H40.2012C37.992 116.124 36.2012 117.914 36.2012 120.124V144.281Z"
        fill={quaternaryColor}
      />
      <path d="M185.808 128.976L197.926 126.84L187.598 68.925L175.48 71.0617L185.808 128.976Z" fill={quaternaryColor} />
      <path
        d="M196.717 160.786L198.435 160.387V159.988C199.294 148.806 199.294 138.024 197.576 126.843L184.688 128.839C187.265 139.622 191.132 150.004 196.287 159.988L196.717 160.786Z"
        fill={tertiaryColor}
      />
      <path
        d="M168.699 1.93962C172.222 1.1496 175.352 3.51964 176.135 6.67969C180.831 25.245 184.745 43.8102 187.484 62.7705L188.267 68.6956L173.004 71.4607L171.83 65.5356C167.917 46.9703 165.177 28.01 163.221 9.04972C163.221 5.88968 165.569 2.72963 168.699 1.93962Z"
        fill={secondaryColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1102_298739"
        x="0"
        y="0"
        width="203"
        height="167"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1102_298739" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1102_298739" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default ClassHourIcon;
