import { RefObject, useState } from 'react';
import { useOptimizedResize } from './useOptimezedResize';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

export const useTextOverflow = (ref: RefObject<Element>) => {
  const [overflow, setOverflow] = useState(false);
  const [windowWidth] = useOptimizedResize();

  useIsomorphicLayoutEffect(() => {
    const headerElement = ref.current;

    if (!headerElement) return;

    setOverflow((overflow) => {
      const newOverflow = headerElement.scrollHeight > 1.25 * headerElement.clientHeight;

      return overflow !== newOverflow ? newOverflow : overflow;
    });
  }, [overflow, ref, windowWidth]);

  return overflow;
};
