import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Music];

const MusicIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M40.9965 4.69944C40.9965 1.76828 38.4898 -0.447205 35.6059 0.0771348L16.3315 4.11082C14.0976 4.51699 12.2207 7.05123 12.2207 9.32175V36.9974H16.3327V14.6071L36.8857 10.2771V30.8312H40.9965V4.69944Z"
      fill={secondaryColor}
    />
    <circle cx="11.1939" cy="35.9697" r="7.19394" fill={primaryColor} />
    <circle cx="33.8053" cy="29.8032" r="7.19394" fill={primaryColor} />
  </svg>
);

export default MusicIcon;
