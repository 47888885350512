import subjectColorsStyles from '../styles/lesson/subject-colors.module.scss';
import { LessonSubjectCode } from './LessonSubjectCode';

export type LessonSubjectColorMap = {
  baseColorName: string;
  primaryColor?: string;
  secondaryColor?: string;
  tertiaryColor?: string;
  quaternaryColor?: string;
};

const getSubjectColors = (
  baseColorName: 'blue' | 'green' | 'orange' | 'purple' | 'red' | 'cyan' | 'scarlet'
): LessonSubjectColorMap => ({
  baseColorName,
  primaryColor: subjectColorsStyles[`${baseColorName}Primary`],
  secondaryColor: subjectColorsStyles[`${baseColorName}Secondary`],
  tertiaryColor: subjectColorsStyles[`${baseColorName}Tertiary`],
  quaternaryColor: subjectColorsStyles[`${baseColorName}Quaternary`]
});

const blueColors = getSubjectColors('blue');
const greenColors = getSubjectColors('green');
const orangeColors = getSubjectColors('orange');
const purpleColors = getSubjectColors('purple');
const redColors = getSubjectColors('red');
const cyanColors = getSubjectColors('cyan');
const scarletColors = getSubjectColors('scarlet');

export const LessonSubjectColors: Readonly<Record<LessonSubjectCode, LessonSubjectColorMap>> = {
  // Алгебра
  [LessonSubjectCode.Algebra]: blueColors,
  // Биология
  [LessonSubjectCode.Biology]: greenColors,
  // Химия
  [LessonSubjectCode.Chemistry]: greenColors,
  // Информатика
  [LessonSubjectCode.ComputerScience]: blueColors,
  // Английский язык
  [LessonSubjectCode.English]: purpleColors,
  // Всеобщая история
  [LessonSubjectCode.GeneralHistory]: orangeColors,
  // Геометрия
  [LessonSubjectCode.Geometry]: blueColors,
  // Математика
  [LessonSubjectCode.Maths]: blueColors,
  // Физика
  [LessonSubjectCode.Physics]: greenColors,
  // Вероятность и статистика
  [LessonSubjectCode.ProbabilityAndStatistics]: blueColors,
  // История России
  [LessonSubjectCode.RussiaHistory]: orangeColors,
  // Русский язык
  [LessonSubjectCode.RussianLanguage]: purpleColors,
  // Классный час
  [LessonSubjectCode.ClassHour]: redColors,
  // Музыка
  [LessonSubjectCode.Music]: cyanColors,
  // Основы духовно-нравственной культуры народов России
  [LessonSubjectCode.RussianSpiritualAndMoralCulture]: scarletColors,
  // Основы религиозных культур и светской этики. Основы светской этики
  [LessonSubjectCode.RussianReligiousCultureAndSecularEthics]: scarletColors
};
