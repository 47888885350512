import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Chemistry];

const ChemistryIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="0 0 240 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1761_12812)">
      <path
        d="M124.057 105.015C124.057 106.564 122.815 107.82 121.284 107.82C119.753 107.82 118.511 106.564 118.511 105.015C118.512 103.466 119.753 102.209 121.284 102.208C122.815 102.208 124.056 103.466 124.057 105.015V105.015Z"
        fill={primaryColor}
      />
      <path
        d="M216.727 101.373C216.729 110.206 209.564 117.369 200.727 117.37C191.891 117.37 184.725 110.206 184.727 101.373C184.729 92.5402 191.895 85.3712 200.727 85.3701C209.56 85.3701 216.725 92.5381 216.727 101.373V101.373Z"
        fill={secondaryColor}
      />
      <path
        d="M132.977 32.0596C132.978 36.7068 129.253 40.4752 124.659 40.4758C120.065 40.4758 116.339 36.7068 116.341 32.0596C116.342 27.4124 120.067 23.6407 124.659 23.6401C129.251 23.6401 132.976 27.4113 132.977 32.0596Z"
        fill={tertiaryColor}
      />
      <path
        d="M68.3637 78.3625C68.3666 90.755 58.4328 100.804 46.1819 100.806C33.9309 100.806 23.9971 90.755 24 78.3625C24.0029 65.9701 33.9367 55.9121 46.1819 55.9106C58.4271 55.9106 68.3609 65.9671 68.3637 78.3625V78.3625Z"
        fill={tertiaryColor}
      />
      <path
        d="M193.098 177.167L146.991 91.2193V72.2339H103.172V91.2164L57.0634 177.167C51.7798 187.016 58.8257 199 69.8997 199H180.261C191.335 199 198.381 187.016 193.098 177.167Z"
        fill={tertiaryColor}
      />
      <path
        d="M180.262 198.998H69.8999C58.8245 198.998 51.7791 187.015 57.0632 177.168L80.6643 133.179C83.2536 132.171 85.9579 131.435 88.7626 130.999C101.379 129.037 115.795 137.407 124.262 142.323C132.724 147.235 147.145 155.604 159.762 153.643C165.96 152.679 171.666 150.257 176.734 146.663L193.098 177.168C198.382 187.015 191.337 198.998 180.262 198.998Z"
        fill={primaryColor}
      />
      <path
        d="M180.262 198.998H69.8999C58.8245 198.998 51.7791 187.015 57.0632 177.168L72.9871 147.486C77.7494 150.63 83.0478 152.756 88.7626 153.642C101.379 155.604 115.795 147.234 124.262 142.323C132.724 137.407 147.145 129.037 159.762 130.998C163.342 131.555 166.76 132.597 169.981 134.074L193.098 177.168C198.382 187.015 191.337 198.998 180.262 198.998V198.998Z"
        fill={secondaryColor}
      />
      <path
        d="M146.965 159.601C148.947 159.601 150.554 157.974 150.554 155.968C150.554 153.962 148.947 152.335 146.965 152.335C144.982 152.335 143.375 153.962 143.375 155.968C143.375 157.974 144.982 159.601 146.965 159.601Z"
        fill={quaternaryColor}
      />
      <path
        d="M163.435 182.476C165.417 182.476 167.024 180.849 167.024 178.843C167.024 176.837 165.417 175.21 163.435 175.21C161.452 175.21 159.845 176.837 159.845 178.843C159.845 180.849 161.452 182.476 163.435 182.476Z"
        fill={tertiaryColor}
      />
      <path
        d="M109.45 175.156C111.432 175.156 113.039 173.53 113.039 171.523C113.039 169.517 111.432 167.891 109.45 167.891C107.467 167.891 105.86 169.517 105.86 171.523C105.86 173.53 107.467 175.156 109.45 175.156Z"
        fill={tertiaryColor}
      />
      <path
        d="M81.9996 181.561C83.9821 181.561 85.5893 179.934 85.5893 177.928C85.5893 175.922 83.9821 174.295 81.9996 174.295C80.0171 174.295 78.4099 175.922 78.4099 177.928C78.4099 179.934 80.0171 181.561 81.9996 181.561Z"
        fill={primaryColor}
      />
      <path
        d="M44.5004 151C43.5507 151 42.6018 150.638 41.8769 149.914L34.0867 142.123C32.6378 140.674 32.6378 138.325 34.0867 136.877L41.8769 129.086C43.3259 127.638 45.6741 127.638 47.1231 129.086L54.9133 136.877C56.3622 138.326 56.3622 140.675 54.9133 142.123L47.1231 149.914C46.399 150.638 45.45 151 44.5004 151Z"
        fill={tertiaryColor}
      />
      <path
        d="M184 63C183.34 63 182.68 62.7481 182.175 62.2444L176.756 56.8248C175.748 55.8168 175.748 54.1827 176.756 53.1752L182.175 47.7556C183.183 46.7481 184.817 46.7481 185.825 47.7556L191.244 53.1752C192.252 54.1832 192.252 55.8173 191.244 56.8248L185.825 62.2444C185.321 62.7476 184.66 63 184 63Z"
        fill={tertiaryColor}
      />
      <rect x="93" y="60" width="64" height="13" rx="6.5" fill={tertiaryColor} />
    </g>
    <defs>
      <filter
        id="filter0_d_1761_12812"
        x="-6"
        y="23.6401"
        width="252.727"
        height="235.36"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.3125 0 0 0 0 0.03125 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1761_12812" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1761_12812" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default ChemistryIcon;
