import classNames from 'classnames';
import { FC } from 'react';
import { CollapsibleItem } from './CollapsibleItem';
import { CollapsibleHeader } from './CollapsibleHeader';
import { CollapsibleBody } from './CollapsibleBody';

type CollapsibleListProps = {
  className?: string;
};

type CollapsibleList = FC<CollapsibleListProps> & {
  Item: typeof CollapsibleItem;
  Header: typeof CollapsibleHeader;
  Body: typeof CollapsibleBody;
};

export const CollapsibleList: CollapsibleList = ({ children, className }) => (
  <div className={classNames('accordion', className)}>{children}</div>
);

CollapsibleList.Item = CollapsibleItem;
CollapsibleList.Header = CollapsibleHeader;
CollapsibleList.Body = CollapsibleBody;
