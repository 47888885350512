import { ComponentType, useMemo } from 'react';
import { EomComponentProps } from './EomComponentProps';
import { PortraitBannerIcon } from '../../../assets/icons';
import { LessonTaskModel } from '../../../models';
import { EomTestingPlayer, TestingPlayerOptions } from './EomTestingPlayer';
import { LessonTaskPlaybackMethodCode } from '../../../constants';
import { EomMediaPlayer } from './EomMediaPlayer';
import { EomWebComponent } from './EomWebComponent';
import { EomText } from './EomText';
import { EomIFrame } from './EomIFrame';

export const EomComponent = (props: EomComponentProps) => {
  const { task, testingPlayerOptions } = props;

  const [InnerComponent, innerProps] = useMemo(
    () => getEomInnerComponent(task, testingPlayerOptions),
    [task, testingPlayerOptions]
  );

  if (!InnerComponent) {
    return (
      <div className="d-flex justify-content-center m-4 p-3 border border-danger rounded text-danger">
        Не удалось отобразить ЭОМ.
      </div>
    );
  }

  return (
    <>
      <InnerComponent {...props} {...innerProps} />
      <div className="lesson-task__portrait-banner">
        <PortraitBannerIcon />
        <div className="m-3 fs-4 text-center">Пожалуйста, поверните экран в&nbsp;горизонтальное положение.</div>
      </div>
    </>
  );
};

const getEomInnerComponent = (
  { id, name, config, eomType, fileList, number }: LessonTaskModel,
  testingPlayerOptions?: TestingPlayerOptions
): [ComponentType<EomComponentProps> | undefined] | [ComponentType<EomComponentProps>, Partial<EomComponentProps>] => {
  if (fileList.length === 0) {
    console.warn('[EomComponent]', `EOM#${id} '${name}' has no sources.`);

    return [undefined];
  }

  const playbackMethod = eomType.taskPlaybackMethod?.code;

  if (!config || !config.entryPoint) {
    if (
      !!eomType.code &&
      [
        '05' /* Тест в формате ГИА */,
        '06' /* Самостоятельная работа */,
        '12' /* Практическая работа */,
        '16' /* Контрольная работа */,
        '20' /* Диагностическая работа */,
        '38' /* Итоговая контрольная работа */
      ].includes(eomType.code)
    ) {
      if (!testingPlayerOptions) {
        console.warn('[EomComponent]', `EOM#${id} '${name}' (TestingPlayer): TestingPlayer is disabled.`);
        return [undefined];
      }

      const specRelativePath = `${number}/specification.xml`;
      const specPath = fileList.find((src) => src.endsWith(specRelativePath));

      if (!specPath) {
        console.warn(
          '[EomComponent]',
          `EOM#${id} '${name}' (TestingPlayer): EOM specification (specification.xml) not found.`
        );
        return [undefined];
      }

      return [EomTestingPlayer as ComponentType<EomComponentProps>, { testingPlayerOptions: testingPlayerOptions }];
    }

    if (
      [LessonTaskPlaybackMethodCode.Audio, LessonTaskPlaybackMethodCode.Video].includes(
        playbackMethod as LessonTaskPlaybackMethodCode
      )
    ) {
      return [EomMediaPlayer];
    }
  }

  if (!config) {
    console.debug('[EomComponent]', 'EOM configuration (eom.json) not found.');
    return [undefined];
  }

  if (!config.entryPoint) {
    console.debug('[EomComponent]', 'EOM configuration (eom.json) does not contain entryPoint property.');
    return [undefined];
  }

  const entryRelativePath = `${number}/${config.entryPoint}`;
  const entryPath = fileList.find((src) => src.endsWith(entryRelativePath));

  if (!entryPath) {
    console.debug('[EomComponent]', 'EOM configuration (eom.json) entryPoint property not found in sources.');
    return [undefined];
  }

  return [
    entryPath.endsWith('.js')
      ? EomWebComponent
      : playbackMethod === LessonTaskPlaybackMethodCode.Text
      ? EomText
      : EomIFrame,
    { src: entryPath }
  ];
};
