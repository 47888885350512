import { BaseApi, Fetcher } from '../core';

export interface ITestingSystemApi {
  getToken(taskNumber: string): Promise<string>;
}

export class TestingSystemApi extends BaseApi implements ITestingSystemApi {
  constructor(fetcher: Fetcher) {
    super(fetcher, '/api/testing-system');
  }

  getToken = async (taskNumber: string) => {
    const { token } = await this.get<{ token: string }>('/token', {
      taskNumber
    });

    return token;
  };
}
