// Subjects LG
import { SubjectIcon } from '../types';

import AlgebraIcon from './AlgebraIcon';
import BiologyIcon from './BiologyIcon';
import ChemistryIcon from './ChemistryIcon';
import ComputerScienceIcon from './ComputerScienceIcon';
import EnglishIcon from './EnglishIcon';
import GeneralHistoryIcon from './GeneralHistoryIcon';
import GeometryIcon from './GeometryIcon';
import MathsIcon from './MathsIcon';
import MusicIcon from './MusicIcon';
import PhysicsIcon from './PhysicsIcon';
import ProbabilityAndStatisticsIcon from './ProbabilityAndStatisticsIcon';
import RussiaHistoryIcon from './RussiaHistoryIcon';
import RussianLanguageIcon from './RussianLanguageIcon';
import ClassHourIcon from './ClassHourIcon';
import RussianSpiritualAndMoralCultureIcon from './RussianSpiritualAndMoralCultureIcon';
import RussianReligiousCultureAndSecularEthicsIcon from './RussianReligiousCultureAndSecularEthicsIcon';
import { LessonSubjectCode } from '../../../../constants';

export const SubjectIconsLg = {
  [LessonSubjectCode.Algebra]: AlgebraIcon,
  [LessonSubjectCode.Biology]: BiologyIcon,
  [LessonSubjectCode.Chemistry]: ChemistryIcon,
  [LessonSubjectCode.ComputerScience]: ComputerScienceIcon,
  [LessonSubjectCode.English]: EnglishIcon,
  [LessonSubjectCode.GeneralHistory]: GeneralHistoryIcon,
  [LessonSubjectCode.Geometry]: GeometryIcon,
  [LessonSubjectCode.Maths]: MathsIcon,
  [LessonSubjectCode.Music]: MusicIcon,
  [LessonSubjectCode.Physics]: PhysicsIcon,
  [LessonSubjectCode.ProbabilityAndStatistics]: ProbabilityAndStatisticsIcon,
  [LessonSubjectCode.RussiaHistory]: RussiaHistoryIcon,
  [LessonSubjectCode.RussianLanguage]: RussianLanguageIcon,
  [LessonSubjectCode.ClassHour]: ClassHourIcon,
  [LessonSubjectCode.RussianSpiritualAndMoralCulture]: RussianSpiritualAndMoralCultureIcon,
  [LessonSubjectCode.RussianReligiousCultureAndSecularEthics]: RussianReligiousCultureAndSecularEthicsIcon
} as Record<LessonSubjectCode, SubjectIcon>;
