import { Icon } from '../Icon';

export const TypeSimIcon: Icon = (props) => (
  <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 7.90722C0 7.18639 0.387893 6.52133 1.01531 6.16643L8.93197 1.68831C10.2652 0.934146 11.9167 1.89733 11.9167 3.42911V9.42611C11.9167 10.1469 11.5288 10.812 10.9014 11.1669L2.98469 15.645C1.65144 16.3992 0 15.436 0 13.9042V7.90722Z"
      fill="#FADEDC"
    />
    <path
      d="M12.0287 13.5396C12.6328 13.204 13.3672 13.204 13.9713 13.5396L22.4947 18.2749C23.937 19.0761 23.8434 21.1807 22.3357 21.8508L13.8123 25.639C13.2951 25.8688 12.7049 25.8688 12.1877 25.639L3.66427 21.8508C2.15657 21.1807 2.06299 19.0761 3.50527 18.2749L12.0287 13.5396Z"
      fill="#FADEDC"
    />
    <path
      d="M26 7.90722C26 7.18639 25.6121 6.52133 24.9847 6.16643L17.068 1.68831C15.7348 0.934146 14.0833 1.89733 14.0833 3.42911V9.42611C14.0833 10.1469 14.4712 10.812 15.0986 11.1669L23.0153 15.645C24.3486 16.3992 26 15.436 26 13.9042V7.90722Z"
      fill="#FADEDC"
    />
    <circle cx="13" cy="13" r="6" fill="#B43C43" />
  </svg>
);
