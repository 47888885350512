import { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useLesson, useS3Api } from '../../providers';
import { LessonModuleModel } from '../../models';
import LessonText from '../LessonText';

export const LessonModuleRecommendations = () => {
  const { activeModule, role } = useLesson();
  const { s3Api } = useS3Api();
  const [html, setHtml] = useState<string>();
  const [loading, setLoading] = useState(false);

  const url = useMemo(() => getRecommendationsUrl(activeModule, role), [activeModule, role]);
  const imageBaseUrl = useMemo(() => url?.split('/').slice(0, -1).join('/'), [url]);

  useEffect(() => {
    if (!url) {
      setHtml(undefined);
      return;
    }

    setLoading(true);

    s3Api
      .getHtml(url)
      .then((html) => setHtml(html))
      .catch(() => setHtml(undefined))
      .finally(() => setLoading(false));
  }, [s3Api, url]);

  if (loading) {
    return <Spinner animation="grow" variant="light" className="d-block my-4" />;
  }

  if (!html) {
    return null;
  }

  return (
    <LessonText
      imageBaseUrl={imageBaseUrl}
      className="lesson-recommendations--module lesson-recommendations__content my-4 py-3 px-4"
    >
      {html}
    </LessonText>
  );
};

const getRecommendationsUrl = (module: LessonModuleModel | undefined, role: 'teacher' | 'student') => {
  if (!module || !module.recommendations) {
    return undefined;
  }

  const { number, recommendations } = module;
  const relativePath = `/Recommendations/${getRecommendationsFilename(role, number)}`;

  return Object.values(recommendations).find((src) => src.endsWith(relativePath));
};

const getRecommendationsFilename = (role: 'teacher' | 'student', number: string) => {
  switch (role) {
    case 'teacher':
      return `index_t_${number}.html`;
    case 'student':
      return `index_s_${number}.html`;
  }
};
