import { StringifiableRecord } from '../models';

export const buildUrl = (path: string, object?: StringifiableRecord) => `${path}${buildQuery(object)}`;

const buildQuery = (object?: StringifiableRecord) => {
  if (!object) {
    return '';
  }

  const query = new URLSearchParams(object as any);

  return query ? `?${query}` : '';
};
