import { useCallback } from 'react';
import { useLesson } from '../../providers';
import { ObjectType, Verb } from '../../constants';
import { LessonHeader } from '../LessonHeader';
import { LessonNextButton } from './LessonNextButton';
import { getLessonModuleName } from '../../utils/LessonUtils';
import { LessonTaskRecommendations } from './LessonTaskRecommendations';
import { LessonTaskContent } from './LessonTaskContent';
import { useFixedTopHeader } from '../../hooks';

type LessonTaskProps = {
  onBackClick?: () => void;
  hideNextButton?: boolean;
};

export const LessonTask = ({ onBackClick, hideNextButton }: LessonTaskProps) => {
  const { role, activeTask, activeModule, onTaskSelect, onXApiMessage } = useLesson();
  const { headerRef, contentRef } = useFixedTopHeader();

  const handleBackClick = useCallback(() => {
    if (activeTask) {
      onXApiMessage(ObjectType.Task, activeTask.id, Verb.Terminated);
    }

    if (!!onBackClick) {
      return onBackClick();
    }

    onTaskSelect();
  }, [activeTask, onBackClick, onTaskSelect, onXApiMessage]);

  return (
    <div className="lesson-task d-flex flex-column flex-grow-1">
      <LessonHeader
        ref={headerRef}
        title={!!onBackClick ? 'Вернуться назад' : 'На страницу модуля'}
        nextButton={!hideNextButton && <LessonNextButton />}
        actions={
          activeTask && (
            <>
              {!!activeModule?.moduleType && (
                <span className="lesson__module-name">
                  Модуль: {getLessonModuleName(activeModule.moduleType, role)}
                </span>
              )}
              <LessonTaskRecommendations task={activeTask} />
              <div id="lesson-task-feedback-container" />
            </>
          )
        }
        onBackClick={handleBackClick}
      />
      <LessonTaskContent ref={contentRef} />
    </div>
  );
};
