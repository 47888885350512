import { ObjectType, Verb } from '../../constants';
import { BaseApi, Fetcher } from '../core';

export type XApiMessage = {
  verb: Verb;
  object: {
    type: ObjectType;
    id: number;
    info?: unknown;
  };
  result?: XApiResult;
  preview?: boolean;
  role?: 'teacher' | 'student';
};

export type XApiExtensions = { [key: string]: string };

export type XApiResultScore = {
  scaled?: number;
  raw?: number;
  min?: number;
  max?: number;
};

export type XApiResult = {
  score?: XApiResultScore;
  success?: boolean;
  completion?: boolean;
  response?: string;
  duration?: number;
  extensions?: XApiExtensions;
};

export interface IXApi {
  send(message: XApiMessage): Promise<void>;
}

export class XApi extends BaseApi implements IXApi {
  constructor(fetcher: Fetcher) {
    super(fetcher, '/xapi/statements');
  }

  public send = (message: XApiMessage): Promise<void> => {
    return this.post('', message);
  };
}
