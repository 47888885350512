import { Icon } from '../Icon';

export const TypeGameIcon: Icon = (props) => (
  <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M27.5246 25.9496L24.6828 28.8596C22.4687 31.1454 18.6399 31.1834 16.1507 28.8418C13.619 26.441 13.4208 22.6073 15.6858 20.3299C14.8874 19.7802 14.1483 19.1883 13.4771 18.5037C12.8059 17.819 12.1857 17.1428 11.6333 16.3738C9.36826 18.6511 5.51388 18.5287 3.09249 16.0942C0.722055 13.6682 0.727672 9.86852 2.94174 7.5827L5.78356 4.67272C9.05806 1.36636 14.5084 1.33859 18.2632 4.56824C21.5169 7.34983 24.5754 10.3592 27.3964 13.5371C30.7101 17.2138 30.7481 22.6348 27.5246 25.9496Z"
      fill="#FADEDC"
    />
    <path
      d="M13.5392 11.8126C13.9343 12.197 13.9437 12.8286 13.5604 13.2247L13.3357 13.4568C12.9537 13.8514 12.325 13.8642 11.9274 13.4854L11.2528 12.8429C10.8574 12.4663 10.2331 12.4764 9.85017 12.8657L9.22862 13.4975C8.845 13.8875 8.21921 13.8969 7.8241 13.5185L7.59854 13.3025C7.19455 12.9157 7.18642 12.2727 7.58051 11.8757L8.21793 11.2337C8.60979 10.839 8.6043 10.2004 8.2057 9.81249L7.60252 9.22548C7.20367 8.83734 7.19846 8.19824 7.59092 7.80364L7.77971 7.61383C8.16618 7.22526 8.7934 7.22015 9.18616 7.60236L9.81598 8.21529C10.2103 8.59901 10.8405 8.59213 11.2263 8.1999L11.8404 7.57561C12.224 7.18562 12.8498 7.17627 13.2449 7.55462L13.4739 7.77385C13.8766 8.15946 13.8861 8.79991 13.4952 9.19738L12.9074 9.79484C12.5187 10.1901 12.5256 10.8261 12.9229 11.2128L13.5392 11.8126Z"
      fill="#F55757"
    />
    <path
      d="M24.4999 20.999C25.3283 20.999 25.9999 20.3275 25.9999 19.499C25.9999 18.6706 25.3283 17.999 24.4999 17.999C23.6715 17.999 22.9999 18.6706 22.9999 19.499C22.9999 20.3275 23.6715 20.999 24.4999 20.999Z"
      fill="#F55757"
    />
    <path
      d="M20.4999 24.999C21.3283 24.999 21.9999 24.3274 21.9999 23.499C21.9999 22.6706 21.3283 21.999 20.4999 21.999C19.6715 21.999 18.9999 22.6706 18.9999 23.499C18.9999 24.3274 19.6715 24.999 20.4999 24.999Z"
      fill="#F55757"
    />
    <path
      d="M24.5265 25.0129C25.3549 25.0055 26.0205 24.328 26.0131 23.4996C26.0057 22.6712 25.3282 22.0056 24.4998 22.013C23.6714 22.0204 23.0059 22.6979 23.0132 23.5263C23.0206 24.3547 23.6981 25.0203 24.5265 25.0129Z"
      fill="#F55757"
    />
    <path
      d="M20.4999 20.999C21.3283 20.999 21.9999 20.3275 21.9999 19.499C21.9999 18.6706 21.3283 17.999 20.4999 17.999C19.6714 17.999 18.9999 18.6706 18.9999 19.499C18.9999 20.3275 19.6714 20.999 20.4999 20.999Z"
      fill="#F55757"
    />
  </svg>
);
