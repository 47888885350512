import { BaseApi, Fetcher } from '../core';

export interface IS3Api {
  getHtml(path: string): Promise<string>;
}

export class S3Api extends BaseApi implements IS3Api {
  constructor(fetcher: Fetcher, baseUrl?: string) {
    super(fetcher, baseUrl);
  }

  public getHtml = async (url: string): Promise<string> => {
    const response = await this.fetcher.call(null, url, { method: 'get' });

    if (response.ok) {
      return response.text();
    }

    throw new Error(response.statusText);
  };
}
