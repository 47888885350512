import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Music];

const MusicIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="-30 -30 220 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1115_303714)">
      <path
        d="M148.985 18.4171C148.985 6.92988 139.161 -1.75259 127.859 0.302291L52.3232 16.1103C43.5686 17.702 36.2129 27.6337 36.2129 36.5318V144.993H52.3277V57.2452L132.875 40.2757V120.827H148.985V18.4171Z"
        fill={secondaryColor}
      />
      <circle cx="32.193" cy="140.964" r="28.193" fill={primaryColor} />
      <circle cx="120.808" cy="116.798" r="28.193" fill={primaryColor} />
    </g>
    <defs>
      <filter
        id="filter0_d_1115_303714"
        x="0"
        y="0"
        width="153.002"
        height="177.157"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1115_303714" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1115_303714" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default MusicIcon;
