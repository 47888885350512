import { useEffect } from 'react';
import { EomComponentProps } from './EomComponentProps';
import { useLesson } from '../../../providers';
import { Verb } from '../../../constants';
import { EomIFrame } from './EomIFrame';

export const EomText = (props: EomComponentProps) => {
  const { onMessage } = props;
  const { activeTask } = useLesson();

  useEffect(() => {
    if (!activeTask) return;

    onMessage?.({ eomId: activeTask.id, message: { verb: Verb.Started } });

    return () => {
      onMessage?.({ eomId: activeTask.id, message: { verb: Verb.Completed } });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <EomIFrame {...props} />;
};
