import { createContext, FC, useContext, useMemo } from 'react';
import { ITestingSystemApi, TestingSystemApi } from '../api/testing-system';
import { Fetcher } from '../api/core';

type TestingSystemApiContext = {
  testingSystemApi: ITestingSystemApi;
};

const TestingSystemApiContext = createContext<TestingSystemApiContext | undefined>(undefined);

type TestingSystemApiProviderProps = {
  fetcher: Fetcher;
};

export const TestingSystemApiProvider: FC<TestingSystemApiProviderProps> = ({ fetcher, children }) => {
  const contextValue = useMemo(() => ({ testingSystemApi: new TestingSystemApi(fetcher) }), [fetcher]);

  return <TestingSystemApiContext.Provider value={contextValue}>{children}</TestingSystemApiContext.Provider>;
};

export const useTestingSystemApi = () => {
  const context = useContext(TestingSystemApiContext);

  if (!context) {
    throw new Error('useTestingSystemApi must be used within a TestingSystemApiProvider.');
  }

  return context;
};
