import { LessonTaskPlaybackMethodCode, ObjectType } from '../constants';

type TaskPlaybackMethodLabelProps = {
  code?: LessonTaskPlaybackMethodCode | ObjectType.Lesson;
  plural?: boolean;
  className?: string;
};

export const TaskPlaybackMethodLabel = ({ code, plural, className }: TaskPlaybackMethodLabelProps) => {
  return <span className={className}>{getTaskPlaybackMethodLabel(code, plural)}</span>;
};

export const getTaskPlaybackMethodLabel = (
  taskPlaybackMethod?: LessonTaskPlaybackMethodCode | ObjectType.Lesson,
  plural?: boolean
) => {
  switch (taskPlaybackMethod) {
    case ObjectType.Lesson:
      return 'Урок';
    case LessonTaskPlaybackMethodCode.Video:
      return 'Видео';
    case LessonTaskPlaybackMethodCode.Audio:
      return 'Аудио';
    case LessonTaskPlaybackMethodCode.Test:
      return plural ? 'Тесты' : 'Тест';
    case LessonTaskPlaybackMethodCode.Simulator:
      return plural ? 'Симуляторы' : 'Симулятор';
    case LessonTaskPlaybackMethodCode.Interaction:
      return 'Интерактив';
    case LessonTaskPlaybackMethodCode.Text:
      return plural ? 'Тексты' : 'Текст';
    case LessonTaskPlaybackMethodCode.MiniGames:
      return plural ? 'Игры' : 'Игра';
    case LessonTaskPlaybackMethodCode.Image:
      return 'Галерея';
    default:
      return '';
  }
};
