import { DependencyList, useRef } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

export const useFixedTopHeader = (deps?: DependencyList) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  useIsomorphicLayoutEffect(() => {
    const handleResize = () => {
      const headerElement = headerRef.current;
      const contentElement = contentRef.current;
      const footerElement = footerRef.current;

      if (!contentElement) return;

      if (!!headerElement) {
        headerElement.style.top = `${contentElement.offsetTop}px`;
      }

      contentElement.style.paddingTop = `${headerElement?.offsetHeight || 0}px`;
      contentElement.style.paddingBottom = `${footerElement?.offsetHeight || 0}px`;
    };

    const observer = new ResizeObserver(handleResize);

    observer.observe(document.body);
    handleResize();

    return () => {
      observer.disconnect();

      const headerElement = headerRef.current;
      const contentElement = contentRef.current;

      if (!headerElement || !contentElement) return;

      headerElement.style.top = '0px';
      contentElement.style.paddingTop = '0px';
      contentElement.style.paddingBottom = '0px';
    };
  }, [deps]);

  return { headerRef, contentRef, footerRef };
};
