import {
  LessonModel,
  LessonModuleModel,
  LessonResultModel,
  LessonTaskModel,
  LessonTaskModelExtended,
  LessonUnitModel,
  ModuleDictionaryRecord,
  UnitDictionaryRecord
} from '../models';
import { ObjectType, Verb } from '../constants';
import { XApiResult } from '../api/xapi';

export type XApiMessageHandler = (
  type: ObjectType,
  id: number,
  verb: Verb,
  info?: unknown,
  result?: XApiResult
) => void;

export const getLessonModules = (lesson?: LessonModel) => {
  return lesson?.units.map((u) => u.modules).flat() || [];
};

export const getLessonTasks = (lesson?: LessonModel) => {
  const modules = getLessonModules(lesson);

  return modules.map((m) => m.tasks).flat();
};

export const getLessonResultProgress = (lesson: LessonModel | undefined, result: LessonResultModel) => {
  const tasks = getLessonTasks(lesson);
  const solvedTaskCount = tasks.reduce((solved, task) => {
    return solved + (result[task.uid] ? 1 : 0);
  }, 0);

  return (solvedTaskCount * 100) / tasks.length;
};

export const getLessonUnitByModule = (lesson?: LessonModel, module?: LessonModuleModel) => {
  if (!lesson || !module) {
    return undefined;
  }

  return lesson?.units.find((unit) => unit.modules.includes(module));
};

export const getLessonModuleByTask = (lesson?: LessonModel, task?: LessonTaskModel) => {
  if (!lesson || !task) {
    return undefined;
  }

  return getLessonModules(lesson).find((module) => module.tasks.includes(task));
};

export const getExtendedTaskList = (tasks: LessonTaskModel[], result: LessonResultModel) => {
  const extendedTasks: LessonTaskModelExtended[] = [];

  let prevTaskSolved = true;

  for (const task of tasks) {
    const solved = isLessonTaskSolved(task, result);

    extendedTasks.push({
      task,
      solved,
      disabled: !prevTaskSolved
    });

    prevTaskSolved = prevTaskSolved && solved;
  }

  return extendedTasks;
};

export const getLessonModuleName = (type: ModuleDictionaryRecord, role?: 'teacher' | 'student') => {
  switch (role) {
    case 'teacher':
      return type.nameForTeacher || type.name;
    case 'student':
      return type.nameForStudent || type.name;
    default:
      return type.name;
  }
};

export const isLessonTaskSolved = (task: LessonTaskModel | undefined, result: LessonResultModel) =>
  !!task && (result[task.uid] || false);

export const isLessonModuleSolved = (module: LessonModuleModel, result: LessonResultModel) =>
  module.tasks.every((task) => isLessonTaskSolved(task, result));

export const isLessonUnitSolved = (unit: LessonUnitModel, result: LessonResultModel) =>
  unit.modules.every((module) => isLessonModuleSolved(module, result));

export const isLessonSolved = (lesson: LessonModel | undefined, result: LessonResultModel) =>
  !!lesson && lesson.units.every((unit) => isLessonUnitSolved(unit, result));

export const isLessonSaveAllowed = (lesson?: LessonModel) => {
  if (!lesson || !lesson.units || lesson.units.length === 0) return false;

  for (const unit of lesson.units) {
    if (!unit.modules || unit.modules.length === 0) return false;

    for (const module of unit.modules) {
      if (!module.tasks || module.tasks.length === 0) return false;
    }
  }

  return true;
};

export const buildLessonUnitList = (lessonUnits?: LessonUnitModel[], dictionaryUnits?: UnitDictionaryRecord[]) => {
  if (!dictionaryUnits) {
    return lessonUnits?.map((lessonUnit) => {
      const { uid, name } = lessonUnit.unitType;

      return {
        uid,
        name,
        modules: undefined,
        unit: lessonUnit
      };
    });
  }

  return dictionaryUnits.map(({ uid, name, modules }) => {
    const lessonUnit = lessonUnits?.find(({ unitType }) => unitType.uid === uid);

    return {
      uid,
      name,
      modules,
      unit: lessonUnit
    };
  });
};

export const buildLessonModuleList = (
  lessonModules?: LessonModuleModel[],
  dictionaryModules?: ModuleDictionaryRecord[],
  role?: 'teacher' | 'student',
  removedModules?: LessonModuleModel[]
) => {
  if (!dictionaryModules) {
    return lessonModules?.map((lessonModule) => {
      const { uid } = lessonModule.moduleType;
      const name = getLessonModuleName(lessonModule.moduleType, role);

      return {
        uid,
        name,
        module: lessonModule
      };
    });
  }

  return dictionaryModules.map((dictionaryModule) => {
    const { uid } = dictionaryModule;
    const name = getLessonModuleName(dictionaryModule, role);

    const lessonModule = lessonModules?.find(({ moduleType }) => moduleType.uid === uid);

    const removedModule = removedModules?.find(({ moduleType }) => moduleType.uid === uid);

    return {
      uid,
      name,
      module: lessonModule || removedModule,
      removed: !!removedModule
    };
  });
};
