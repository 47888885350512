import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.RussianSpiritualAndMoralCulture];

const RussianSpiritualAndMoralCultureIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 -6 70 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.408 5.19312L19.213 12.9762L16.462 13.8801L14.7715 12.9762L16.0524 7.45299L16.5765 5.19312C16.7097 5.22259 16.8491 5.23856 16.9923 5.23856C17.1354 5.23856 17.2748 5.22259 17.408 5.19312Z"
      fill={tertiaryColor}
    />
    <path
      d="M20.2256 12.6379L20.5878 15.238L20.2903 15.2786L17.1459 16.6738L13.6928 15.2786L13.3965 15.238L13.7575 12.6379H14.7708H19.2123H20.2256Z"
      fill={secondaryColor}
    />
    <path
      d="M20.5902 15.238L20.9636 17.9192L16.837 19.1916L13.0254 17.9192L13.3254 15.7649L13.3976 15.238H13.3988L13.6951 15.2786H20.2927L20.5902 15.238Z"
      fill={tertiaryColor}
    />
    <path
      d="M16.9941 2.82861C17.7853 2.82861 18.4256 3.46039 18.4256 4.24103C18.4256 4.91236 17.9503 5.47549 17.3129 5.61771V5.6187C17.2108 5.6413 17.1038 5.65358 16.9941 5.65358C16.8843 5.65358 16.7775 5.6413 16.6753 5.6187V5.61771C16.0386 5.47549 15.5625 4.91249 15.5625 4.24103C15.5625 3.46039 16.2038 2.82861 16.9941 2.82861Z"
      fill={tertiaryColor}
    />
    <path
      d="M21.8679 22.3809L24.7856 43.0474H11.6873L9.375 28.7667C9.85207 28.2257 12.201 22.3809 12.201 22.3809V17.2349H13.0321H21.0381H21.8679V22.3809Z"
      fill={secondaryColor}
    />
    <path
      d="M40.5285 10.4736L38.7454 6.40997V4.35603C38.7454 4.09833 38.5129 3.8894 38.2261 3.8894C37.9393 3.8894 37.7068 4.09833 37.7068 4.35603V6.40897L35.9233 10.4736H35.1797V15.2045H38.226H41.2722V10.4736H40.5285Z"
      fill={primaryColor}
    />
    <path
      d="M38.2383 15.424C37.7226 15.424 37.3047 15.0116 37.3047 14.5029V13.0291C37.3047 12.5203 37.7226 12.1079 38.2383 12.1079C38.754 12.1079 39.1719 12.5203 39.1719 13.0291V14.5029C39.1719 15.0117 38.754 15.424 38.2383 15.424Z"
      fill={quaternaryColor}
    />
    <path d="M32.3125 27.5806V38.191V43.1388H44.1392V38.191V27.5806H32.3125Z" fill={primaryColor} />
    <path d="M47.0068 28.2878H45.2148V31.8238H47.0068V28.2878Z" fill={primaryColor} />
    <path
      d="M23.8598 27.8425H26.7945H29.4479V27.9706V43.0472H24.4671H21.2051V27.9706V27.8425H23.8598Z"
      fill={primaryColor}
    />
    <path d="M13.2129 27.7947V42.9801H4.74805V27.7947V27.6658H13.2129V27.7947Z" fill={primaryColor} />
    <path
      d="M53.0983 43.1387H45.089H44.1387V39.174V36.3229V30.0557H46.103H48.0389H49.9747H51.9106H53.0983V43.1387Z"
      fill={primaryColor}
    />
    <path
      d="M42.7158 22.7803V14.4976H41.6135H38.2265H34.8396H33.7373V22.7803H32.3125V27.5806H32.5082H35.1372H38.1687H41.2001H43.9449H44.1392V22.7803H42.7158Z"
      fill={primaryColor}
    />
    <path
      d="M29.7008 27.8428C29.8067 27.8428 29.9082 27.7961 29.9684 27.7131C30.3191 27.2308 30.5192 26.6855 30.5192 26.1075C30.5192 22.2179 27.2579 22.0983 25.5018 20.0637C24.7065 20.9852 23.6014 21.5142 22.6306 22.1796C21.4603 22.9803 20.4844 23.9808 20.4844 26.1075C20.4844 26.6854 20.6844 27.2308 21.0352 27.7131C21.0955 27.7961 21.197 27.8428 21.3027 27.8428H29.7008Z"
      fill={tertiaryColor}
    />
    <path
      d="M13.3848 27.9342C13.7968 27.4208 14.0348 26.8293 14.0348 26.1988C14.0348 25.4377 13.9094 24.8201 13.6949 24.3055C13.2947 23.3397 12.5797 22.7329 11.787 22.2015C10.8425 21.5684 9.78506 21.0454 9.0174 20.155C8.27608 21.0131 7.26758 21.5313 6.3494 22.1345C5.4864 22.7006 4.70369 23.3433 4.29603 24.4144C4.10787 24.9063 4 25.4903 4 26.1988C4 26.785 4.20571 27.3376 4.56571 27.8248C4.61714 27.8944 4.70281 27.9342 4.79212 27.9342H13.3848Z"
      fill={tertiaryColor}
    />
    <path
      d="M38.226 15.5583C39.9096 15.5583 41.2722 16.9028 41.2722 18.5639C41.2722 20.223 39.9096 21.5695 38.226 21.5695C36.5444 21.5695 35.1797 20.223 35.1797 18.5639C35.1797 16.9028 36.5444 15.5583 38.226 15.5583Z"
      fill={secondaryColor}
    />
    <path
      d="M38.227 17.3262C38.9203 17.3262 39.4814 17.8798 39.4814 18.5638C39.4814 19.2469 38.9203 19.8013 38.227 19.8013C37.5346 19.8013 36.9727 19.2469 36.9727 18.5638C36.9727 17.8798 37.5346 17.3262 38.227 17.3262Z"
      fill={tertiaryColor}
    />
    <path d="M39.1206 37.6782V43.139H25.502V37.4814H30.7031H33.9941H39.1206V37.6782Z" fill={primaryColor} />
    <path
      d="M27.2948 26.1075C27.2948 26.737 27.211 27.3294 27.064 27.8428H23.943C23.796 27.3294 23.7109 26.737 23.7109 26.1075C23.7109 22.2179 24.8763 22.0983 25.5029 20.0637C26.1307 22.0983 27.2948 22.2179 27.2948 26.1075Z"
      fill={secondaryColor}
    />
    <path
      d="M13.286 27.8427H13.388C13.7974 27.3107 14.0338 26.6975 14.0338 26.0441C14.0338 25.2551 13.9092 24.6151 13.6961 24.0817L13.5976 24.1214C8.48772 23.8733 6.74287 21.8886 6.74287 21.8886L6.39764 21.8315C5.54018 22.4183 4.76248 23.0844 4.35742 24.1946L4.4995 24.2454C4.4995 24.2454 8.23846 27.2225 13.286 27.8427Z"
      fill={secondaryColor}
    />
    <path
      d="M8.97854 34.6782C8.46293 34.6782 8.04492 34.2658 8.04492 33.7571V32.0376C8.04492 31.5289 8.46293 31.1165 8.97854 31.1165C9.49415 31.1165 9.91216 31.5289 9.91216 32.0376V33.7571C9.91216 34.2659 9.49415 34.6782 8.97854 34.6782Z"
      fill={quaternaryColor}
    />
    <path
      d="M17.1172 24.686C16.6016 24.686 16.1836 24.2736 16.1836 23.7649V22.0454C16.1836 21.5367 16.6016 21.1243 17.1172 21.1243C17.6328 21.1243 18.0508 21.5367 18.0508 22.0454V23.7649C18.0508 24.2737 17.6328 24.686 17.1172 24.686Z"
      fill={quaternaryColor}
    />
    <path
      d="M17.1172 30.3694C16.6016 30.3694 16.1836 29.9569 16.1836 29.4482V27.7288C16.1836 27.22 16.6016 26.8076 17.1172 26.8076C17.6328 26.8076 18.0508 27.22 18.0508 27.7288V29.4482C18.0508 29.9571 17.6328 30.3694 17.1172 30.3694Z"
      fill={quaternaryColor}
    />
    <path
      d="M25.3653 34.6782C24.8497 34.6782 24.4316 34.2658 24.4316 33.7571V32.0376C24.4316 31.5289 24.8497 31.1165 25.3653 31.1165C25.8809 31.1165 26.2989 31.5289 26.2989 32.0376V33.7571C26.2989 34.2659 25.8809 34.6782 25.3653 34.6782Z"
      fill={quaternaryColor}
    />
    <path
      d="M35.0391 34.9192C34.5235 34.9192 34.1055 34.5068 34.1055 33.998V32.2786C34.1055 31.7698 34.5235 31.3574 35.0391 31.3574C35.5547 31.3574 35.9727 31.7698 35.9727 32.2786V33.998C35.9727 34.5069 35.5547 34.9192 35.0391 34.9192Z"
      fill={secondaryColor}
    />
    <path
      d="M38.2637 34.9192C37.7481 34.9192 37.3301 34.5068 37.3301 33.998V32.2786C37.3301 31.7698 37.7481 31.3574 38.2637 31.3574C38.7793 31.3574 39.1973 31.7698 39.1973 32.2786V33.998C39.1973 34.5069 38.7793 34.9192 38.2637 34.9192Z"
      fill={secondaryColor}
    />
    <path
      d="M41.4903 34.9192C40.9747 34.9192 40.5566 34.5068 40.5566 33.998V32.2786C40.5566 31.7698 40.9747 31.3574 41.4903 31.3574C42.0059 31.3574 42.4239 31.7698 42.4239 32.2786V33.998C42.4239 34.5069 42.0059 34.9192 41.4903 34.9192Z"
      fill={secondaryColor}
    />
    <path d="M50.2323 28.2878H48.082V31.8238H50.2323V28.2878Z" fill={primaryColor} />
    <path d="M53.0986 28.2878H51.3066V31.8238H53.0986V28.2878Z" fill={primaryColor} />
    <path
      d="M42.3701 26.5199L42.3701 24.752H40.5554V26.5199H39.1218L39.1218 24.752H37.3299L37.3299 26.5199H35.8964L35.8964 24.752H34.1044L34.1044 26.5199H32.3125L32.3125 27.9343H33.9631H36.1005H37.0979H39.2353H40.2327H42.3701H44.1392L44.1392 26.5199H42.3701Z"
      fill={secondaryColor}
    />
    <path
      d="M38.2384 0L39.1317 1.78579L41.1291 2.07221L39.6838 3.46227L40.025 5.42505L38.2384 4.49825L36.4518 5.42505L36.793 3.46227L35.3477 2.07221L37.3451 1.78579L38.2384 0Z"
      fill={secondaryColor}
    />
    <path
      d="M50.2746 21.9898C44.6817 26.1096 41.9447 27.7124 40.1916 36.8541C45.1421 37.2626 51.3127 35.373 56.9821 31.2792C62.6515 27.1853 66.6511 21.7482 69.3972 18.1986C64.4705 17.7134 55.9439 17.8959 50.2746 21.9898Z"
      fill={tertiaryColor}
    />
    <path
      d="M37.9179 40.9575C37.6858 40.8853 37.4538 40.8132 37.3428 40.6103C37.0217 40.258 37.0976 39.6922 37.5165 39.4014C37.5165 39.4014 42.986 32.6199 47.3593 29.2632C51.6932 25.9369 61.2368 21.7455 60.7722 22.6356C60.3076 23.5257 53.0584 27.5891 48.7938 30.8303C44.3698 34.1926 38.5688 40.7388 38.5688 40.7388C38.3484 40.9229 38.1718 40.9522 37.9179 40.9575Z"
      fill={secondaryColor}
    />
  </svg>
);

export default RussianSpiritualAndMoralCultureIcon;
