import { createContext, FC, useContext, useMemo } from 'react';
import { IS3Api, S3Api } from '../api/s3';
import { Fetcher } from '../api/core';

type S3ApiContext = {
  s3Api: IS3Api;
};

const S3ApiContext = createContext<S3ApiContext | undefined>(undefined);

type S3ApiProviderProps = {
  fetcher: Fetcher;
};

export const S3ApiProvider: FC<S3ApiProviderProps> = ({ fetcher, children }) => {
  const contextValue = useMemo(() => ({ s3Api: new S3Api(fetcher) }), [fetcher]);

  return <S3ApiContext.Provider value={contextValue}>{children}</S3ApiContext.Provider>;
};

export const useS3Api = () => {
  const context = useContext(S3ApiContext);

  if (!context) {
    throw new Error('useS3Api must be used within a S3ApiProvider.');
  }

  return context;
};
