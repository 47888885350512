import { useEffect, useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { InfoIcon } from '../../assets/icons';
import { LessonTaskModel } from '../../models';
import { useLesson, useS3Api } from '../../providers';
import LessonText from '../LessonText';
import { Modal } from '../modal';

type LessonTaskRecommendationsProps = {
  task: LessonTaskModel;
};

export const LessonTaskRecommendations = ({ task }: LessonTaskRecommendationsProps) => {
  const [showModal, setShowModal] = useState(false);
  const { role } = useLesson();
  const { s3Api } = useS3Api();
  const [html, setHtml] = useState<string>();
  const [loading, setLoading] = useState(false);

  const url = useMemo(() => getRecommendationsUrl(task, role), [task, role]);
  const imageBaseUrl = useMemo(() => url?.split('/').slice(0, -1).join('/'), [url]);

  useEffect(() => {
    if (!url) {
      setHtml(undefined);
      return;
    }

    setLoading(true);

    s3Api
      .getHtml(url)
      .then((html) => setHtml(html))
      .catch(() => setHtml(undefined))
      .finally(() => setLoading(false));
  }, [url]);

  if (loading) {
    return <Spinner animation="grow" variant="light" className="lesson-recommendations__loading-indicator" />;
  }

  if (!html) {
    return null;
  }

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  return (
    <>
      <Button
        variant="link"
        className="lesson-recommendations__info-button"
        onClick={handleModalOpen}
        title="Рекомендации к уроку"
      >
        <InfoIcon />
      </Button>
      <Modal size="xl" centered={true} show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton={true} className="pb-2" />
        <Modal.Body className="px-5 pt-0 pb-5">
          <LessonText imageBaseUrl={imageBaseUrl} className="lesson-recommendations__content">
            {html}
          </LessonText>
        </Modal.Body>
      </Modal>
    </>
  );
};

const getRecommendationsUrl = ({ number, fileList }: LessonTaskModel, role: 'teacher' | 'student') => {
  const relativePath = `${number}/${getRecommendationsFilename(role)}`;

  return fileList.find((src) => src.endsWith(relativePath));
};

const getRecommendationsFilename = (role: 'teacher' | 'student') => {
  switch (role) {
    case 'teacher':
      return 'index_t.html';
    case 'student':
      return 'index_s.html';
  }
};
