import { forwardRef, useCallback, useEffect } from 'react';
import { useLesson } from '../../providers';
import { EomEvent } from './eom/EomEvent';
import { ObjectType, Verb } from '../../constants';
import { clearRequestInterval, requestInterval } from '../../utils/DomUtils';
import { EomComponent } from './eom';

export const LessonTaskContent = forwardRef<HTMLDivElement>(function LessonTaskContent(_, ref) {
  const { activeTask, onTaskComplete, onXApiMessage } = useLesson();

  const handleEomMessage = useCallback(
    (evt: EomEvent) => {
      if (!activeTask) return;

      const { eomId, message } = evt || {};
      const { verb, info } = message || {};
      const { result, ...otherInfo } = info || {};

      onXApiMessage(ObjectType.Task, eomId, message.verb, otherInfo, result);

      if (verb === Verb.Completed) {
        onTaskComplete(activeTask);
      }
    },
    [activeTask, onTaskComplete, onXApiMessage]
  );

  useEffect(() => {
    if (!activeTask) return;

    let pageFocused = true;
    let pageHidden = false;

    const handleDocumentVisibilityChange = () => {
      if (pageHidden) return;

      const hidden = document.visibilityState === 'hidden';

      onXApiMessage(ObjectType.Task, activeTask.id, hidden ? Verb.Inactive : Verb.Active);
    };

    const handleWindowPageHide = () => {
      pageHidden = true;
      onXApiMessage(ObjectType.Task, activeTask.id, Verb.Terminated);
    };

    const handleActiveCheck = () => {
      const documentHasFocus = document.hasFocus();

      if (pageFocused !== documentHasFocus) {
        onXApiMessage(ObjectType.Task, activeTask.id, documentHasFocus ? Verb.Active : Verb.Inactive);
      }

      pageFocused = documentHasFocus;
    };

    document.addEventListener('visibilitychange', handleDocumentVisibilityChange);
    window.addEventListener('pagehide', handleWindowPageHide);

    const timeout = requestInterval(handleActiveCheck, 1000);

    return () => {
      document.removeEventListener('visibilitychange', handleDocumentVisibilityChange);
      window.removeEventListener('pagehide', handleWindowPageHide);

      clearRequestInterval(timeout);
    };
  }, [activeTask, onXApiMessage]);

  if (!activeTask) {
    return null;
  }

  return (
    <section ref={ref} className="lesson-task__content-wrapper d-flex flex-column flex-grow-1">
      <div className="lesson-task__inner-wrapper">
        <EomComponent key={activeTask.uid} task={activeTask} onMessage={handleEomMessage} />
      </div>
    </section>
  );
});
