import classNames from 'classnames';
import { forwardRef, ReactNode } from 'react';
import { Button, Container } from 'react-bootstrap';
import { ArrowLineIcon } from '../assets/icons';

type LessonHeaderProps = {
  title?: string;
  nextButton?: ReactNode;
  actions?: ReactNode;
  className?: string;
  onBackClick?: () => void;
};

export const LessonHeader = forwardRef<HTMLDivElement, LessonHeaderProps>(function LessonHeader(
  { title, nextButton, actions, className, onBackClick },
  ref
) {
  return (
    <header ref={ref} className="lesson__header">
      <Container className={classNames('lesson__header-container py-2 py-xl-0', className)}>
        <Button variant="link-secondary" title={title} onClick={onBackClick} size="lg" className="py-2 px-3">
          <ArrowLineIcon width={18} height={18} className="me-2 pe-1 text-gray" />{' '}
          <span className="d-none d-md-inline-block text-nowrap">{title || 'Вернуться назад'}</span>
        </Button>

        {nextButton}
        <div className="actions">
          {actions}
          <span className="text-gray ms-2">
            β<span className="d-none d-lg-inline">-версия</span>
          </span>
        </div>
      </Container>
    </header>
  );
});
