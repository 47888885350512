import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.English];

const EnglishIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M33.963 11.9539L29.3054 22.4111L40.9348 27.5695L45.5923 17.1123L33.963 11.9539Z" fill={secondaryColor} />
    <path
      d="M45.5262 13.3675L36.8271 9.49018C36.4096 9.31485 35.8947 9.50237 35.7188 9.92074L35.4978 10.4004L45.733 14.9561L45.954 14.4764C46.1357 14.0245 45.9437 13.5429 45.5262 13.3675Z"
      fill={secondaryColor}
    />
    <path
      d="M46.3467 15.1964L34.8592 10.1151C34.5087 9.9513 34.1 10.1226 33.9691 10.4796L33.6914 11.0875C33.5271 11.4388 33.6973 11.8477 34.0537 11.978L45.5412 17.0593C45.8918 17.2231 46.3005 17.0519 46.4314 16.6948L46.7091 16.0869C46.8341 15.7633 46.6973 15.3602 46.3467 15.1964Z"
      fill={tertiaryColor}
    />
    <path
      d="M39.7853 27.0338L36.7353 34.1401C36.7353 34.1401 36.5075 34.5991 35.914 34.7716C35.3204 34.944 34.7178 34.8572 34.7178 34.8572L27.4635 29.7757L30.5066 22.9578L39.7853 27.0338Z"
      fill={tertiaryColor}
    />
    <path d="M45.9152 9.06937L39.7115 6.31738L38.053 10.0415L44.2567 12.7934L45.9152 9.06937Z" fill={tertiaryColor} />
    <path
      d="M43.7762 4.76855L39.7192 6.31252L45.9234 9.06864L44.3551 5.00597C44.2896 4.78782 44.0002 4.66911 43.7762 4.76855Z"
      fill={secondaryColor}
    />
    <path
      d="M36.9164 23.389C39.0426 23.8089 41.0105 22.5236 41.3119 20.5181C41.6133 18.5127 40.1339 16.5465 38.0077 16.1266C35.8815 15.7066 33.9135 16.992 33.6122 18.9974C33.3108 21.0029 34.7902 22.969 36.9164 23.389Z"
      fill={primaryColor}
    />
    <path
      d="M37.1616 20.3615C36.8923 20.241 36.7574 19.9443 36.8667 19.6956L37.4087 18.485C37.518 18.2364 37.822 18.1273 38.0913 18.2478C38.3607 18.3683 38.4956 18.6651 38.3862 18.9137L37.8443 20.1243C37.7349 20.373 37.431 20.482 37.1616 20.3615Z"
      fill={tertiaryColor}
    />
    <path
      d="M39.0863 20.7045C38.9797 20.9463 38.6834 21.0524 38.4209 20.9352L37.1422 20.3561C36.8796 20.2389 36.748 19.9504 36.8546 19.7086C36.9612 19.4668 37.2575 19.3608 37.5201 19.4779L38.7988 20.0571C39.0614 20.1742 39.1929 20.4627 39.0863 20.7045Z"
      fill={tertiaryColor}
    />
    <path
      d="M34.467 26.4644C34.1041 26.2947 33.6882 26.4562 33.5665 26.7745L32.4655 29.2296C32.304 29.5747 32.487 29.9838 32.8208 30.114C33.1838 30.2837 33.5996 30.1222 33.7213 29.8039L34.8224 27.3488C34.9785 27.0368 34.83 26.6341 34.467 26.4644Z"
      fill={primaryColor}
    />
    <path
      d="M36.8006 27.5211C36.4376 27.3513 36.0217 27.5128 35.9 27.8312L34.799 30.2863C34.6375 30.6314 34.8205 31.0404 35.1543 31.1706C35.5173 31.3404 35.9331 31.1789 36.0549 30.8605L37.1559 28.4054C37.3174 28.0603 37.1291 27.6844 36.8006 27.5211Z"
      fill={primaryColor}
    />
    <path
      d="M32.1274 25.44C31.7644 25.2703 31.3487 25.4317 31.2269 25.7501L30.1261 28.205C29.9646 28.5501 30.1476 28.9591 30.4813 29.0893C30.8443 29.259 31.26 29.0976 31.3818 28.7793L32.4826 26.3243C32.6732 26.0187 32.4903 25.6097 32.1274 25.44Z"
      fill={primaryColor}
    />
    <path
      d="M41.1539 8.10461C40.7879 7.9316 40.3729 8.08488 40.2546 8.39753L39.9883 8.98335C39.8304 9.32179 40.0187 9.72853 40.355 9.86209C40.721 10.0351 41.1361 9.88182 41.2544 9.56917L41.5207 8.98335C41.6736 8.67754 41.5199 8.27763 41.1539 8.10461Z"
      fill={primaryColor}
    />
    <path
      d="M43.6152 9.21884C43.2493 9.04585 42.8343 9.19911 42.7161 9.51169L42.4498 10.0974C42.2921 10.4358 42.4803 10.8425 42.8165 10.976C43.1825 11.149 43.5974 10.9957 43.7157 10.6831L43.9819 10.0974C44.1743 9.76587 43.9811 9.39183 43.6152 9.21884Z"
      fill={primaryColor}
    />
    <path
      d="M10.3452 2.75781H3.32362C1.48787 2.75781 0 4.4015 0 6.42675V10.6383H10.3451L10.3452 2.75781Z"
      fill={secondaryColor}
    />
    <path
      d="M36.0749 5.44322V37.0004H9.97121C8.13546 37.0004 6.64759 35.7982 6.64759 34.315V5.44322C6.64759 3.96088 5.15972 2.75781 3.32397 2.75781H32.7513C34.587 2.75781 36.0749 3.96088 36.0749 5.44322V5.44322Z"
      fill={tertiaryColor}
    />
    <path
      d="M13.2947 33.4106V29.29H42.722V33.4106C42.722 35.3921 41.2341 37.0003 39.3984 37.0003H9.97107C11.8068 37.0003 13.2947 35.3921 13.2947 33.4106Z"
      fill={secondaryColor}
    />
    <path
      d="M32.6656 24.0745H10.8825C10.3226 24.0745 9.86853 23.77 9.86853 23.3942C9.86853 23.0184 10.3226 22.7139 10.8825 22.7139H32.6656C33.2255 22.7139 33.6796 23.0184 33.6796 23.3942C33.6796 23.77 33.2255 24.0745 32.6656 24.0745Z"
      fill={secondaryColor}
    />
    <path
      d="M32.6656 27.023H10.8825C10.3226 27.023 9.86853 26.6677 9.86853 26.2292C9.86853 25.7908 10.3226 25.4355 10.8825 25.4355H32.6656C33.2255 25.4355 33.6796 25.7908 33.6796 26.2292C33.6796 26.6677 33.2255 27.023 32.6656 27.023Z"
      fill={secondaryColor}
    />
    <rect x="9.78345" y="18.0859" width="23.8068" height="2.60896" rx="1.30448" fill={primaryColor} />
    <path
      d="M9.92139 14.9645V14.7439L10.7349 14.2613L14.389 5.79492H15.9747L19.5874 14.2613L20.5388 14.7439V14.9645H15.5748V14.7439L16.7607 14.2337L15.8782 12.1102H12.6654L11.7829 14.2337L12.9136 14.7439V14.9645H9.92139ZM12.9825 11.3518H15.561L14.2787 8.27692L12.9825 11.3518Z"
      fill={primaryColor}
    />
    <path d="M21.022 11.9034V10.9382H24.8139V11.9034H21.022Z" fill={primaryColor} />
    <path
      d="M26.9682 14.9645V14.675L29.8501 9.2973H28.0713L27.244 10.883H26.9544L27.1061 8.48375H27.1751C27.3037 8.52052 27.4646 8.5527 27.6577 8.58028C27.8507 8.60785 28.0438 8.62164 28.2368 8.62164H32.8423V8.91121L29.9604 14.2889H31.946L32.663 12.6066H32.9526V14.9645H26.9682Z"
      fill={primaryColor}
    />
  </svg>
);

export default EnglishIcon;
