import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.GeneralHistory];

const GeneralHistoryIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M31.5501 18.6406V37.2441H10.5935L10.5935 18.6406H31.5501Z" fill={secondaryColor} />
    <path d="M9.88525 18.7197H32.3516V21.0523H9.88525V18.7197Z" fill={primaryColor} />
    <path
      d="M36.7675 13.8457C36.0811 14.8504 34.5975 17.9304 32.0898 18.7372H9.83229C7.32459 17.9304 5.84092 14.8504 5.15454 13.8457V4.68945H36.7675V13.8457Z"
      fill={secondaryColor}
    />
    <path
      d="M17.7101 37.2703H15.0955L15.0955 26.3854C15.0955 25.5739 15.6808 24.916 16.4028 24.916C17.1248 24.916 17.7101 25.5739 17.7101 26.3854L17.7101 37.2703Z"
      fill={primaryColor}
    />
    <path
      d="M22.7383 37.2703H19.9225L19.9225 26.3854C19.9225 25.5739 20.5528 24.916 21.3304 24.916C22.1079 24.916 22.7382 25.5739 22.7382 26.3854L22.7383 37.2703Z"
      fill={primaryColor}
    />
    <path
      d="M27.5654 37.2703H24.9507V26.3854C24.9507 25.5739 25.536 24.916 26.258 24.916C26.98 24.916 27.5653 25.5739 27.5653 26.3854L27.5654 37.2703Z"
      fill={primaryColor}
    />
    <path
      d="M17.7791 19.7935H15.0514V18.1842C15.0514 17.3215 15.662 16.6221 16.4152 16.6221C17.1684 16.6221 17.779 17.3215 17.779 18.1842L17.7791 19.7935Z"
      fill={primaryColor}
    />
    <path
      d="M22.689 19.7935H19.9613V18.1842C19.9613 17.3215 20.5719 16.6221 21.3251 16.6221C22.0783 16.6221 22.6889 17.3215 22.6889 18.1842L22.689 19.7935Z"
      fill={primaryColor}
    />
    <path
      d="M27.5989 19.7935H24.8712L24.8713 18.1842C24.8713 17.3215 25.4819 16.6221 26.2351 16.6221C26.9883 16.6221 27.5989 17.3215 27.5989 18.1842V19.7935Z"
      fill={primaryColor}
    />
    <path
      d="M34.9295 3.01562H7.44507C3.41345 3.01562 0.0529273 6.15943 0.000632242 10.1112C-0.0525149 14.1265 3.25187 17.3978 7.33568 17.3978C10.6648 17.3978 13.4756 15.2238 14.3721 12.2451C14.6062 11.4673 15.3196 10.9232 16.1468 10.9232H26.1172C26.9283 10.9232 27.6559 11.4395 27.8807 12.2036C28.7916 15.2991 31.7668 17.5368 35.2507 17.391C39.018 17.2335 42.0815 14.243 42.257 10.5507C42.4532 6.42274 39.0972 3.01562 34.9295 3.01562V3.01562Z"
      fill={tertiaryColor}
    />
    <path
      d="M35.1003 5.02148H7.59498C4.80034 5.02148 2.45968 7.27796 2.4067 10.1297C2.35245 13.0478 4.6624 15.4392 7.50934 15.4392C9.2956 15.4392 10.8696 14.4973 11.7814 13.0749C12.1001 12.5777 11.7569 11.918 11.1748 11.918H11.1743C10.9289 11.918 10.6998 12.0438 10.5661 12.2538C9.86633 13.3515 8.6157 14.0512 7.21683 13.9392C5.43571 13.7966 4.00145 12.3222 3.87352 10.5035C3.72051 8.32896 5.41063 6.50963 7.50934 6.50963H35.0318C37.0225 6.50963 38.6976 8.1094 38.7446 10.1407C38.793 12.233 37.1396 13.9509 35.1003 13.9509C33.8239 13.9509 32.6994 13.2775 32.0481 12.2609C31.9122 12.0488 31.6833 11.918 31.4349 11.918C30.854 11.918 30.509 12.5763 30.8268 13.0727C31.785 14.5691 33.4759 15.5343 35.3759 15.4317C37.9877 15.2907 40.0961 13.1085 40.1998 10.4412C40.3151 7.47342 37.983 5.02148 35.1003 5.02148Z"
      fill={primaryColor}
    />
    <path
      d="M29.7232 8.14844H12.0268C11.6564 8.14844 11.3561 8.44074 11.3561 8.80128C11.3561 9.16183 11.6564 9.45413 12.0268 9.45413L29.7232 9.45413C30.0936 9.45413 30.3939 9.16183 30.3939 8.80128C30.3939 8.44074 30.0936 8.14844 29.7232 8.14844Z"
      fill={secondaryColor}
    />
    <path
      d="M7.74247 11.8646C8.79259 11.8646 9.64388 11.036 9.64388 10.0139C9.64388 8.99171 8.79259 8.16309 7.74247 8.16309C6.69235 8.16309 5.84106 8.99171 5.84106 10.0139C5.84106 11.036 6.69235 11.8646 7.74247 11.8646Z"
      fill={secondaryColor}
    />
    <path
      d="M34.8885 11.8666C35.9386 11.8666 36.7899 11.038 36.7899 10.0158C36.7899 8.99366 35.9386 8.16504 34.8885 8.16504C33.8383 8.16504 32.9871 8.99366 32.9871 10.0158C32.9871 11.038 33.8383 11.8666 34.8885 11.8666Z"
      fill={secondaryColor}
    />
    <path
      d="M39.7929 16.5437C35.1485 19.8498 32.8795 21.1417 31.3486 28.3699C35.4208 28.6419 40.5197 27.0889 45.2269 23.8026C49.9342 20.5162 53.2794 16.1872 55.5744 13.3597C51.5225 13.027 44.5001 13.2573 39.7929 16.5437Z"
      fill={tertiaryColor}
    />
    <path
      d="M29.47 31.492C29.2801 31.4375 29.0902 31.383 29.0011 31.2243C28.7408 30.9499 28.8085 30.5033 29.1552 30.2699C29.1552 30.2699 33.7114 24.8704 37.3347 22.181C40.9252 19.5159 48.8021 16.1164 48.4121 16.8226C48.0221 17.5288 42.0305 20.8041 38.4976 23.4015C34.8327 26.0958 30.0065 31.3132 30.0065 31.3132C29.8238 31.4605 29.6785 31.4853 29.47 31.492Z"
      fill={primaryColor}
    />
  </svg>
);

export default GeneralHistoryIcon;
