import { LessonTaskPlaybackMethodCode, ObjectType } from '../constants';
import { Icon, IconProps, PlaybackMethodIcons } from '../assets/icons';

type TaskPlaybackMethodIconProps = IconProps & {
  code?: LessonTaskPlaybackMethodCode | ObjectType.Lesson;
};

export const TaskPlaybackMethodIcon = ({ code, ...props }: TaskPlaybackMethodIconProps) => {
  const IconComponent = getIconComponent(code);

  return IconComponent && <IconComponent {...props} />;
};

const getIconComponent = (taskPlaybackMethod?: LessonTaskPlaybackMethodCode | ObjectType.Lesson): Icon => {
  switch (taskPlaybackMethod) {
    case ObjectType.Lesson:
      return PlaybackMethodIcons.TypeLessonIcon;
    case LessonTaskPlaybackMethodCode.Video:
      return PlaybackMethodIcons.TypeVideoIcon;
    case LessonTaskPlaybackMethodCode.Audio:
      return PlaybackMethodIcons.TypeAudioIcon;
    case LessonTaskPlaybackMethodCode.Test:
      return PlaybackMethodIcons.TypeTestIcon;
    case LessonTaskPlaybackMethodCode.Simulator:
      return PlaybackMethodIcons.TypeSimIcon;
    case LessonTaskPlaybackMethodCode.Interaction:
      return PlaybackMethodIcons.TypeInteractiveIcon;
    case LessonTaskPlaybackMethodCode.Text:
      return PlaybackMethodIcons.TypeTextIcon;
    case LessonTaskPlaybackMethodCode.MiniGames:
      return PlaybackMethodIcons.TypeGameIcon;
    case LessonTaskPlaybackMethodCode.Image:
      return PlaybackMethodIcons.TypeGalleryIcon;
    default:
      return PlaybackMethodIcons.TypeTrainerIcon;
  }
};
