import { createElement, useEffect, useRef } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { useLoadScript } from '../hooks';

type WebComponentProps = {
  url: string;
  onMessage?: (evt: Event) => void;
};

export const WebComponent = ({ url, onMessage }: WebComponentProps) => {
  const isModule = url.endsWith('.esm.js');
  const { ready, error } = useLoadScript(url, isModule);
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!ready || !onMessage) {
      return;
    }

    const element = ref.current;

    element?.addEventListener('xApiMessage', onMessage);

    return () => {
      element?.removeEventListener('xApiMessage', onMessage);
    };
  }, [ready, onMessage]);

  if (!ready && !error) {
    return (
      <div className="d-flex align-items-center justify-content-center p-4">
        <Spinner animation="grow" variant="light" />
      </div>
    );
  }

  if (!!error) {
    return <Alert variant="danger">{error.message}</Alert>;
  }

  return createElement(getComponentName(url), { ref });
};

const getComponentName = (url: string) => {
  const [fileName] = url.split('/').reverse();
  const [componentName] = fileName.split('.');

  return componentName;
};
