import { StrictMode } from 'react';
import { render } from 'react-dom';
import { App } from './components';

import './styles/index.scss';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider, LessonApiProvider } from './components/providers';

const baseApiUrl = process.env['REACT_APP_API_URL'] || 'http://cms.ru-edu.srvdev.ru';

render(
  <StrictMode>
    <BrowserRouter>
      <AuthProvider baseUrl={baseApiUrl}>
        <LessonApiProvider>
          <App />
        </LessonApiProvider>
      </AuthProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
