import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.English];

const EnglishIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="0 0 240 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1777_15276)">
      <path d="M175.206 79.5502L154.711 125.611L206.038 148.41L226.532 102.35L175.206 79.5502Z" fill={secondaryColor} />
      <path
        d="M226.25 85.8039L187.863 68.6958C186.021 67.9221 183.751 68.7442 182.977 70.5839L182.004 72.6929L227.17 92.794L228.142 90.685C228.942 88.6979 228.092 86.5777 226.25 85.8039Z"
        fill={secondaryColor}
      />
      <path
        d="M229.872 93.8685L179.18 71.4461C177.633 70.7233 175.831 71.4745 175.256 73.0449L174.034 75.718C173.311 77.2629 174.064 79.0629 175.636 79.6382L226.329 102.061C227.876 102.783 229.678 102.032 230.253 100.462L231.475 97.7887C232.025 96.3657 231.42 94.5912 229.872 93.8685Z"
        fill={tertiaryColor}
      />
      <path
        d="M200.935 146.098L187.51 177.387C187.51 177.387 186.507 179.408 183.889 180.164C181.27 180.92 178.611 180.535 178.611 180.535L146.572 158.107L159.968 128.088L200.935 146.098Z"
        fill={tertiaryColor}
      />
      <path d="M227.955 66.856L200.581 54.6963L193.284 71.0977L220.658 83.2575L227.955 66.856Z" fill={tertiaryColor} />
      <path
        d="M218.516 47.8828L200.625 54.6583L228.009 66.8289L221.07 48.9315C220.78 47.9706 219.503 47.4462 218.516 47.8828Z"
        fill={secondaryColor}
      />
      <path
        d="M187.827 130.473C196.957 132.051 205.635 125.939 207.21 116.821C208.786 107.704 202.662 99.0333 193.533 97.4557C184.404 95.8781 175.726 101.99 174.15 111.108C172.574 120.226 178.698 128.896 187.827 130.473Z"
        fill={primaryColor}
      />
      <path
        d="M189.285 116.759C188.155 116.261 187.621 114.954 188.12 113.826L190.589 108.332C191.088 107.204 192.396 106.671 193.526 107.17C194.656 107.669 195.19 108.975 194.691 110.104L192.222 115.597C191.723 116.725 190.415 117.258 189.285 116.759Z"
        fill={tertiaryColor}
      />
      <path
        d="M197.723 118.066C197.224 119.194 195.916 119.727 194.787 119.229L189.285 116.76C188.156 116.261 187.621 114.954 188.12 113.826C188.619 112.698 189.927 112.165 191.057 112.664L196.558 115.132C197.688 115.631 198.222 116.938 197.723 118.066Z"
        fill={tertiaryColor}
      />
      <path
        d="M177.534 143.419C175.987 142.696 174.185 143.448 173.635 144.871L168.696 155.858C167.973 157.403 168.726 159.203 170.151 159.752C171.698 160.475 173.5 159.724 174.05 158.301L178.989 147.314C179.686 145.916 179.081 144.142 177.534 143.419Z"
        fill={primaryColor}
      />
      <path
        d="M187.824 148.082C186.277 147.359 184.475 148.11 183.925 149.533L178.987 160.52C178.264 162.065 179.017 163.865 180.442 164.415C181.989 165.138 183.791 164.387 184.34 162.964L189.279 151.976C190.002 150.432 189.224 148.779 187.824 148.082Z"
        fill={primaryColor}
      />
      <path
        d="M167.218 138.904C165.671 138.181 163.87 138.933 163.32 140.356L158.381 151.343C157.658 152.888 158.411 154.687 159.836 155.237C161.383 155.96 163.185 155.209 163.735 153.786L168.673 142.799C169.518 141.427 168.766 139.627 167.218 138.904Z"
        fill={primaryColor}
      />
      <path
        d="M207.031 62.4448C205.484 61.722 203.682 62.4733 203.132 63.8963L201.911 66.5694C201.188 68.1143 201.94 69.9142 203.365 70.464C204.913 71.1868 206.714 70.4356 207.264 69.0126L208.486 66.3395C209.184 64.942 208.578 63.1675 207.031 62.4448Z"
        fill={primaryColor}
      />
      <path
        d="M217.886 67.3569C216.339 66.6341 214.538 67.3854 213.988 68.8084L212.766 71.4815C212.043 73.0264 212.796 74.8263 214.221 75.3762C215.768 76.0989 217.57 75.3477 218.119 73.9247L219.341 71.2516C220.212 69.7322 219.433 68.0797 217.886 67.3569Z"
        fill={primaryColor}
      />
      <path
        d="M71.102 39.0005H40.1389C32.0438 39.0005 25.4827 46.2487 25.4827 55.1795V73.7513H71.1015L71.102 39.0005Z"
        fill={secondaryColor}
      />
      <path
        d="M184.562 50.8419V190H69.4528C61.3577 190 54.7966 184.699 54.7966 178.158V50.8419C54.7966 44.3052 48.2355 39 40.1404 39H169.906C178.001 39 184.562 44.3052 184.562 50.8419V50.8419Z"
        fill={tertiaryColor}
      />
      <path
        d="M84.1076 174.171V156H213.874V174.171C213.874 182.909 207.312 190 199.217 190H69.4514C77.5465 190 84.1076 182.909 84.1076 174.171Z"
        fill={secondaryColor}
      />
      <path
        d="M169.529 133H73.4714C71.0023 133 69 131.657 69 130C69 128.343 71.0023 127 73.4714 127H169.529C171.998 127 174 128.343 174 130C174 131.657 171.998 133 169.529 133Z"
        fill={secondaryColor}
      />
      <path
        d="M169.529 146H73.4714C71.0023 146 69 144.433 69 142.5C69 140.567 71.0023 139 73.4714 139H169.529C171.998 139 174 140.567 174 142.5C174 144.433 171.998 146 169.529 146Z"
        fill={secondaryColor}
      />
      <rect x="68.6255" y="106.59" width="104.981" height="11.5048" rx="5.75239" fill={primaryColor} />
      <path
        d="M69.2336 92.8289V91.856L72.8211 89.7278L88.9345 52.3936H95.927L111.858 89.7278L116.053 91.856V92.8289H94.1637V91.856L99.3929 89.6062L95.5014 80.2422H81.3338L77.4423 89.6062L82.4283 91.856V92.8289H69.2336ZM82.7324 76.898H94.1029L88.448 63.3385L82.7324 76.898Z"
        fill={primaryColor}
      />
      <path d="M118.184 79.3302V75.0738H134.905V79.3302H118.184Z" fill={primaryColor} />
      <path
        d="M144.405 92.8289V91.552L157.113 67.838H149.27L145.621 74.8306H144.344L145.013 64.2505H145.317C145.885 64.4127 146.594 64.5546 147.446 64.6762C148.297 64.7978 149.148 64.8586 149.999 64.8586H170.308V66.1355L157.6 89.8494H166.356L169.518 82.4312H170.795V92.8289H144.405Z"
        fill={primaryColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1777_15276"
        x="-30"
        y="39"
        width="300"
        height="211"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0 0 0 0 0 0.352941 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1777_15276" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1777_15276" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default EnglishIcon;
