export const DefaultPlyrOptions = {
  i18n: {
    restart: 'Перезапустить',
    play: 'Запустить',
    pause: 'Пауза',
    fastForward: 'Вперёд {seektime}с',
    played: 'просмотрено',
    duration: 'Продолжительность',
    volume: 'Громкость',
    mute: 'Выключить звук',
    unmute: 'Включить звук',
    download: 'Скачать',
    enterFullscreen: 'Полноэкранный режим',
    exitFullscreen: 'Выйти из полноэкранного режима',
    captions: 'Субтитры',
    settings: 'Настройки',
    menuBack: 'Назад',
    speed: 'Скорость',
    normal: 'нормальная',
    quality: 'Качество',
    start: 'Начало',
    end: 'Конец',
    all: 'Все',
    reset: 'Сбросить'
  }
};
