import { MathJax } from 'better-react-mathjax';
import { forwardRef } from 'react';
import { Text, TextProps } from './Text';

export const LessonText = forwardRef<HTMLDivElement, TextProps>(function LessonText({ children, ...textProps }, ref) {
  if (!children) return null;

  return (
    <MathJax>
      <Text ref={ref} {...textProps}>
        {children}
      </Text>
    </MathJax>
  );
});

export default LessonText;
