import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Geometry];

const GeometryIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="0 0 240 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1975_13918)">
      <path
        d="M63.485 170.845C62.3447 170.845 61.3469 170.125 60.9193 169.117L49.5163 143.203C48.9462 141.764 49.5163 140.036 50.9417 139.46C52.3671 138.74 54.0775 139.46 54.6477 140.9L63.485 160.911L72.3223 140.9C72.8925 139.46 74.6029 138.74 76.0283 139.46C77.4536 140.036 78.1663 141.764 77.4536 143.203L66.0507 169.117C65.623 170.125 64.6253 170.845 63.485 170.845Z"
        fill={secondaryColor}
      />
      <path
        d="M149.008 62.871C145.017 62.871 141.881 59.7038 141.881 55.6727C141.881 51.6417 145.017 48.4744 149.008 48.4744C152.999 48.4744 156.134 51.6417 156.134 55.6727C156.134 57.2563 157.417 58.552 158.985 58.552C160.553 58.552 161.836 57.2563 161.836 55.6727C161.836 48.4744 156.134 42.7158 149.008 42.7158C141.881 42.7158 136.179 48.4744 136.179 55.6727C136.179 62.871 141.881 68.6296 149.008 68.6296C152.999 68.6296 156.134 71.7969 156.134 75.8279C156.134 79.8589 152.999 83.0262 149.008 83.0262C145.017 83.0262 141.881 79.8589 141.881 75.8279C141.881 74.2443 140.598 72.9486 139.03 72.9486C137.462 72.9486 136.179 74.2443 136.179 75.8279C136.179 83.0262 141.881 88.7848 149.008 88.7848C156.134 88.7848 161.836 83.0262 161.836 75.8279C161.836 68.6296 156.134 62.871 149.008 62.871Z"
        fill={tertiaryColor}
      />
      <path
        d="M44.9552 98.8622C44.3851 98.8622 43.8149 98.7183 43.2448 98.2864L34.9776 91.9519V95.9829C34.9776 97.5665 33.6948 98.8622 32.1269 98.8622C30.559 98.8622 29.2761 97.5665 29.2761 95.9829V74.3881C29.2761 72.8045 30.559 71.5088 32.1269 71.5088H38.541C43.6724 71.5088 47.806 75.6838 47.806 80.8665C47.806 85.0415 45.0978 88.4967 41.5343 89.7924L46.6657 93.6795C47.9485 94.6872 48.2336 96.4148 47.2358 97.7105C46.6657 98.4303 45.8105 98.8622 44.9552 98.8622ZM34.9776 84.4657H38.541C40.5366 84.4657 42.1045 82.8821 42.1045 80.8665C42.1045 78.851 40.5366 77.2674 38.541 77.2674H34.9776V84.4657Z"
        fill={secondaryColor}
      />
      <path
        d="M100.545 70.069H83.4403C82.585 70.069 82.0149 69.4931 82.0149 68.6293V34.0776C82.0149 28.4629 86.4335 24 91.9925 24C97.5515 24 101.97 28.4629 101.97 34.0776V68.6293C101.97 69.4931 101.4 70.069 100.545 70.069Z"
        fill={primaryColor}
      />
      <path
        d="M27.8507 183.802C27.4231 183.802 26.8529 183.658 26.4253 183.37C25 182.506 24.5724 180.778 25.4276 179.483L82.4425 81.5862C83.2977 80.1466 85.0082 79.7147 86.291 80.5785C87.7164 81.4422 88.144 83.1698 87.2888 84.4655L30.2738 182.362C29.8462 183.226 28.8485 183.802 27.8507 183.802Z"
        fill={secondaryColor}
      />
      <path
        d="M156.134 183.801C155.137 183.801 154.139 183.226 153.711 182.362L96.6962 84.4653C95.841 83.0256 96.4111 81.298 97.694 80.5782C99.1193 79.7144 100.83 80.2903 101.542 81.586L158.557 179.483C159.413 180.922 158.842 182.65 157.56 183.37C157.132 183.658 156.562 183.801 156.134 183.801Z"
        fill={secondaryColor}
      />
      <path
        d="M131.903 115.706H52.0819C50.514 115.706 49.2312 114.41 49.2312 112.827C49.2312 111.243 50.514 109.947 52.0819 109.947H131.903C133.471 109.947 134.754 111.243 134.754 112.827C134.754 114.41 133.471 115.706 131.903 115.706Z"
        fill={secondaryColor}
      />
      <path
        d="M91.9926 123.336C88.0015 123.336 84.8657 120.169 84.8657 116.138V108.939C84.8657 104.908 88.0015 101.741 91.9926 101.741C95.9836 101.741 99.1194 104.908 99.1194 108.939V116.138C99.1194 120.169 95.9836 123.336 91.9926 123.336Z"
        fill={tertiaryColor}
      />
      <path
        d="M39.8239 169.549C38.256 169.549 36.8306 169.117 35.5478 168.253C31.8418 165.95 30.559 161.055 32.8396 157.168L70.8971 90.8C71.0396 90.512 71.4672 90.2241 71.7523 90.0801C72.1799 89.9362 72.465 90.0801 72.8926 90.2241L84.2956 97.4224C85.0083 97.8543 85.1508 98.7181 84.7232 99.4379L46.8083 165.518C45.668 167.534 43.815 168.829 41.6769 169.405C41.1068 169.549 40.3941 169.549 39.8239 169.549Z"
        fill={primaryColor}
      />
      <path
        d="M144.161 169.549C143.448 169.549 142.878 169.405 142.166 169.261C140.027 168.685 138.174 167.39 137.034 165.374L99.2618 99.5819C98.8342 98.8621 99.1193 97.9983 99.6894 97.5664L111.092 90.3681C111.52 90.2241 111.948 90.2241 112.233 90.2241C112.66 90.3681 112.945 90.5121 113.088 90.9439L151.145 157.312C153.426 161.199 152.143 166.094 148.437 168.397C147.154 169.117 145.729 169.549 144.161 169.549Z"
        fill={primaryColor}
      />
      <path
        d="M91.9924 90.2241C82.585 90.2241 74.8879 82.45 74.8879 72.9482C74.8879 63.4465 82.585 55.6724 91.9924 55.6724C101.4 55.6724 109.097 63.4465 109.097 72.9482C109.097 82.45 101.4 90.2241 91.9924 90.2241Z"
        fill={tertiaryColor}
      />
      <path
        d="M91.9925 83.0258C86.4335 83.0258 82.0149 78.5628 82.0149 72.9482C82.0149 67.3335 86.4335 62.8706 91.9925 62.8706C97.5515 62.8706 101.97 67.3335 101.97 72.9482C101.97 78.5628 97.5515 83.0258 91.9925 83.0258Z"
        fill={secondaryColor}
      />
      <path
        d="M208.873 191H87.7163C87.1462 191 86.576 190.712 86.4335 190.136C86.1484 189.56 86.2909 188.984 86.7185 188.552L213.577 60.4231C214.004 59.9912 214.574 59.8472 215.145 60.1352C215.715 60.4231 216 60.855 216 61.4308V183.802C216 187.689 212.722 191 208.873 191ZM152.428 160.767H186.067V126.791L152.428 160.767Z"
        fill={tertiaryColor}
      />
      <path
        d="M216 172.284H208.873C207.305 172.284 206.022 173.58 206.022 175.163C206.022 176.747 207.305 178.043 208.873 178.043H216V172.284Z"
        fill={secondaryColor}
      />
      <path
        d="M208.873 162.207H216V156.448H208.873C207.305 156.448 206.022 157.744 206.022 159.328C206.022 160.911 207.305 162.207 208.873 162.207Z"
        fill={secondaryColor}
      />
      <path
        d="M208.873 146.37H216V140.612H208.873C207.305 140.612 206.022 141.908 206.022 143.491C206.022 145.075 207.305 146.37 208.873 146.37Z"
        fill={secondaryColor}
      />
      <path
        d="M208.873 130.535H216V124.776H208.873C207.305 124.776 206.022 126.072 206.022 127.655C206.022 129.239 207.305 130.535 208.873 130.535Z"
        fill={secondaryColor}
      />
      <path
        d="M208.873 114.698H216V108.939H208.873C207.305 108.939 206.022 110.235 206.022 111.819C206.022 113.402 207.305 114.698 208.873 114.698Z"
        fill={secondaryColor}
      />
      <path
        d="M208.873 98.8621H216V93.1035H208.873C207.305 93.1035 206.022 94.3992 206.022 95.9828C206.022 97.5664 207.305 98.8621 208.873 98.8621Z"
        fill={secondaryColor}
      />
      <path
        d="M206.022 80.1464C206.022 81.73 207.305 83.0257 208.873 83.0257H216V77.2671H208.873C207.305 77.2671 206.022 78.5628 206.022 80.1464Z"
        fill={secondaryColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1975_13918"
        x="-5.01123"
        y="24"
        width="251.011"
        height="227"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.4 0 0 0 0 0.588235 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1975_13918" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1975_13918" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default GeometryIcon;
