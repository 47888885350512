import { BaseApi } from './BaseApi';
import { StringifiableRecord } from '../models';

export interface IListApi<T, Q extends StringifiableRecord = StringifiableRecord> {
  getList(query?: Q): Promise<T[]>;
}

export abstract class ListApi<T, Q extends StringifiableRecord = StringifiableRecord>
  extends BaseApi
  implements IListApi<T, Q>
{
  getList = (query?: Q) => this.get<T[]>('', query);
}
