import { KeyboardEvent, CSSProperties } from 'react';

export const getElementHeight = (element?: HTMLElement | null) => {
  if (!element) {
    return 0;
  }

  const style = getComputedStyle(element);
  const borderTopWidth = parseInt(style.borderTopWidth, 10) || 0;
  const borderBottomWidth = parseInt(style.borderBottomWidth, 10) || 0;

  return element.offsetHeight - (borderTopWidth + borderBottomWidth);
};

export const getCssProperties = (stringStyles: string): CSSProperties =>
  (stringStyles || '').split(';').reduce((acc, style) => {
    const colonPosition = style.indexOf(':');

    if (colonPosition === -1) return acc;

    const value = style.substr(colonPosition + 1).trim();

    if (!value) return acc;

    const camelCaseProperty = style
      .substr(0, colonPosition)
      .trim()
      .replace(/^-ms-/, 'ms-')
      .replace(/-./g, (c) => c.substr(1).toUpperCase());

    return { ...acc, [camelCaseProperty]: value };
  }, {});

export const requestInterval = (fn: Function, delay: number) => {
  let start = new Date().getTime();
  let handle = { value: 0 };

  const loop = () => {
    const current = new Date().getTime();
    const delta = current - start;

    if (delta >= delay) {
      fn.call(null);
      start = new Date().getTime();
    }

    handle.value = requestAnimationFrame(loop);
  };

  handle.value = requestAnimationFrame(loop);

  return handle;
};

export const clearRequestInterval = (handle: { value: number }) => cancelAnimationFrame(handle.value);

export const checkButtonEventKey = (e: KeyboardEvent) => {
  return ['Enter', ' '].includes(e.key);
};
