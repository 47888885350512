import { Container } from 'react-bootstrap';
import { LessonDetailsHeader } from './LessonDetailsHeader';
import { LessonDetailsContent } from './LessonDetailsContent';
import { LessonDetailsNavigation } from './navigation/LessonDetailsNavigation';
import { LessonPlannedResults } from './planned-results/LessonPlannedResults';
import { LessonHeader } from '../LessonHeader';
import { useFixedTopHeader } from '../../hooks';

type LessonDetailsProps = {
  onBackClick?: () => void;
};

export const LessonDetails = ({ onBackClick }: LessonDetailsProps) => {
  const { headerRef, contentRef } = useFixedTopHeader();

  return (
    <div ref={contentRef} className="lesson-details__wrapper">
      <Container className="lesson-details">
        <LessonHeader ref={headerRef} onBackClick={onBackClick} />
        <div className="pb-5">
          <LessonDetailsHeader />
          <LessonDetailsContent />
          <LessonDetailsNavigation />
          <LessonPlannedResults />
        </div>
      </Container>
    </div>
  );
};
