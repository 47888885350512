import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.ClassHour];

const ClassHourIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M25.1947 29.2683H47.022C47.3783 29.2683 47.6456 29.0022 47.6456 28.6475V27.2284C47.6456 26.8736 47.3783 26.6075 47.022 26.6075H11.3856C9.24742 26.6075 7.55469 28.2927 7.55469 30.4213V36.1863C7.55469 38.2262 9.24742 39.9113 11.3856 39.9113H47.022C47.3783 39.9113 47.6456 39.6452 47.6456 39.2905V37.8714C47.6456 37.5166 47.3783 37.2506 47.022 37.2506H25.1947C24.3038 37.2506 23.5911 36.541 23.5911 35.6541V30.8648C23.5911 29.9778 24.3038 29.2683 25.1947 29.2683Z"
      fill={primaryColor}
    />
    <path
      d="M25.5525 23.9468H3.7252C3.36884 23.9468 3.10156 24.2129 3.10156 24.5676V25.9867C3.10156 26.3415 3.36884 26.6075 3.7252 26.6075H39.3616C41.4997 26.6075 43.1925 24.9224 43.1925 22.7938V17.0288C43.1925 14.9002 41.4997 13.2151 39.3616 13.2151H3.7252C3.36884 13.3038 3.10156 13.5699 3.10156 13.9246V15.3437C3.10156 15.6985 3.36884 15.9645 3.7252 15.9645H25.5525C26.4434 15.9645 27.1561 16.6741 27.1561 17.561V22.3503C27.1561 23.2373 26.4434 23.9468 25.5525 23.9468Z"
      fill={tertiaryColor}
    />
    <path
      d="M38.6144 20C38.6144 17.8902 36.904 16.1798 34.7941 16.1798H5.12544C4.22381 18.7266 4.22381 21.2734 5.12544 23.8203H34.7941C36.904 23.8203 38.6144 22.1099 38.6144 20V20Z"
      fill={quaternaryColor}
    />
    <path
      d="M30.0056 10.643H8.17832C7.82196 10.643 7.55469 10.9091 7.55469 11.2639V12.6829C7.55469 13.0377 7.82196 13.3038 8.17832 13.3038H43.8147C45.9529 13.3038 47.6456 11.6186 47.6456 9.49002V3.81375C47.6456 1.68514 45.9529 0 43.8147 0H8.17832C7.82196 0 7.55469 0.266075 7.55469 0.620843V2.03991C7.55469 2.39468 7.82196 2.66075 8.17832 2.66075H30.0056C30.8965 2.66075 31.6092 3.37029 31.6092 4.25721V9.04656C31.6092 9.93348 30.8965 10.643 30.0056 10.643Z"
      fill={primaryColor}
    />
    <path
      d="M41.7612 6.69665C41.7612 4.48752 39.9703 2.69666 37.7612 2.69666H10.4755C9.63317 5.39328 9.63317 8.08991 10.4755 10.7865H37.7612C39.9703 10.7865 41.7612 8.99568 41.7612 6.78654V6.69665Z"
      fill={quaternaryColor}
    />
    <path
      d="M12.0918 33.3034C12.0918 35.5125 13.8827 37.3034 16.0918 37.3034H45.5807C46.4824 34.6068 46.4824 31.9101 45.5807 29.2135H16.0918C13.8827 29.2135 12.0918 31.0044 12.0918 33.2135V33.3034Z"
      fill={quaternaryColor}
    />
    <path
      d="M49.6855 32.4468L52.7305 31.9093L50.1352 17.3396L47.0903 17.8771L49.6855 32.4468Z"
      fill={quaternaryColor}
    />
    <path
      d="M52.427 40.4495L52.8588 40.349V40.2486C53.0747 37.4356 53.0747 34.7231 52.6429 31.9102L49.4043 32.4125C50.052 35.125 51.0236 37.737 52.319 40.2486L52.427 40.4495Z"
      fill={tertiaryColor}
    />
    <path
      d="M45.3865 0.487983C46.2715 0.289238 47.0582 0.885473 47.2549 1.68045C48.4349 6.35096 49.4183 11.0215 50.1067 15.7914L50.3034 17.2819L46.4682 17.9776L46.1732 16.487C45.1898 11.8165 44.5015 7.04657 44.0098 2.27669C44.0098 1.48171 44.5998 0.686728 45.3865 0.487983Z"
      fill={secondaryColor}
    />
  </svg>
);

export default ClassHourIcon;
