import classNames from 'classnames';
import { forwardRef } from 'react';
import { Button, Col, Container, Pagination, Row } from 'react-bootstrap';
import { useLesson } from '../../providers';
import { getLessonModules, isLessonSolved } from '../../utils/LessonUtils';
import { ArrowLineIcon } from '../../assets/icons';

export const LessonModuleFooter = forwardRef<HTMLDivElement>(function LessonModuleFooter(_, ref) {
  const { lesson, activeUnit, activeModule, result, onModuleSelect, onFinish } = useLesson();

  const isSolved = !!lesson && isLessonSolved(lesson, result);

  const modules = getLessonModules(lesson);
  const moduleIdx = modules.findIndex((module) => module === activeModule);
  const isModuleFirst = moduleIdx === 0;
  const isModuleLast = moduleIdx === modules.length - 1;

  const unitModules = activeUnit?.modules || [];

  const handlePrevClick = () => onModuleSelect(modules[moduleIdx - 1]);
  const handleNextClick = () => {
    if (moduleIdx < modules.length - 1) {
      return onModuleSelect(modules[moduleIdx + 1]);
    }

    onFinish();
  };

  return (
    <footer ref={ref} className="lesson__footer">
      <Container>
        <Row>
          <Col className="d-flex justify-content-start">
            {!isModuleFirst && (
              <Button variant="outline-primary" title="Предыдущий модуль" onClick={handlePrevClick}>
                <ArrowLineIcon />
                <span className="d-none d-md-inline-block">Предыдущий модуль</span>
              </Button>
            )}
          </Col>
          <Col className="d-flex justify-content-center">
            {unitModules.length > 1 && (
              <Pagination>
                {unitModules.map((module, idx) => (
                  <Pagination.Item
                    key={module.uid}
                    active={module === activeModule}
                    onClick={onModuleSelect.bind(null, module)}
                  >
                    {idx + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            )}
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              onClick={handleNextClick}
              variant="outline-primary"
              title={isModuleLast ? 'Готово' : 'Следующий модуль'}
              disabled={isModuleLast && !isSolved}
            >
              <span className={classNames({ 'd-none d-md-inline-block': !isModuleLast })}>
                {isModuleLast ? 'Готово' : 'Следующий модуль'}
              </span>
              {!isModuleLast && <ArrowLineIcon style={{ transform: 'rotate(180deg)' }} />}
            </Button>
          </Col>
        </Row>
      </Container>
    </footer>
  );
});
