import { VFC } from 'react';
import { CommonPlayerProps } from './CommonPlayerProps';
import { LessonTaskModel } from '../models';
import { LessonContextProvider } from '../providers';
import { LessonTask } from './task';
import { PlayerWrapper } from './PlayerWrapper';

type TaskPlayerProps = CommonPlayerProps & {
  task: LessonTaskModel;
};

export const TaskPlayer: VFC<TaskPlayerProps> = ({ task, role, fetcher, onBackClick }) => {
  return (
    <PlayerWrapper fetcher={fetcher}>
      <LessonContextProvider task={task} role={role}>
        <LessonTask onBackClick={onBackClick} hideNextButton={true} />
      </LessonContextProvider>
    </PlayerWrapper>
  );
};
