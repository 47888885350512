import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.RussianLanguage];

const RussianLanguageIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M47.8822 21.9248H39.4705V37.0003H47.8822V21.9248Z" fill={secondaryColor} />
    <path
      d="M44.938 26.4148H45.941C46.1027 26.4148 46.2321 26.2865 46.2321 26.1261V24.1695C46.2321 24.0092 46.1027 23.8809 45.941 23.8809H44.938C44.7763 23.8809 44.6469 24.0092 44.6469 24.1695V26.1261C44.6469 26.2865 44.7763 26.4148 44.938 26.4148Z"
      fill={primaryColor}
    />
    <path
      d="M41.3793 26.4148H42.3823C42.544 26.4148 42.6734 26.2865 42.6734 26.1261V24.1695C42.6734 24.0092 42.544 23.8809 42.3823 23.8809H41.3793C41.2175 23.8809 41.0881 24.0092 41.0881 24.1695V26.1261C41.0881 26.2865 41.2175 26.4148 41.3793 26.4148Z"
      fill={primaryColor}
    />
    <path d="M47.8822 31.8682H39.4705V37.0002H47.8822V31.8682Z" fill={tertiaryColor} />
    <path d="M47.8822 28.3398H39.4705V33.4719H47.8822V28.3398Z" fill={tertiaryColor} />
    <path
      d="M44.6469 8.13222C44.6469 8.00392 43.6763 8.13222 43.6763 8.13222C43.6763 8.13222 42.7057 8.00392 42.7057 8.13222L41.4116 15.5096H43.6763H45.941L44.6469 8.13222Z"
      fill={secondaryColor}
    />
    <path d="M41.7028 13.9053L41.4116 15.509H43.6763H45.941L45.6499 13.9053H41.7028Z" fill={tertiaryColor} />
    <path
      d="M46.6528 15.8305C46.6205 15.6381 46.4587 15.5098 46.2646 15.5098H43.6763H41.0881C40.894 15.5098 40.7322 15.6381 40.6999 15.8305L39.4705 21.9249H43.6763H47.8822L46.6528 15.8305Z"
      fill={secondaryColor}
    />
    <path
      d="M43.6763 6.20764C44.391 6.20764 44.9704 5.63321 44.9704 4.92462C44.9704 4.21603 44.391 3.6416 43.6763 3.6416C42.9616 3.6416 42.3822 4.21603 42.3822 4.92462C42.3822 5.63321 42.9616 6.20764 43.6763 6.20764Z"
      fill={tertiaryColor}
    />
    <path d="M44.3234 5.56641H43.0293V8.13244H44.3234V5.56641Z" fill={tertiaryColor} />
    <path
      d="M44.9704 7.81187V7.17036C44.9704 7.00999 44.841 6.84961 44.6469 6.84961H42.7057C42.544 6.84961 42.3822 6.97791 42.3822 7.17036V7.81187C42.3822 7.97225 42.5116 8.13263 42.7057 8.13263H44.6793C44.841 8.13263 44.9704 8.00433 44.9704 7.81187Z"
      fill={tertiaryColor}
    />
    <path
      d="M39.5029 19.6152L36.5588 17.1133L33.647 19.6152C32.8382 20.1925 32.3529 21.0265 32.3529 21.9246C32.3529 23.6887 34.2294 25.1321 36.5588 25.1321C38.8882 25.1321 40.7647 23.6887 40.7647 21.9246C40.7647 21.0265 40.2794 20.2246 39.5029 19.6152Z"
      fill={secondaryColor}
    />
    <path
      d="M39.4705 24.1699H33.647C33.4853 24.1699 33.3235 24.2982 33.3235 24.4907V25.7737C33.3235 25.9341 33.4529 26.0945 33.647 26.0945H39.4705C39.6323 26.0945 39.7941 25.9662 39.7941 25.7737V24.4907C39.7941 24.2982 39.6647 24.1699 39.4705 24.1699Z"
      fill={tertiaryColor}
    />
    <path d="M39.4705 26.0938H33.647V31.8673H39.4705V26.0938Z" fill={primaryColor} />
    <path
      d="M36.5588 29.3018C35.847 29.3018 35.2646 29.8791 35.2646 30.5848V36.9999H37.8529V30.5848C37.8529 29.8791 37.2705 29.3018 36.5588 29.3018Z"
      fill={primaryColor}
    />
    <path
      d="M39.147 29.3018C38.4353 29.3018 37.8529 29.8791 37.8529 30.5848V36.9999H40.4411V30.5848C40.4411 29.8791 39.8588 29.3018 39.147 29.3018Z"
      fill={primaryColor}
    />
    <path
      d="M33.9705 29.3018C33.2587 29.3018 32.6764 29.8791 32.6764 30.5848V36.9999H35.2646V30.5848C35.2646 29.8791 34.6823 29.3018 33.9705 29.3018Z"
      fill={primaryColor}
    />
    <path d="M40.4411 32.1885H32.6764V36.9998H40.4411V32.1885Z" fill={secondaryColor} />
    <path
      d="M33.647 19.6147C33.2587 19.9034 32.9352 20.2562 32.7087 20.6411H40.4087C40.1823 20.2562 39.8587 19.9034 39.5028 19.6147L38.6293 18.877H34.5205L33.647 19.6147Z"
      fill={primaryColor}
    />
    <path
      d="M32.4176 22.4053C32.547 23.0789 32.9676 23.6883 33.5823 24.1694H39.6C40.2147 23.6883 40.6029 23.0789 40.7647 22.4053H32.4176Z"
      fill={primaryColor}
    />
    <path
      d="M53.7381 19.6152L50.794 17.1133L47.8823 19.6152C47.0734 20.1925 46.5881 21.0265 46.5881 21.9246C46.5881 23.6887 48.4646 25.1321 50.794 25.1321C53.1234 25.1321 54.9999 23.6887 54.9999 21.9246C54.9999 21.0265 54.5146 20.2246 53.7381 19.6152Z"
      fill={secondaryColor}
    />
    <path
      d="M48.6911 24.715V18.9414L47.8823 19.615C47.0734 20.1923 46.5881 21.0263 46.5881 21.9244C46.5881 23.1112 47.4293 24.1376 48.6911 24.715Z"
      fill={primaryColor}
    />
    <path
      d="M52.897 24.7152V18.9416L50.7941 17.1133V25.1321C51.5705 25.1321 52.2823 24.9718 52.897 24.7152Z"
      fill={primaryColor}
    />
    <path
      d="M53.7058 24.1699H47.8822C47.7205 24.1699 47.5587 24.2982 47.5587 24.4907V25.7737C47.5587 25.9341 47.6881 26.0945 47.8822 26.0945H53.7058C53.8675 26.0945 54.0293 25.9662 54.0293 25.7737V24.4907C54.0293 24.2982 53.8999 24.1699 53.7058 24.1699Z"
      fill={tertiaryColor}
    />
    <path d="M53.7058 26.0938H47.8823V31.8673H53.7058V26.0938Z" fill={secondaryColor} />
    <path
      d="M50.794 29.3018C50.0822 29.3018 49.4999 29.8791 49.4999 30.5848V36.9999H52.0881V30.5848C52.0881 29.8791 51.5057 29.3018 50.794 29.3018Z"
      fill={primaryColor}
    />
    <path
      d="M53.3822 29.3018C52.6705 29.3018 52.0881 29.8791 52.0881 30.5848V36.9999H54.6764V30.5848C54.6764 29.8791 54.094 29.3018 53.3822 29.3018Z"
      fill={primaryColor}
    />
    <path
      d="M48.2057 29.3018C47.494 29.3018 46.9116 29.8791 46.9116 30.5848V36.9999H49.4999V30.5848C49.4999 29.8791 48.9175 29.3018 48.2057 29.3018Z"
      fill={primaryColor}
    />
    <path d="M54.6763 32.1885H46.9116V36.9998H54.6763V32.1885Z" fill={secondaryColor} />
    <path
      d="M3.55882 3C1.58529 3 0 4.5717 0 6.5283V10.6981H7.11765V6.5283C7.11765 4.5717 5.53235 3 3.55882 3Z"
      fill={secondaryColor}
    />
    <path
      d="M32.6765 3H3.55884C5.53237 3 7.11766 4.5717 7.11766 6.5283V29.3019V33.4717C7.11766 35.3962 8.70296 36.9679 10.6441 37H10.6765H36.2353V10.6981V6.5283C36.2353 4.5717 34.65 3 32.6765 3Z"
      fill={tertiaryColor}
    />
    <path
      d="M36.2353 29.3018H14.2353V33.4716C14.2353 35.4282 12.65 36.9999 10.6765 36.9999H39.7942C41.7677 36.9999 43.353 35.4282 43.353 33.4716V29.3018H36.2353Z"
      fill={secondaryColor}
    />
    <path
      d="M21.0294 15.1886H20.4147C20.1882 15.1886 19.9941 15.0603 19.9294 14.8358L17.5029 7.65087C17.4706 7.55464 17.4382 7.42634 17.4382 7.29804V6.52823H18.0853C18.2794 6.52823 18.4088 6.39993 18.4088 6.20747C18.4088 6.01502 18.2794 5.88672 18.0853 5.88672H14.2353C14.0412 5.88672 13.9118 6.01502 13.9118 6.20747C13.9118 6.39993 14.0412 6.52823 14.2353 6.52823H14.8823V7.49049L11.3559 12.8471C10.6765 13.9056 11.097 15.3169 12.2618 15.798C13.2 16.1829 14.3 15.8301 14.7853 14.932L16.4353 12.0773L17.4706 15.1886H16.8235C16.6294 15.1886 16.5 15.3169 16.5 15.5094C16.5 15.7018 16.6294 15.8301 16.8235 15.8301H21.0294C21.2235 15.8301 21.3529 15.7018 21.3529 15.5094C21.3529 15.3169 21.2235 15.1886 21.0294 15.1886ZM14.4294 13.7131C14.1382 14.1943 13.5235 14.3546 13.0382 14.1301C12.4882 13.8414 12.2618 13.1358 12.6176 12.6226L15.2382 8.54898L16.0794 11.0188L14.4294 13.7131Z"
      fill={primaryColor}
    />
    <path
      d="M25.2353 12.9432H21.6764C21.5147 12.9432 21.3529 12.8149 21.3529 12.6224V11.9809C21.3529 11.8205 21.4823 11.6602 21.6764 11.6602H25.2353C25.397 11.6602 25.5588 11.7885 25.5588 11.9809V12.6224C25.5588 12.8149 25.4294 12.9432 25.2353 12.9432Z"
      fill={primaryColor}
    />
    <path
      d="M32.9676 9.41504H27.5323C27.3382 9.41504 27.1764 9.57542 27.1764 9.76787V10.3773C27.1764 11.5962 28.1794 12.5905 29.4088 12.5905H29.7647L28.6647 12.9433C27.597 13.2962 26.8529 14.2905 26.8529 15.4452C26.8529 15.6377 27.0147 15.7981 27.2088 15.7981H28.7617C28.9558 15.7981 29.1176 15.6377 29.1176 15.4452V14.9641C29.1176 14.2905 29.5058 13.7132 30.1206 13.4565L31.3823 12.9433V15.4773C31.3823 15.6698 31.5441 15.8301 31.7382 15.8301H32.9353C33.1294 15.8301 33.2911 15.6698 33.2911 15.4773V9.76787C33.3235 9.57542 33.1617 9.41504 32.9676 9.41504ZM31.3823 11.7565C31.3823 11.8848 31.2853 11.9811 31.1558 11.9811H30.8C30.0558 11.9811 29.4735 11.3716 29.4735 10.666V10.3132C29.4735 10.1848 29.5706 10.0886 29.7 10.0886H31.1882C31.3176 10.0886 31.4147 10.1848 31.4147 10.3132V11.7565H31.3823Z"
      fill={primaryColor}
    />
    <path
      d="M32.3529 20.9625H11.647C10.9353 20.9625 10.3529 20.3852 10.3529 19.6795C10.3529 18.9738 10.9353 18.3965 11.647 18.3965H32.3529C33.0647 18.3965 33.647 18.9738 33.647 19.6795C33.647 20.3852 33.0647 20.9625 32.3529 20.9625Z"
      fill={primaryColor}
    />
    <path
      d="M33 24.1697H11C10.6441 24.1697 10.3529 23.8811 10.3529 23.5282C10.3529 23.1754 10.6441 22.8867 11 22.8867H33C33.3558 22.8867 33.647 23.1754 33.647 23.5282C33.647 23.8811 33.3558 24.1697 33 24.1697Z"
      fill={secondaryColor}
    />
    <path
      d="M33 26.7361H11C10.6441 26.7361 10.3529 26.4475 10.3529 26.0946C10.3529 25.7418 10.6441 25.4531 11 25.4531H33C33.3558 25.4531 33.647 25.7418 33.647 26.0946C33.647 26.4475 33.3558 26.7361 33 26.7361Z"
      fill={secondaryColor}
    />
  </svg>
);

export default RussianLanguageIcon;
