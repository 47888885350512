import Plyr, { Options, PlyrEvent, PlyrEventMap, SourceInfo } from 'plyr';
import { useEffect, useRef } from 'react';

import { getPlyrOptions } from './utils';

export type PlyrPlayerProps = {
  source: SourceInfo;
  options?: Options;
  listeners?: {
    [K in keyof PlyrEventMap]?: ((evt: PlyrEvent) => void) | [(evt: PlyrEvent) => void, boolean];
  };
};

export const PlyrPlayer = ({ source, options, listeners }: PlyrPlayerProps) => {
  const mediaRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const mediaElement = mediaRef.current;

    if (!mediaElement) {
      return;
    }

    const player = new Plyr(mediaElement, getPlyrOptions(source, options));

    player.source = source;

    if (!!listeners) {
      for (const [event, listener] of Object.entries(listeners)) {
        const [callback, once] = Array.isArray(listener) ? listener : [listener, false];

        player[once ? 'once' : 'on'](event as keyof PlyrEventMap, callback);
      }
    }
  }, [mediaRef, source, options, listeners]);

  return (
    <div className="plyr-wrapper">
      <video ref={mediaRef} />
    </div>
  );
};
