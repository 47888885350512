import { Icon } from './Icon';

export const InfoIcon: Icon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0013 1.99984C4.68759 1.99984 2.0013 4.68613 2.0013 7.99984C2.0013 11.3135 4.68759 13.9998 8.0013 13.9998C11.315 13.9998 14.0013 11.3135 14.0013 7.99984C14.0013 4.68613 11.315 1.99984 8.0013 1.99984ZM0.667969 7.99984C0.667969 3.94975 3.95121 0.666504 8.0013 0.666504C12.0514 0.666504 15.3346 3.94975 15.3346 7.99984C15.3346 12.0499 12.0514 15.3332 8.0013 15.3332C3.95121 15.3332 0.667969 12.0499 0.667969 7.99984Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33203 4.66667C7.33203 4.29848 7.62902 4 7.99538 4H8.00201C8.36837 4 8.66536 4.29848 8.66536 4.66667C8.66536 5.03486 8.36837 5.33333 8.00201 5.33333H7.99538C7.62902 5.33333 7.33203 5.03486 7.33203 4.66667Z"
    />
    <rect x="7.33203" y="6.6665" width="1.33333" height="5.33333" rx="0.666667" />
  </svg>
);
