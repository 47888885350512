import { ResizerEvents } from 'iframe-resizer-react';
import { EomComponentProps } from './EomComponentProps';
import { IFrame } from '../../IFrame';

export const EomIFrame = ({ src, task, onMessage }: EomComponentProps) => {
  const handleMessage: ResizerEvents['onMessage'] = ({ message }) => {
    onMessage?.({ eomId: task.id, message });
  };

  if (!src) {
    return null;
  }

  return <IFrame src={src} scrolling={true} sizeHeight={false} className="flex-grow-1" onMessage={handleMessage} />;
};
