import classNames from 'classnames';
import { FC, useCallback } from 'react';
import { useCollapsibleItem } from './CollapsibleItemProvider';

type CollapsibleHeaderProps = {
  className?: string;
  tabIndex?: number;
};

export const CollapsibleHeader: FC<CollapsibleHeaderProps> = ({ children, className, tabIndex }) => {
  const { collapsed, toggle } = useCollapsibleItem();

  const handleToggle = useCallback(() => toggle(), [toggle]);

  return (
    <h2 className={classNames('accordion-header', className)}>
      <button
        type="button"
        aria-expanded={!collapsed}
        tabIndex={tabIndex}
        className={classNames('accordion-button', { collapsed })}
        onClick={handleToggle}
      >
        {children}
      </button>
    </h2>
  );
};
