import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Biology];

const BiologyIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M37.4959 27.4472C37.1057 27.0894 37.2683 26.4065 37.7886 26.3089C43.6423 24.9106 48 19.6423 48 13.3333C48 5.98374 42.0163 0 34.6667 0L32 0.0325203C31.5122 0.0325203 31.0894 0.455285 31.0894 0.97561V1.95122L23.7724 0.325203C23.0244 0.162602 22.3089 0.747967 22.3089 1.49593V1.95122L10.5691 0.357724C9.26829 0.195122 8.1626 1.30081 8.3252 2.60163L9.95122 14.3089H9.49593C8.71545 14.3089 8.1626 15.0244 8.3252 15.7724L9.95122 23.0894H8.97561C8.45528 23.0894 8.06504 23.5122 8.03252 24L8 26.6667C8 34.0163 13.9837 40 21.3333 40C27.6098 40 32.878 35.6423 34.3089 29.7886C34.439 29.2683 35.0894 29.1057 35.4472 29.4959L42.6341 37.4959C42.8943 37.7886 43.3821 37.8211 43.6748 37.5285L45.5285 35.6748C45.8211 35.3821 45.7886 34.9268 45.4959 34.6341L37.4959 27.4472Z"
      fill={tertiaryColor}
    />
    <path
      d="M22.1936 33.106C21.871 33.106 21.5484 32.9759 21.3226 32.7482C20.8387 32.2604 20.8387 31.48 21.3226 30.9921L23.5807 28.7157C23.7097 28.5856 23.9033 28.5856 24.0323 28.7157L25.3226 30.0165C25.4517 30.1466 25.4517 30.3417 25.3226 30.4718L23.0646 32.7482C22.8387 33.0084 22.5162 33.106 22.1936 33.106Z"
      fill={secondaryColor}
    />
    <path
      d="M13.6452 24.4878C13.3227 24.4878 13.0001 24.3577 12.7743 24.1301C12.2904 23.6423 12.2904 22.8618 12.7743 22.374L15.0323 20.0976C15.1614 19.9675 15.3549 19.9675 15.484 20.0976L16.7743 21.3984C16.9033 21.5285 16.9033 21.7236 16.7743 21.8537L14.5162 24.1301C14.2904 24.3902 13.9678 24.4878 13.6452 24.4878Z"
      fill={secondaryColor}
    />
    <path
      d="M44.1291 19.2198C43.871 19.2198 43.6129 19.1223 43.4194 18.9271L41.5484 17.041C40.9033 17.5938 40.0968 17.8865 39.2258 17.8865H36.7097C36.6129 17.8865 36.5162 17.8215 36.4516 17.7564C36.3871 17.6589 36.3871 17.5613 36.4194 17.4637L37.3871 15.1873C37.4516 15.0572 37.5484 14.9922 37.6774 14.9922H41.1613C41.9355 14.9922 42.6452 15.2849 43.1613 15.8377L44.8387 17.5288C45.2258 17.919 45.2258 18.5694 44.8387 18.9597C44.6452 19.1223 44.3871 19.2198 44.1291 19.2198Z"
      fill={secondaryColor}
    />
    <path
      d="M40.4193 22.9591C40.1613 22.9591 39.9032 22.8615 39.7097 22.6664L37.8387 20.7802C37.1935 21.3331 36.3871 21.6258 35.5161 21.6258H33C32.9032 21.6258 32.8064 21.5607 32.7419 21.4957C32.6774 21.3981 32.6774 21.3006 32.7097 21.203L33.6774 18.9266C33.7419 18.7965 33.8387 18.7314 33.9677 18.7314H37.4516C38.2258 18.7314 38.9355 19.0241 39.4516 19.577L41.129 21.268C41.5161 21.6583 41.5161 22.3087 41.129 22.6989C40.9355 22.8615 40.6774 22.9591 40.4193 22.9591Z"
      fill={secondaryColor}
    />
    <path
      d="M27.8388 8.94289C27.7742 8.94289 27.7097 8.91037 27.6452 8.87785C27.5484 8.81281 27.5162 8.71525 27.5162 8.61769V6.0811C27.5162 5.20305 27.8065 4.39004 28.3549 3.73964L26.4839 1.85346C26.0968 1.46322 26.0968 0.81281 26.4839 0.422566C26.871 0.0323218 27.5162 0.0323218 27.9033 0.422566L29.5807 2.11362C30.1291 2.66647 30.4194 3.38191 30.4194 4.12988V7.64208C30.4194 7.77216 30.3549 7.90224 30.2259 7.93476L27.9678 8.91037C27.9355 8.94289 27.871 8.94289 27.8388 8.94289Z"
      fill={secondaryColor}
    />
    <path
      d="M24.129 12.6831C24.0645 12.6831 24 12.6506 23.9355 12.6181C23.871 12.553 23.8065 12.4555 23.8065 12.3579V9.82134C23.8065 8.94329 24.0968 8.13028 24.6452 7.47987L22.7742 5.59369C22.3871 5.20345 22.3871 4.55304 22.7742 4.1628C23.1613 3.77256 23.8065 3.77256 24.1936 4.1628L25.871 5.85386C26.4194 6.4067 26.7097 7.12215 26.7097 7.87012V11.3823C26.7097 11.5124 26.6452 11.6425 26.5161 11.675L24.2581 12.6506C24.2258 12.6831 24.1613 12.6831 24.129 12.6831Z"
      fill={secondaryColor}
    />
    <path
      d="M22.8386 30.244C20.8709 30.244 18.8709 29.496 17.3548 27.9676C14.3225 24.9106 14.3225 19.9676 17.3548 16.9432C20.4193 13.8537 28.9354 10.2765 31.9354 13.3009C34.9354 16.2928 31.387 24.8456 28.3225 27.9676C26.8064 29.4635 24.8064 30.244 22.8386 30.244Z"
      fill={tertiaryColor}
    />
    <path
      d="M22.8387 30.2442C20.8064 30.2442 18.8387 29.4637 17.3548 27.9678C15.1935 25.7889 14.5161 22.5369 15.6129 19.6751C15.6451 19.5775 15.7419 19.48 15.8387 19.48C15.9355 19.4474 16.0645 19.48 16.129 19.5775L25.5806 29.2686C25.6451 29.3336 25.6774 29.4637 25.6774 29.5613C25.6774 29.6588 25.5806 29.7564 25.4838 29.7889C24.6129 30.0816 23.7419 30.2442 22.8387 30.2442Z"
      fill={primaryColor}
    />
    <path
      d="M28.9032 27.1223C28.8064 27.1223 28.7419 27.0897 28.6774 27.0247L18.3871 16.4881C18.3225 16.4231 18.2903 16.3255 18.2903 16.2279C18.2903 16.1304 18.3548 16.0653 18.4193 16.0003C19.5161 15.1548 20.9032 14.3418 22.3871 13.6588C22.5161 13.5938 22.6451 13.6263 22.7419 13.7239L31.4193 22.6344C31.5161 22.732 31.5483 22.8621 31.4838 22.9922C30.8064 24.4881 29.9677 25.919 29.129 26.9922C29.0967 27.0572 29 27.1223 28.9032 27.1223Z"
      fill={primaryColor}
    />
    <path
      d="M41.5484 13.0089C41.3548 13.0089 41.2258 12.8788 41.2258 12.6837V10.9276C41.2258 10.2772 40.7097 9.75685 40.0645 9.75685H38.9677C38.7742 9.75685 38.6451 9.62677 38.6451 9.43165C38.6451 9.23653 38.7742 9.10645 38.9677 9.10645H40.0645C41.0645 9.10645 41.8709 9.91945 41.8709 10.9276V12.6837C41.8709 12.8788 41.7419 13.0089 41.5484 13.0089Z"
      fill={primaryColor}
    />
    <path
      d="M35.7419 6.50399C35.5483 6.50399 35.4193 6.3739 35.4193 6.17878V5.07309C35.4193 4.42268 34.9032 3.90236 34.258 3.90236H32.5161C32.3225 3.90236 32.1935 3.77228 32.1935 3.57716C32.1935 3.38203 32.3225 3.25195 32.5161 3.25195H34.258C35.258 3.25195 36.0644 4.06496 36.0644 5.07309V6.17878C36.0644 6.3739 35.9354 6.50399 35.7419 6.50399Z"
      fill={primaryColor}
    />
    <path
      d="M13.1613 21.4636C13.129 21.4636 13.129 21.4636 13.0968 21.4636C13.0645 21.4636 13.0323 21.4636 13 21.4636C10.2581 21.4636 8 19.1872 8 16.4229C8 13.7237 10.0645 11.5124 12.7419 11.3823C12.7742 11.3823 12.8065 11.3823 12.8387 11.3823C17.4839 11.3823 23.2581 10.114 27.4839 8.13025L29.4839 7.18716C29.6129 7.12212 29.7742 7.15464 29.871 7.2522C29.9677 7.34976 29.9677 7.51236 29.9032 7.64244L29.871 7.67496C29.8387 7.74 29.8064 7.77252 29.7419 7.80504C29.2258 8.06521 28.7742 8.42293 28.3548 8.81317C27.3226 9.85383 26.7419 11.2197 26.7097 12.6831C26.7097 12.7807 26.6774 12.8457 26.6129 12.9107L21.6452 17.9189C19.3871 20.1953 16.3548 21.4636 13.1613 21.4636Z"
      fill={quaternaryColor}
    />
    <path
      d="M38.1936 11.8698C38.1613 11.8698 38.1291 11.8698 38.1291 11.8698C38 11.8373 37.9355 11.7397 37.9033 11.6096C37.6774 10.5364 37.1613 9.56083 36.3871 8.81287C35.6129 8.03238 34.6775 7.51205 33.6129 7.28441C33.4839 7.25189 33.3871 7.15433 33.3549 7.05677C33.3226 6.92669 33.3549 6.79661 33.4516 6.73156C34.0968 6.17872 34.9355 5.85352 35.7742 5.85352C37.742 5.85352 39.3226 7.44701 39.3226 9.43075C39.3226 10.2763 39 11.1218 38.4516 11.7722C38.3549 11.8373 38.2581 11.8698 38.1936 11.8698Z"
      fill={secondaryColor}
    />
    <path
      d="M28.8065 37.3987C26.1291 37.3987 23.9356 35.2849 23.8065 32.6182C23.8065 32.5857 23.8065 32.5532 23.8065 32.5207V32.4231C23.8065 32.4231 23.8065 32.3906 23.8065 32.3581C23.8065 32.3255 23.8065 32.293 23.8065 32.228V32.1629C23.9033 28.8459 25.2259 25.7239 27.5485 23.3825L32.2904 18.602C32.3549 18.5369 32.4194 18.5044 32.5162 18.5044C33.9678 18.4719 35.3226 17.8865 36.3549 16.8459C36.742 16.4556 37.0646 16.0329 37.3226 15.5451C37.4194 15.3825 37.5807 15.3174 37.742 15.415C37.9033 15.48 37.9678 15.6751 37.9033 15.8377L37.0323 17.7239C34.9033 22.3743 33.8065 27.4475 33.8065 32.5207C33.8065 32.5532 33.8065 32.5857 33.8065 32.6182C33.6775 35.2849 31.4839 37.3987 28.8065 37.3987Z"
      fill={quaternaryColor}
    />
    <path
      d="M32.3548 19.1868C30.7419 19.1868 29.129 18.5689 27.9032 17.3332C25.4515 14.8616 25.4515 10.8291 27.9032 8.35756C30.3548 5.88602 34.3548 5.88602 36.8064 8.35756C37.9999 9.56082 38.6451 11.1543 38.6451 12.8454C38.6451 14.5364 37.9999 16.1299 36.8064 17.3332C35.5806 18.5689 33.9677 19.1868 32.3548 19.1868Z"
      fill={primaryColor}
    />
  </svg>
);

export default BiologyIcon;
