import { LessonStudentPlannedResults } from './LessonStudentPlannedResults';
import { useLesson } from '../../../providers';

export const LessonPlannedResults = () => {
  const { role } = useLesson();

  switch (role) {
    case 'student':
      return <LessonStudentPlannedResults />;
    default:
      return null;
  }
};
