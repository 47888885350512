import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Biology];

const BiologyIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="0 0 240 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1777_14619)">
      <path
        d="M157.239 148.278C155.613 146.776 156.29 143.907 158.458 143.498C182.845 137.624 201 115.498 201 89C201 58.1317 176.071 33 145.452 33L134.342 33.1366C132.31 33.1366 130.548 34.9122 130.548 37.0976V41.1951L100.065 34.3659C96.9484 33.6829 93.9678 36.1415 93.9678 39.2829V41.1951L45.0581 34.5024C39.6387 33.8195 35.0323 38.4634 35.7097 43.9268L42.4839 93.0976H40.5871C37.3355 93.0976 35.0323 96.1024 35.7097 99.2439L42.4839 129.976H38.4194C36.2516 129.976 34.6258 131.751 34.4903 133.8L34.3549 145C34.3549 175.868 59.2839 201 89.9032 201C116.052 201 138 182.698 143.961 158.112C144.503 155.927 147.213 155.244 148.703 156.883L178.645 190.483C179.729 191.712 181.761 191.849 182.981 190.62L190.703 182.834C191.923 181.605 191.787 179.693 190.568 178.463L157.239 148.278Z"
        fill={tertiaryColor}
      />
      <path
        d="M92.6129 172.044C91.2581 172.044 89.9032 171.498 88.9549 170.542C86.9226 168.493 86.9226 165.215 88.9549 163.166L98.4387 153.605C98.9807 153.059 99.7936 153.059 100.335 153.605L105.755 159.068C106.297 159.615 106.297 160.434 105.755 160.981L96.271 170.542C95.3226 171.634 93.9678 172.044 92.6129 172.044Z"
        fill={secondaryColor}
      />
      <path
        d="M56.7097 135.849C55.3549 135.849 54 135.302 53.0517 134.346C51.0194 132.298 51.0194 129.02 53.0517 126.971L62.5355 117.41C63.0775 116.863 63.8904 116.863 64.4323 117.41L69.8517 122.873C70.3936 123.42 70.3936 124.239 69.8517 124.785L60.3678 134.346C59.4194 135.439 58.0646 135.849 56.7097 135.849Z"
        fill={secondaryColor}
      />
      <path
        d="M184.742 113.722C183.658 113.722 182.574 113.312 181.761 112.493L173.903 104.571C171.194 106.893 167.807 108.122 164.148 108.122H153.581C153.174 108.122 152.768 107.849 152.497 107.576C152.226 107.166 152.226 106.756 152.361 106.346L156.426 96.7853C156.697 96.239 157.103 95.9658 157.645 95.9658H172.277C175.529 95.9658 178.51 97.1951 180.677 99.517L187.723 106.619C189.348 108.259 189.348 110.99 187.723 112.629C186.91 113.312 185.826 113.722 184.742 113.722Z"
        fill={secondaryColor}
      />
      <path
        d="M169.161 129.429C168.077 129.429 166.994 129.02 166.181 128.2L158.323 120.278C155.613 122.6 152.226 123.829 148.568 123.829H138C137.594 123.829 137.187 123.556 136.916 123.283C136.645 122.873 136.645 122.464 136.781 122.054L140.845 112.493C141.116 111.947 141.523 111.673 142.065 111.673H156.697C159.948 111.673 162.929 112.903 165.097 115.225L172.142 122.327C173.768 123.966 173.768 126.698 172.142 128.337C171.329 129.02 170.245 129.429 169.161 129.429Z"
        fill={secondaryColor}
      />
      <path
        d="M116.323 70.561C116.052 70.561 115.781 70.4244 115.51 70.2878C115.103 70.0147 114.968 69.6049 114.968 69.1952V58.5415C114.968 54.8537 116.187 51.4391 118.49 48.7074L110.632 40.7854C109.006 39.1464 109.006 36.4147 110.632 34.7757C112.258 33.1366 114.968 33.1366 116.593 34.7757L123.639 41.8781C125.942 44.2 127.161 47.2049 127.161 50.3464V65.0976C127.161 65.6439 126.89 66.1903 126.348 66.3269L116.864 70.4244C116.729 70.561 116.458 70.561 116.323 70.561Z"
        fill={secondaryColor}
      />
      <path
        d="M100.742 86.2685C100.471 86.2685 100.2 86.1319 99.9291 85.9954C99.6581 85.7222 99.3871 85.3124 99.3871 84.9027V74.249C99.3871 70.5612 100.606 67.1466 102.91 64.4149L95.0516 56.4929C93.4258 54.8539 93.4258 52.1222 95.0516 50.4832C96.6774 48.8442 99.3871 48.8442 101.013 50.4832L108.058 57.5856C110.361 59.9076 111.581 62.9124 111.581 66.0539V80.8051C111.581 81.3515 111.31 81.8978 110.768 82.0344L101.284 86.1319C101.148 86.2685 100.877 86.2685 100.742 86.2685Z"
        fill={secondaryColor}
      />
      <path
        d="M95.3225 160.024C87.058 160.024 78.658 156.883 72.2903 150.463C59.5548 137.624 59.5548 116.863 72.2903 104.161C85.1612 91.1853 120.929 76.1609 133.529 88.8633C146.129 101.429 131.226 137.351 118.355 150.463C111.987 156.746 103.587 160.024 95.3225 160.024Z"
        fill={tertiaryColor}
      />
      <path
        d="M95.3226 160.025C86.7871 160.025 78.5226 156.747 72.2903 150.464C63.2129 141.312 60.3677 127.654 64.9742 115.634C65.1097 115.225 65.5161 114.815 65.9226 114.815C66.329 114.678 66.871 114.815 67.1419 115.225L106.839 155.927C107.11 156.2 107.245 156.747 107.245 157.156C107.245 157.566 106.839 157.976 106.432 158.112C102.774 159.342 99.1161 160.025 95.3226 160.025Z"
        fill={primaryColor}
      />
      <path
        d="M120.794 146.912C120.387 146.912 120.116 146.776 119.845 146.502L76.6258 102.249C76.3548 101.976 76.2194 101.566 76.2194 101.156C76.2194 100.746 76.4903 100.473 76.7613 100.2C81.3677 96.6487 87.1936 93.234 93.4258 90.3658C93.9677 90.0926 94.5097 90.2292 94.9161 90.6389L131.361 128.063C131.768 128.473 131.903 129.019 131.632 129.566C128.787 135.849 125.265 141.858 121.742 146.366C121.606 146.639 121.2 146.912 120.794 146.912Z"
        fill={primaryColor}
      />
      <path
        d="M173.903 87.6344C173.09 87.6344 172.548 87.088 172.548 86.2685V78.8929C172.548 76.1612 170.381 73.9758 167.671 73.9758H163.065C162.252 73.9758 161.71 73.4295 161.71 72.61C161.71 71.7905 162.252 71.2441 163.065 71.2441H167.671C171.871 71.2441 175.258 74.6588 175.258 78.8929V86.2685C175.258 87.088 174.716 87.6344 173.903 87.6344Z"
        fill={primaryColor}
      />
      <path
        d="M149.516 60.3172C148.703 60.3172 148.161 59.7709 148.161 58.9514V54.3075C148.161 51.5758 145.994 49.3904 143.284 49.3904H135.968C135.155 49.3904 134.613 48.8441 134.613 48.0245C134.613 47.205 135.155 46.6587 135.968 46.6587H143.284C147.484 46.6587 150.871 50.0733 150.871 54.3075V58.9514C150.871 59.7709 150.329 60.3172 149.516 60.3172Z"
        fill={primaryColor}
      />
      <path
        d="M54.6774 123.147C54.5419 123.147 54.5419 123.147 54.4065 123.147C54.271 123.147 54.1355 123.147 54 123.147C42.4839 123.147 33 113.586 33 101.976C33 90.6392 41.671 81.3514 52.9161 80.8051C53.0516 80.8051 53.1871 80.8051 53.3226 80.8051C72.8323 80.8051 97.0839 75.4783 114.832 67.1466L123.232 63.1856C123.774 62.9124 124.452 63.049 124.858 63.4587C125.265 63.8685 125.265 64.5514 124.994 65.0978L124.858 65.2344C124.723 65.5075 124.587 65.6441 124.316 65.7807C122.148 66.8734 120.252 68.3758 118.49 70.0148C114.155 74.3856 111.716 80.1222 111.581 86.2685C111.581 86.6783 111.445 86.9514 111.174 87.2246L90.3097 108.259C80.8258 117.82 68.0903 123.147 54.6774 123.147Z"
        fill={quaternaryColor}
      />
      <path
        d="M159.813 82.8537C159.677 82.8537 159.542 82.8537 159.542 82.8537C159 82.7172 158.729 82.3074 158.593 81.7611C157.645 77.2537 155.477 73.1562 152.226 70.0147C148.974 66.7367 145.045 64.5513 140.574 63.5952C140.032 63.4586 139.626 63.0489 139.49 62.6391C139.355 62.0928 139.49 61.5464 139.897 61.2733C142.606 58.9513 146.129 57.5854 149.652 57.5854C157.916 57.5854 164.555 64.2781 164.555 72.6098C164.555 76.1611 163.2 79.7123 160.897 82.444C160.49 82.7172 160.084 82.8537 159.813 82.8537Z"
        fill={secondaryColor}
      />
      <path
        d="M120.387 190.073C109.142 190.073 99.929 181.195 99.3871 169.995C99.3871 169.858 99.3871 169.722 99.3871 169.585V169.175C99.3871 169.175 99.3871 169.039 99.3871 168.902C99.3871 168.766 99.3871 168.629 99.3871 168.356V168.083C99.7935 154.151 105.348 141.039 115.103 131.205L135.019 111.127C135.29 110.854 135.561 110.717 135.968 110.717C142.065 110.58 147.755 108.122 152.09 103.751C153.716 102.112 155.071 100.336 156.155 98.2877C156.561 97.6048 157.239 97.3316 157.916 97.7414C158.594 98.0145 158.865 98.834 158.594 99.517L154.935 107.439C145.994 126.971 141.387 148.278 141.387 169.585C141.387 169.722 141.387 169.858 141.387 169.995C140.845 181.195 131.632 190.073 120.387 190.073Z"
        fill={quaternaryColor}
      />
      <path
        d="M135.29 113.586C128.516 113.586 121.742 110.991 116.594 105.8C106.297 95.4198 106.297 78.4832 116.594 68.1027C126.89 57.7223 143.69 57.7223 153.987 68.1027C159 73.1564 161.71 79.8491 161.71 86.9515C161.71 94.054 159 100.747 153.987 105.8C148.839 110.991 142.065 113.586 135.29 113.586Z"
        fill={primaryColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1777_14619"
        x="3"
        y="33"
        width="228"
        height="228"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.3125 0 0 0 0 0.03125 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1777_14619" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1777_14619" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default BiologyIcon;
