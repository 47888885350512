import {
  SubjectIcon as BaseBaseSubjectIcon,
  SubjectIconProps as BaseSubjectIconProps
} from '../assets/icons/subjects/types';
import { LessonSubjectCode } from '../constants';
import { SubjectIconsLg } from '../assets/icons/subjects/lg';
import { SubjectIconsSm } from '../assets/icons/subjects/sm';

type SubjectIconProps = BaseSubjectIconProps & {
  code?: LessonSubjectCode;
  size?: 'sm' | 'lg';
};

export const SubjectIcon = ({ code, size, ...props }: SubjectIconProps) => {
  const IconComponent = getIconComponent(code, size);

  return IconComponent ? <IconComponent {...props} /> : null;
};

const getIconComponent = (subject?: LessonSubjectCode, size: 'sm' | 'lg' = 'sm'): BaseBaseSubjectIcon | null => {
  const SubjectIcons = size === 'lg' ? SubjectIconsLg : SubjectIconsSm;

  return subject ? SubjectIcons[subject] : null;
};
