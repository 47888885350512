import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { Accordion, Collapse, ListGroup } from 'react-bootstrap';
import { MediaBreakpoint, useIsomorphicLayoutEffect, useMediaBreakpointDown } from '../../hooks';
import { useLesson } from '../../providers';
import { ArrowTwoIcon } from '../../assets/icons';
import {
  getLessonModuleName,
  isLessonModuleSolved,
  isLessonTaskSolved,
  isLessonUnitSolved
} from '../../utils/LessonUtils';
import { LessonTaskType } from '../task';

export const LessonModuleNavigation = () => {
  const isMobile = useMediaBreakpointDown(MediaBreakpoint.Sm);
  const { lesson, activeUnit, activeModule, activeTask, result, role, onUnitSelect, onModuleSelect } = useLesson();
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const sidebarButtonStyle = useMemo(
    () => (isSidebarOpen ? undefined : { transform: 'rotate(180deg)' }),
    [isSidebarOpen]
  );

  const handleSidebarToggle = useCallback(() => setSidebarOpen((isOpen) => !isOpen), []);

  useIsomorphicLayoutEffect(() => setSidebarOpen(!isMobile), [isMobile]);

  if (!activeModule) return null;

  return (
    <div className={classNames('lesson-module__navigation-wrapper', { 'is-open': isSidebarOpen })}>
      <button
        onClick={handleSidebarToggle}
        className="lesson-module__sidebar-button"
        title={`${isSidebarOpen ? 'Свернуть' : 'Развернуть'} навигацию урока`}
      >
        <ArrowTwoIcon style={sidebarButtonStyle} />
      </button>
      <Collapse in={isSidebarOpen} dimension="width" timeout={0}>
        <Accordion activeKey={activeUnit?.uid} className="lesson-details__navigation">
          {lesson?.units.map((unit) => (
            <Accordion.Item key={unit.uid} eventKey={unit.uid}>
              <Accordion.Header
                onClick={onUnitSelect.bind(null, unit)}
                className={classNames({
                  solved: isLessonUnitSolved(unit, result)
                })}
              >
                <span className="fz-md">{unit.unitType.name}</span>
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {unit.modules.map((module) => (
                    <ListGroup.Item
                      key={module.uid}
                      active={module.uid === activeModule.uid}
                      action={true}
                      className={classNames({
                        solved: isLessonModuleSolved(module, result)
                      })}
                      onClick={onModuleSelect.bind(null, module)}
                    >
                      <span className="fz-md">{getLessonModuleName(module.moduleType, role)}</span>
                      <ListGroup variant="flush">
                        {module.tasks.map((task) => (
                          <ListGroup.Item
                            key={task.uid}
                            active={task === activeTask}
                            className={classNames({
                              solved: isLessonTaskSolved(task, result)
                            })}
                          >
                            <LessonTaskType type={task.eomType} className="fz-normal" />
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Collapse>
    </div>
  );
};
