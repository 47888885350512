import classNames from 'classnames';
import { Button, ListGroup } from 'react-bootstrap';
import { LessonUnitModel } from '../../../models';
import { useLesson } from '../../../providers';
import { CollapsibleList } from '../../collapsible-list';
import { getLessonModuleName, isLessonUnitSolved } from '../../../utils/LessonUtils';
import { LessonDetailsNavigationModule } from './LessonDetailsNavigationModule';

type LessonDetailsNavigationUnitProps = {
  name: string;
  unit: LessonUnitModel;
};

export const LessonDetailsNavigationUnit = ({ name, unit }: LessonDetailsNavigationUnitProps) => {
  const { role, result } = useLesson();

  return (
    <CollapsibleList.Item>
      <CollapsibleList.Header className={classNames({ solved: isLessonUnitSolved(unit, result) })}>
        <div className="lesson-details__unit-info fw-bold fz-md">{name}</div>
      </CollapsibleList.Header>
      <CollapsibleList.Body>
        <ListGroup>
          {unit.modules?.map((module) => (
            <LessonDetailsNavigationModule
              key={module.uid}
              name={getLessonModuleName(module.moduleType, role)}
              module={module}
            />
          ))}
        </ListGroup>
      </CollapsibleList.Body>
    </CollapsibleList.Item>
  );
};
