import { createContext, FC, useContext, useMemo } from 'react';
import { useAuth } from './AuthProvider';
import { ILessonApi, ILessonTaskApi, LessonApi, LessonTaskApi } from '../../api/lesson';

type LessonApiContext = {
  lessonApi: ILessonApi;
  lessonTaskApi: ILessonTaskApi;
};

const LessonApiContext = createContext<LessonApiContext | undefined>(undefined);

export const LessonApiProvider: FC = ({ children }) => {
  const { fetcher } = useAuth();

  const contextValue = useMemo(
    () => ({
      lessonApi: new LessonApi(fetcher),
      lessonTaskApi: new LessonTaskApi(fetcher)
    }),
    [fetcher]
  );

  return <LessonApiContext.Provider value={contextValue}>{children}</LessonApiContext.Provider>;
};

export const useLessonApi = () => {
  const context = useContext(LessonApiContext);

  if (!context) {
    throw new Error('useLessonApi must be used within a LessonApiProvider.');
  }

  return context;
};
