import { useLesson } from '../../providers';
import { getLessonModuleName } from '../../utils/LessonUtils';
import { LessonModuleRecommendations } from './LessonModuleRecommendations';
import { LessonModuleTaskList } from './LessonModuleTaskList';

export const LessonModuleContent = () => {
  const { activeModule, role } = useLesson();

  if (!activeModule) {
    return null;
  }

  return (
    <div className="lesson-module__content flex-grow-1">
      <h3 className="subheader-1 subheader-md-2 mt-2 mt-lg-0 mb-0">
        {getLessonModuleName(activeModule.moduleType, role)}
      </h3>
      <LessonModuleRecommendations />
      <LessonModuleTaskList />
    </div>
  );
};
