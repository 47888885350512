import { VFC } from 'react';
import { Link } from 'react-router-dom';
import { ObjectType, TaskPlaybackMethodIcon, TaskPlaybackMethodLabel } from '@ru-edu/ecl-player';
import { LessonSearchResultModel } from '../../models';

type ListItemProps = {
  result: LessonSearchResultModel;
  baseUrl?: string;
};

export const ListItem: VFC<ListItemProps> = ({ result, baseUrl }) => {
  const href = `${baseUrl || ''}/${result.uid}`;
  const playbackMethod = result.kind === ObjectType.Lesson ? ObjectType.Lesson : result.types?.[0]?.category?.code;

  return (
    <Link to={href} className="d-flex flex-column h-100 text-decoration-none text-primary card mb-4">
      <div className="d-flex align-items-center mb-3 text-md">
        <TaskPlaybackMethodIcon code={playbackMethod} className="me-4" />
        <TaskPlaybackMethodLabel code={playbackMethod} />
      </div>
      <div className="d-flex flex-column flex-grow-1 mb-2">
        <span className="text-lines-2" title={result.name}>
          {result.name}
        </span>
      </div>
      <div className="d-flex flex-wrap">
        {result.subject && <div className="badge me-1 mb-1">{result.subject.name}</div>}
        {result.class && <div className="badge me-1 mb-1">{result.class} класс</div>}
      </div>
      <div className="text-sm text-muted mt-1">{result.author}</div>
    </Link>
  );
};
