import { Modal as ModalComponent, ModalProps } from 'react-bootstrap';
import { useEffect, useRef } from 'react';

export const Modal = ({
  children,
  ...props
}: Omit<ModalProps, 'autoFocus' | 'enforceFocus' | 'restoreFocus' | 'restoreFocusOptions'>) => {
  const container = useRef<HTMLElement | null>(null);

  useEffect(() => {
    container.current = document.querySelector('.ecl-container');

    return () => {
      container.current = null;
    };
  }, []);

  return (
    <ModalComponent {...props} container={container}>
      {children}
    </ModalComponent>
  );
};

Modal.Body = ModalComponent.Body;
Modal.Header = Object.assign(ModalComponent.Header, {
  ...ModalComponent.Header,
  defaultProps: {
    ...ModalComponent.Header?.defaultProps,
    closeLabel: 'Закрыть'
  }
});
Modal.Title = ModalComponent.Title;
Modal.Footer = ModalComponent.Footer;
Modal.Dialog = ModalComponent.Dialog;
Modal.TRANSITION_DURATION = ModalComponent.TRANSITION_DURATION;
Modal.BACKDROP_TRANSITION_DURATION = ModalComponent.BACKDROP_TRANSITION_DURATION;
