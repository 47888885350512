import { Icon } from '../Icon';

export const TypeGalleryIcon: Icon = (props) => (
  <svg width="24" height="24" viewBox="4 4 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="5" y="5" width="10" height="10" rx="3" fill="#3E2476" />
    <rect x="17" y="5" width="10" height="10" rx="3" fill="#C5B3ED" />
    <rect x="5" y="17" width="10" height="10" rx="3" fill="#C5B3ED" />
    <rect x="17" y="17" width="10" height="10" rx="3" fill="#C5B3ED" />
  </svg>
);
