import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Physics];

const PhysicsIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="0 0 240 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1761_10315)">
      <path
        d="M135 185C179.735 185 216 148.735 216 104C216 59.2649 179.735 23 135 23C90.2649 23 54 59.2649 54 104C54 148.735 90.2649 185 135 185Z"
        fill={tertiaryColor}
      />
      <path
        d="M103 198C137.242 198 165 170.242 165 136C165 101.758 137.242 74 103 74C68.7583 74 41 101.758 41 136C41 170.242 68.7583 198 103 198Z"
        fill={tertiaryColor}
      />
      <path
        d="M109 104C126.673 104 141 89.6731 141 72C141 54.3269 126.673 40 109 40C91.3269 40 77 54.3269 77 72C77 89.6731 91.3269 104 109 104Z"
        fill={quaternaryColor}
      />
      <path
        d="M165 158C182.673 158 197 143.897 197 126.5C197 109.103 182.673 95 165 95C147.327 95 133 109.103 133 126.5C133 143.897 147.327 158 165 158Z"
        fill={quaternaryColor}
      />
      <path
        d="M162.548 134.052C164.648 136.161 164.648 139.776 162.548 141.884L133.446 170.952C132.696 171.705 131.946 172.308 131.196 173.061C112.744 189.477 84.3923 188.724 66.691 170.952C48.9897 153.18 48.2397 124.714 64.5908 106.039C65.1909 105.285 65.9409 104.532 66.691 103.779L95.6431 74.7113C97.7432 72.6027 101.343 72.6027 103.444 74.7113L117.845 89.17C119.945 91.2786 119.945 94.8933 117.845 97.0018L91.5928 123.509C89.9427 125.166 88.5926 127.275 87.8425 129.383C85.8924 134.956 87.0925 141.432 91.5928 145.8C96.0931 150.318 102.394 151.523 107.944 149.565C110.044 148.812 112.144 147.607 113.794 145.8L140.196 119.443C142.296 117.334 145.897 117.334 147.997 119.443L162.548 134.052Z"
        fill={secondaryColor}
      />
      <path
        d="M109.12 106.203L86.7683 83.7619L95.619 74.8759C97.7191 72.7673 101.319 72.7673 103.42 74.8759L117.821 89.3346C119.921 91.4431 119.921 95.0578 117.821 97.1664L109.12 106.203Z"
        fill={primaryColor}
      />
      <path
        d="M153.673 150.935L131.321 128.494L140.172 119.608C142.272 117.499 145.872 117.499 147.973 119.608L162.374 134.067C164.474 136.175 164.474 139.79 162.374 141.898L153.673 150.935Z"
        fill={primaryColor}
      />
      <path
        d="M177.899 109.383L167.919 113.766C165.892 114.549 163.709 113.766 162.773 111.731C161.993 109.696 162.773 107.504 164.8 106.565L174.78 102.181C176.807 101.398 178.99 102.181 179.926 104.216C180.705 106.251 179.926 108.6 177.899 109.383Z"
        fill={primaryColor}
      />
      <path
        d="M175.248 119.09L165.268 123.473C163.241 124.256 161.058 123.473 160.123 121.438C159.343 119.403 160.123 117.211 162.15 116.272L172.13 111.888C174.157 111.105 176.34 111.888 177.275 113.923C178.211 115.959 177.275 118.15 175.248 119.09Z"
        fill={primaryColor}
      />
      <path
        d="M131.355 65.3123L121.375 69.696C119.348 70.4788 117.165 69.696 116.229 67.6607C115.45 65.6255 116.229 63.4336 118.256 62.4943L128.236 58.1106C130.263 57.3279 132.446 58.1106 133.382 60.1459C134.162 62.1812 133.382 64.5296 131.355 65.3123Z"
        fill={primaryColor}
      />
      <path
        d="M128.704 75.0228L118.724 79.4064C116.697 80.1892 114.514 79.4064 113.578 77.3712C112.799 75.3359 113.578 73.1441 115.605 72.2047L125.585 67.8211C127.612 67.0383 129.795 67.8211 130.731 69.8564C131.667 71.8916 130.731 74.0834 128.704 75.0228Z"
        fill={primaryColor}
      />
      <path
        d="M152.014 62.7296C155.028 62.7296 157.472 60.2763 157.472 57.2501C157.472 54.2238 155.028 51.7705 152.014 51.7705C149 51.7705 146.556 54.2238 146.556 57.2501C146.556 60.2763 149 62.7296 152.014 62.7296Z"
        fill={primaryColor}
      />
      <path
        d="M171.506 59.5953C173.228 59.5953 174.624 58.1935 174.624 56.4642C174.624 54.7349 173.228 53.333 171.506 53.333C169.783 53.333 168.387 54.7349 168.387 56.4642C168.387 58.1935 169.783 59.5953 171.506 59.5953Z"
        fill={primaryColor}
      />
      <path
        d="M171.506 86.2125C175.812 86.2125 179.303 82.7078 179.303 78.3846C179.303 74.0613 175.812 70.5566 171.506 70.5566C167.2 70.5566 163.709 74.0613 163.709 78.3846C163.709 82.7078 167.2 86.2125 171.506 86.2125Z"
        fill={primaryColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1761_10315"
        x="11"
        y="23"
        width="235"
        height="235"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.3125 0 0 0 0 0.03125 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1761_10315" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1761_10315" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default PhysicsIcon;
