import { BaseApi, Fetcher } from '@ru-edu/ecl-player';
import { AuthCredentialsModel, AuthTokenRequest, AuthTokenResponse } from './models';
import { UserModel } from '../../models';

export interface IAuthApi {
  login(credentials: AuthCredentialsModel): Promise<{ code: string }>;
  authenticate(provider: string, code: string, redirectUrl?: string): Promise<string>;
  getProfile(): Promise<UserModel | undefined>;
}

export class AuthApi extends BaseApi implements IAuthApi {
  constructor(fetcher: Fetcher) {
    super(fetcher, '/api/auth');
  }

  login = (credentials: AuthCredentialsModel) => {
    return this.post<{ code: string }, AuthCredentialsModel>('/login', credentials);
  };

  public authenticate = async (provider: string, code: string, redirectUrl?: string) => {
    const response = await this.post<AuthTokenResponse, AuthTokenRequest>('/oauth2', { provider, code, redirectUrl });
    const { access_token: accessToken } = response;

    return accessToken;
  };

  getProfile() {
    return this.get<UserModel>('/profile');
  }
}
