import { FormEvent, useCallback, useState } from 'react';
import { useAuth } from '../providers';
import { AuthCredentialsModel } from '../../api/auth';

export const LoginForm = () => {
  const { error, loading, login } = useAuth();
  const [credentials, setCredentials] = useState<AuthCredentialsModel>({ username: '', password: '' });

  const handleInputChange = useCallback((evt: FormEvent<HTMLInputElement>) => {
    const { name, value } = evt.currentTarget;

    setCredentials((credentials) => ({ ...credentials, [name]: value }));
  }, []);

  const handleLoginClick = useCallback(
    (evt: FormEvent<HTMLFormElement>) => {
      evt.preventDefault();
      login(credentials);
    },
    [credentials, login]
  );

  return (
    <div className="example-inner">
      <div className="d-flex align-items-center justify-content-center h-100">
        <form className="d-flex flex-column" onSubmit={handleLoginClick}>
          <input
            name="username"
            placeholder="Имя пользователя"
            className="form-control mb-3"
            disabled={loading}
            onInput={handleInputChange}
          />

          <input
            name="password"
            type="password"
            placeholder="Пароль"
            className="form-control mb-3"
            disabled={loading}
            onInput={handleInputChange}
          />

          <button type="submit" className="btn" disabled={loading}>
            Войти
          </button>

          {!!error && <div className="mt-4 text-danger">Не удалось авторизоваться.</div>}
        </form>
      </div>
    </div>
  );
};
