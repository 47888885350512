import { useCallback, VFC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LessonPlayer } from '@ru-edu/ecl-player';
import { LoadingIndicator } from '../LoadingIndicator';
import { Error404 } from '../error';
import { useAuth, useLessonApi } from '../providers';
import { useRequest } from '../../hooks';
import { UserRole } from '../../constants';

type LessonDetailsParams = {
  uid: string;
};

export const LessonDetails: VFC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { uid } = useParams<LessonDetailsParams>();
  const { lessonApi } = useLessonApi();
  const { result: lesson, loading } = useRequest(() => (uid ? lessonApi.getOne(uid) : undefined), [lessonApi, uid]);

  const handleBackClick = useCallback(() => navigate('/lessons'), [navigate]);

  if (loading) return <LoadingIndicator />;
  if (!lesson) return <Error404 />;

  const userIsTeacher = user?.roles.includes(UserRole.Teacher);

  return <LessonPlayer role={userIsTeacher ? 'teacher' : 'student'} lesson={lesson} onBackClick={handleBackClick} />;
};
