import { EomComponentProps } from './EomComponentProps';
import { EomMessageData } from './EomEvent';
import { WebComponent } from '../../WebComponent';

export const EomWebComponent = ({ src, task, onMessage }: EomComponentProps) => {
  const handleMessage = (evt: Event) => {
    const message = (evt as CustomEvent<EomMessageData>).detail;

    onMessage?.({ eomId: task.id, message });
  };

  if (!src) {
    return null;
  }

  return <WebComponent url={src} onMessage={handleMessage} />;
};
