import { useLesson } from '../../../providers';

export const LessonStudentPlannedResults = () => {
  const { lesson } = useLesson();

  if (!lesson) {
    return null;
  }

  const willKnow = (lesson.youWillKnow || []).filter((line) => !!line);
  const hasWillKnow = willKnow.length > 0;

  const willLearn = (lesson.youWillLearn || []).filter((line) => !!line);
  const hasWillLearn = willLearn.length > 0;

  if (!hasWillKnow && !hasWillLearn) {
    return null;
  }

  return (
    <section className="lesson-details__planned-results mt-45 pt-2">
      <h2 className="h1 mb-0">Планируемые результаты</h2>
      {hasWillKnow && (
        <>
          <h3 className="subheader-2 mb-2 mt-45 fz-md">Ты узнаешь</h3>
          {lesson.youWillKnow?.map((line, idx) => (
            <p key={idx} className="planned-result subheader-3 fw-normal fz-normal">
              {line}
            </p>
          ))}
        </>
      )}
      {hasWillLearn && (
        <>
          <h3 className="subheader-2 mb-2 mt-45 fz-md">Ты научишься</h3>
          {lesson.youWillLearn?.map((line, idx) => (
            <p key={idx} className="planned-result subheader-3 fw-normal fz-normal">
              {line}
            </p>
          ))}
        </>
      )}
    </section>
  );
};
