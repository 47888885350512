import classNames from 'classnames';
import { ListGroup } from 'react-bootstrap';
import { useLesson } from '../../../providers';
import { isLessonModuleSolved } from '../../../utils/LessonUtils';
import { LessonDetailsNavigationTask } from './LessonDetailsNavigationTask';
import { LessonModuleModel } from '../../../models';

type LessonDetailsNavigationModuleProps = {
  name: string;
  module: LessonModuleModel;
};

export const LessonDetailsNavigationModule = ({ name, module }: LessonDetailsNavigationModuleProps) => {
  const { result } = useLesson();

  return (
    <ListGroup.Item className={classNames({ solved: isLessonModuleSolved(module, result) })}>
      <div className="lesson-details__module-info">
        <span className="fz-md">{name}</span>
        {module.tasks.length > 0 && (
          <ListGroup variant="flush">
            {module.tasks.map((task) => (
              <LessonDetailsNavigationTask key={task.uid} task={task} />
            ))}
          </ListGroup>
        )}
      </div>
    </ListGroup.Item>
  );
};
