import { PersonModel } from '../../models';

type UserNameProps = {
  user?: PersonModel;
  size?: 'sm' | 'md';
  className?: string;
};

export const UserName = ({ user, size, className }: UserNameProps) => {
  const { firstName, lastName, middleName } = user || {};
  const firstNameStr = size === 'sm' ? getFirstLetter(firstName) : firstName;
  const middleNameStr = size === 'sm' ? getFirstLetter(middleName) : size === 'md' ? '' : middleName;
  const fullName = [lastName, firstNameStr, middleNameStr].filter(Boolean).join(' ');

  if (fullName === '') {
    return null;
  }

  return <div className={className}>{fullName}</div>;
};

const getFirstLetter = (part?: string) => {
  if (!part) {
    return undefined;
  }

  return `${part.charAt(0).toUpperCase()}.`;
};
