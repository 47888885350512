import { Badge } from 'react-bootstrap';
import { Text } from '../Text';
import { useLesson } from '../../providers';
import { ShieldIcon } from '../../assets/icons';
import { LessonKeywordBadge } from './LessonKeywordBadge';
import { LessonBasicConceptBadge } from './LessonBasicConceptBadge';

export const LessonDetailsContent = () => {
  const { lesson } = useLesson();
  const { basicConcepts, keywords, lessonType, shortDescription, original } = lesson || {};

  return (
    <section className="mt-45">
      <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
        <h2 className="h1 subheader-lg-1 subheader-md-2 fz-lg">Краткая информация по уроку</h2>
        {original && (
          <Badge bg="" className="lesson-details__badge badge-xl">
            <ShieldIcon />
            Соответствует обновленному ФГОС
          </Badge>
        )}
      </div>

      {shortDescription && <Text className="fz-normal">{shortDescription}</Text>}

      {lessonType && (
        <>
          <h3 className="subheader-3 subheader-lg-2 mt-4 mb-25 fz-md">Тип урока</h3>
          <div className="d-flex">
            <Badge bg="gray" className="badge-lg">
              {lessonType.name}
            </Badge>
          </div>
        </>
      )}

      {!!keywords && keywords.length > 0 && (
        <>
          <h3 className="subheader-3 subheader-lg-2 mt-4 mb-25 fz-md">Ключевые слова</h3>
          <div className="d-flex flex-wrap">
            {keywords.map((keyword) => (
              <LessonKeywordBadge key={`keyword-${keyword.id}`} keyword={keyword} />
            ))}
          </div>
        </>
      )}

      {!!basicConcepts && basicConcepts.length > 0 && (
        <>
          <h3 className="subheader-3 subheader-lg-2 mt-4 mb-25 fz-md">
            Базовые понятия, единые для школьного образования
          </h3>
          <div className="d-flex flex-wrap">
            {basicConcepts.map((basicConcept) => (
              <LessonBasicConceptBadge key={basicConcept.uid} basicConcept={basicConcept} />
            ))}
          </div>
        </>
      )}
    </section>
  );
};
