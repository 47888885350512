import classNames from 'classnames';
import { ListGroup } from 'react-bootstrap';
import { LessonTaskModel } from '../../../models';
import { useLesson } from '../../../providers';
import { isLessonTaskSolved } from '../../../utils/LessonUtils';
import { LessonTaskType } from '../../task/LessonTaskType';

type LessonDetailsNavigationTaskProps = {
  task: LessonTaskModel;
};

export const LessonDetailsNavigationTask = ({ task }: LessonDetailsNavigationTaskProps) => {
  const { result } = useLesson();

  return (
    <ListGroup.Item className={classNames({ solved: isLessonTaskSolved(task, result) })}>
      <LessonTaskType type={task.eomType} className="fz-normal" />
    </ListGroup.Item>
  );
};
