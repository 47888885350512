import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.ComputerScience];

const ComputerScienceIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="0 0 240 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_2796_52166)">
      <path
        d="M54.9768 36.8716C50.8167 36.9858 47.2441 40.506 47.0614 44.6783C45.8662 72.5126 45.4013 100.347 45.6673 128.181C45.7077 132.354 49.2439 135.801 53.5014 135.837C64.856 141.002 76.1995 146.202 87.4981 151.394C87.1014 112.999 87.2998 74.603 88.0932 36.2075C77.0546 36.3533 66.0154 36.5741 54.9768 36.8716Z"
        fill={secondaryColor}
      />
      <path
        d="M184.694 36.8726C152.494 36.0062 120.293 35.7849 88.0924 36.2091C83.9324 36.2647 80.4355 39.7729 80.3346 43.9887C79.686 72.1045 79.4336 100.22 79.578 128.336C79.6007 132.552 83.1132 136.021 87.3706 136.038C98.2397 143.759 109.085 151.501 119.835 159.201C141.801 151.501 163.961 143.626 186.17 135.839C190.428 135.803 193.964 132.356 194.004 128.183C194.27 100.348 193.805 72.5141 192.61 44.6799C192.427 40.5066 188.854 36.9868 184.694 36.8726Z"
        fill={secondaryColor}
      />
      <path
        d="M170.348 158.893C170.149 169.091 169.884 179.289 169.553 189.487C179.648 189.279 189.742 189.008 199.837 188.673C208.148 188.385 215.404 181.348 215.836 173.098C200.811 168.568 185.633 163.804 170.348 158.893Z"
        fill={secondaryColor}
      />
      <path
        d="M185.278 173.901C163.723 166.754 141.861 159.199 119.836 151.499C87.527 159.199 55.4553 166.586 23.8359 173.097C24.2675 181.347 31.5236 188.384 39.8347 188.671C83.0742 190.107 126.313 190.379 169.553 189.486C177.864 189.308 184.98 182.266 185.278 173.901Z"
        fill={secondaryColor}
      />
      <path
        d="M196.11 139.436C194.751 137.167 192.249 135.789 189.529 135.811C179.205 135.897 168.88 135.964 158.556 136.011C167.666 148.752 176.629 161.408 185.278 173.902C195.464 173.685 205.649 173.417 215.835 173.098C209.511 162.009 202.901 150.775 196.11 139.436Z"
        fill={tertiaryColor}
      />
      <path
        d="M119.836 136.098C96.6042 136.098 73.3726 136.002 50.1404 135.809C47.4204 135.787 44.9194 137.168 43.5607 139.438C36.7704 150.777 30.1608 162.01 23.8359 173.098C68.568 174.498 113.3 174.919 158.032 174.363C158.258 174.452 119.988 136.167 119.836 136.098Z"
        fill={tertiaryColor}
      />
      <path
        d="M165.161 139.698C172.031 151.18 178.771 162.594 185.279 173.902C166.056 174.312 146.834 174.542 127.612 174.59C117.066 174.619 107.513 170.251 100.503 163.265C93.5012 156.273 89.0742 146.648 89.001 136.041C112.187 136.126 135.372 136.115 158.558 136.008C161.278 135.996 163.787 137.399 165.161 139.698Z"
        fill={tertiaryColor}
      />
      <rect x="54.6006" y="45.1572" width="129.213" height="83.9461" rx="4.35669" fill={primaryColor} />
      <path
        d="M50.3145 145.243C51.0941 143.9 52.5295 143.073 54.0823 143.073H185.564C187.042 143.073 188.42 143.823 189.222 145.064L198.875 159.99C200.749 162.889 198.669 166.713 195.216 166.713H45.417C42.0586 166.713 39.9631 163.073 41.6492 160.169L50.3145 145.243Z"
        fill={primaryColor}
      />
      <path
        d="M72.5863 77.7676C70.4703 77.7853 68.7215 79.0331 68.6833 80.553C68.645 82.0733 70.3381 83.293 72.4601 83.2789C74.6987 83.2638 76.9373 83.2497 79.176 83.2367C81.7535 82.3019 84.3291 81.3671 86.9016 80.4326C84.3582 79.5245 81.8184 78.6192 79.2836 77.7168C77.0515 77.7327 74.8189 77.7496 72.5863 77.7676Z"
        fill={secondaryColor}
      />
      <path
        d="M93.909 80.4543C93.9262 78.9323 92.3072 77.7057 90.2909 77.7169C86.8598 77.7352 83.4282 77.7567 79.9971 77.7815C79.9597 79.6141 79.9257 81.4464 79.8945 83.279C83.3352 83.2582 86.7759 83.2403 90.2162 83.2252C92.2392 83.2166 93.8917 81.9764 93.909 80.4543Z"
        fill={secondaryColor}
      />
      <path
        d="M72.5628 93.0477C70.4461 93.0596 68.7069 94.3058 68.6829 95.8308C68.6588 97.3557 70.362 98.5842 72.4832 98.5759C74.7206 98.5668 76.9584 98.5582 79.1958 98.5506C81.7655 97.6186 84.3343 96.6864 86.9016 95.7537C84.3528 94.8381 81.8066 93.9243 79.2638 93.0137C77.0305 93.0242 74.7967 93.0354 72.5628 93.0477Z"
        fill={tertiaryColor}
      />
      <path
        d="M79.9623 93.1052C79.9364 94.9289 79.914 96.7522 79.8945 98.5759C95.8469 98.5209 111.799 98.4987 127.751 98.5102C129.552 97.4994 131.352 96.4889 133.15 95.4795C130.1 94.657 127.052 93.8352 124.007 93.0148C109.326 93.0073 94.6442 93.0373 79.9623 93.1052Z"
        fill={tertiaryColor}
      />
      <path
        d="M165.756 93.1343C152.084 93.061 138.412 93.0208 124.74 93.0137C125.805 94.9078 127.047 96.7365 128.457 98.4876C140.916 98.4965 153.375 98.526 165.834 98.5759C167.929 98.5841 169.61 97.3743 169.587 95.8734C169.563 94.3725 167.846 93.1457 165.756 93.1343Z"
        fill={tertiaryColor}
      />
      <path
        d="M72.5628 62.4544C70.4461 62.4663 68.7069 63.7125 68.6829 65.2375C68.6588 66.7625 70.362 67.9909 72.4832 67.9826C74.7206 67.9736 76.9584 67.9649 79.1958 67.9573C81.7655 67.0254 84.3343 66.0931 86.9016 65.1605C84.3528 64.2448 81.8066 63.331 79.2638 62.4204C77.0305 62.4309 74.7967 62.4421 72.5628 62.4544Z"
        fill={tertiaryColor}
      />
      <path
        d="M79.9623 62.5119C79.9364 64.3356 79.914 66.159 79.8945 67.9827C95.8469 67.9276 111.799 67.9055 127.751 67.9169C129.552 66.9061 131.352 65.8956 133.15 64.8862C130.1 64.0637 127.052 63.2419 124.007 62.4215C109.326 62.414 94.6442 62.444 79.9623 62.5119Z"
        fill={tertiaryColor}
      />
      <path
        d="M165.756 62.5411C152.084 62.4677 138.412 62.4275 124.74 62.4204C125.805 64.3146 127.047 66.1433 128.457 67.8943C140.916 67.9032 153.375 67.9328 165.834 67.9826C167.929 67.9908 169.61 66.7811 169.587 65.2802C169.563 63.7793 167.846 62.5525 165.756 62.5411Z"
        fill={tertiaryColor}
      />
      <path
        d="M68.6827 111.107C68.6722 112.637 70.3857 113.874 72.5057 113.871C74.7422 113.868 76.9786 113.866 79.215 113.863C81.777 112.934 84.3395 112.005 86.9016 111.074C84.3475 110.15 81.795 109.229 79.2444 108.309C77.0095 108.314 74.7746 108.32 72.5397 108.326C70.4216 108.332 68.6932 109.577 68.6827 111.107Z"
        fill={secondaryColor}
      />
      <path
        d="M115.357 113.848C117.444 113.848 119.135 112.608 119.135 111.078C119.135 109.549 117.445 108.309 115.36 108.309C103.548 108.312 91.7351 108.327 79.923 108.355C79.9103 110.194 79.9009 112.032 79.8945 113.871C91.7149 113.857 103.536 113.849 115.357 113.848Z"
        fill={secondaryColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2796_52166"
        x="-6.16406"
        y="36"
        width="252"
        height="213.999"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.4 0 0 0 0 0.588235 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2796_52166" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2796_52166" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default ComputerScienceIcon;
