import { Icon } from './Icon';

export const ShieldIcon: Icon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.0865 22C11.9627 22 11.8389 21.9716 11.7271 21.9137L8.12603 20.0496C7.10419 19.5201 6.30484 18.9259 5.68067 18.2336C4.31453 16.7195 3.55444 14.776 3.54236 12.7599L3.50004 6.12426C3.49504 5.35842 3.98935 4.67103 4.72829 4.41215L11.3405 2.10679C11.7332 1.96656 12.1711 1.9646 12.5708 2.09992L19.2082 4.32684C19.9512 4.57493 20.4535 5.25742 20.4575 6.02228L20.4998 12.6628C20.5129 14.676 19.779 16.6274 18.434 18.1581C17.8169 18.8602 17.0246 19.4632 16.0128 20.0025L12.4439 21.9088C12.3332 21.9686 12.2104 21.999 12.0865 22Z M11.3191 14.3209C11.1258 14.3219 10.9325 14.2523 10.7835 14.1091L8.86671 12.2656C8.57073 11.9793 8.56771 11.5145 8.86067 11.2262C9.15363 10.9369 9.63183 10.934 9.92882 11.2193L11.308 12.5451L14.6756 9.22479C14.9695 8.93552 15.4477 8.93258 15.7437 9.21793C16.0407 9.50426 16.0437 9.97004 15.7508 10.2574L11.8517 14.1022C11.7047 14.2474 11.5124 14.3199 11.3191 14.3209Z"
    />
  </svg>
);
