import { Icon } from '../Icon';

export const TypeTestIcon: Icon = (props) => (
  <svg width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2" y="21" width="19" height="19" rx="4" transform="rotate(-90 2 21)" fill="#55A8F0" />
    <rect y="19" width="19" height="19" rx="4" transform="rotate(-90 0 19)" fill="#D9F1FF" />
    <path
      d="M14.8718 6.34251C14.4318 5.88455 13.8962 5.88647 13.4562 6.34443L8.50752 11.2916L5.6791 8.46321C5.23908 8.00524 4.61598 8.11211 4.26488 8.46321C4.26318 8.46597 4.26577 8.46459 4.26577 8.46459C3.91082 8.81898 3.91276 9.52381 4.2635 9.88018C4.61423 10.2366 7.8007 13.4136 7.8007 13.4136C8.15425 13.7671 8.86136 13.7671 9.21484 13.4137L14.9747 7.86306C15.4776 7.4051 15.3118 6.80048 14.8718 6.34251Z"
      fill="#55A8F0"
    />
  </svg>
);
