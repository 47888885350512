import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Fetcher } from '@ru-edu/ecl-player';
import { AuthApi, AuthCredentialsModel } from '../../api/auth';
import { UserModel } from '../../models';
import { useRequest } from '../../hooks';

type AuthContext = {
  loading?: boolean;
  user?: UserModel;
  error?: Error;
  fetcher: Fetcher;
  logout: () => void;
  login: (credentials: AuthCredentialsModel) => void;
};

const AuthContext = createContext<AuthContext | undefined>(undefined);

type AuthProviderProps = {
  baseUrl: string;
};

const AuthTokenKey = 'AUTH_TOKEN';

export const AuthProvider: FC<AuthProviderProps> = ({ baseUrl, children }) => {
  const [token, setToken] = useState(localStorage.getItem(AuthTokenKey) || undefined);
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState<Error>();

  const fetcher = useMemo(
    () => (url: string, init?: RequestInit) => {
      const requestUrl = [baseUrl, url].filter(Boolean).join('');
      const config: RequestInit = init || {};

      config.headers = new Headers({
        ...config.headers,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      });

      if (!!token) {
        config.headers.set('Authorization', `Bearer ${token}`);
      }

      return fetch(requestUrl, config);
    },
    [baseUrl, token]
  );
  const authApi = useMemo(() => new AuthApi(fetcher), [fetcher]);

  const {
    result: user,
    error: profileError,
    loading: profileLoading
  } = useRequest(() => (!token ? undefined : authApi.getProfile()), [authApi, token], { initialLoading: !!token });

  const login = useCallback(
    async (credentials: AuthCredentialsModel) => {
      setLoginLoading(true);
      setToken(undefined);
      localStorage.removeItem(AuthTokenKey);

      try {
        const { code } = await authApi.login(credentials);
        const token = await authApi.authenticate('basic', code);

        setToken(token);

        localStorage.setItem(AuthTokenKey, token);
      } catch (e) {
        setLoginError(e as Error);
      } finally {
        setLoginLoading(false);
      }
    },
    [authApi]
  );

  const logout = useCallback(() => {
    setToken(undefined);
    localStorage.removeItem(AuthTokenKey);
  }, []);

  useEffect(() => {
    if (loginError || profileError)
      console.error('[AuthProvider]', 'Unable to load user profile', loginError || profileError);
  }, [loginError, profileError]);

  return (
    <AuthContext.Provider
      value={{
        loading: profileLoading || loginLoading,
        user,
        error: loginError || profileError,
        fetcher,
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider.');
  }

  return context;
};
