import { ListGroup } from 'react-bootstrap';

import { LessonModuleTaskListItem } from './LessonModuleTaskListItem';
import { useLesson } from '../../providers';
import { getExtendedTaskList } from '../../utils/LessonUtils';

export const LessonModuleTaskList = () => {
  const { activeModule, result } = useLesson();

  if (!activeModule) {
    return null;
  }

  const tasks = getExtendedTaskList(activeModule.tasks, result);

  return (
    <div className="my-4">
      <ListGroup className="lesson-module__task-list">
        {tasks.map((taskProps) => (
          <LessonModuleTaskListItem key={taskProps.task.uid} {...taskProps} />
        ))}
      </ListGroup>
    </div>
  );
};
