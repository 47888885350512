import { IModelListApi, ModelListApi, LessonModel, Fetcher } from '@ru-edu/ecl-player';
import { LessonSearchResultModel } from '../../models';

export type ILessonApi = IModelListApi<LessonModel, LessonSearchResultModel>;

export class LessonApi extends ModelListApi<LessonModel, LessonSearchResultModel> implements ILessonApi {
  constructor(fetcher: Fetcher) {
    super(fetcher, '/api/ecl/lessons');
  }
}
