import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Geometry];

const GeometryIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.2708 35.3634C13.9962 35.3634 13.756 35.1901 13.653 34.9474L10.9069 28.7067C10.7696 28.36 10.9069 27.944 11.2501 27.8053C11.5934 27.632 12.0053 27.8053 12.1426 28.152L14.2708 32.9712L16.3991 28.152C16.5364 27.8053 16.9483 27.632 17.2916 27.8053C17.6348 27.944 17.8065 28.36 17.6348 28.7067L14.8887 34.9474C14.7857 35.1901 14.5455 35.3634 14.2708 35.3634Z"
      fill={secondaryColor}
    />
    <path
      d="M34.8667 9.36068C33.9056 9.36068 33.1504 8.59793 33.1504 7.62716C33.1504 6.6564 33.9056 5.89365 34.8667 5.89365C35.8279 5.89365 36.583 6.6564 36.583 7.62716C36.583 8.00854 36.892 8.32057 37.2696 8.32057C37.6472 8.32057 37.9561 8.00854 37.9561 7.62716C37.9561 5.89365 36.583 4.50684 34.8667 4.50684C33.1504 4.50684 31.7773 5.89365 31.7773 7.62716C31.7773 9.36068 33.1504 10.7475 34.8667 10.7475C35.8279 10.7475 36.583 11.5102 36.583 12.481C36.583 13.4518 35.8279 14.2145 34.8667 14.2145C33.9056 14.2145 33.1504 13.4518 33.1504 12.481C33.1504 12.0996 32.8415 11.7876 32.4639 11.7876C32.0863 11.7876 31.7773 12.0996 31.7773 12.481C31.7773 14.2145 33.1504 15.6013 34.8667 15.6013C36.583 15.6013 37.9561 14.2145 37.9561 12.481C37.9561 10.7475 36.583 9.36068 34.8667 9.36068Z"
      fill={tertiaryColor}
    />
    <path
      d="M9.80837 18.0288C9.67107 18.0288 9.53376 17.9941 9.39645 17.8901L7.40553 16.3646V17.3354C7.40553 17.7167 7.09659 18.0288 6.719 18.0288C6.34141 18.0288 6.03247 17.7167 6.03247 17.3354V12.1348C6.03247 11.7534 6.34141 11.4414 6.719 11.4414H8.26368C9.49943 11.4414 10.4949 12.4468 10.4949 13.695C10.4949 14.7004 9.8427 15.5325 8.98454 15.8445L10.2203 16.7806C10.5292 17.0233 10.5979 17.4394 10.3576 17.7514C10.2203 17.9248 10.0143 18.0288 9.80837 18.0288ZM7.40553 14.5617H8.26368C8.74425 14.5617 9.12184 14.1804 9.12184 13.695C9.12184 13.2096 8.74425 12.8282 8.26368 12.8282H7.40553V14.5617Z"
      fill={secondaryColor}
    />
    <path
      d="M23.1956 11.0945H19.0764C18.8705 11.0945 18.7332 10.9558 18.7332 10.7478V2.42692C18.7332 1.07478 19.7973 0 21.136 0C22.4747 0 23.5388 1.07478 23.5388 2.42692V10.7478C23.5388 10.9558 23.4015 11.0945 23.1956 11.0945Z"
      fill={primaryColor}
    />
    <path
      d="M5.68922 38.4839C5.58624 38.4839 5.44894 38.4492 5.34596 38.3799C5.0027 38.1719 4.89972 37.7558 5.10567 37.4438L18.8362 13.868C19.0422 13.5213 19.4541 13.4173 19.763 13.6253C20.1063 13.8333 20.2093 14.2494 20.0033 14.5614L6.27277 38.1372C6.16979 38.3452 5.92951 38.4839 5.68922 38.4839Z"
      fill={secondaryColor}
    />
    <path
      d="M36.5829 38.4839C36.3426 38.4839 36.1024 38.3452 35.9994 38.1372L22.2688 14.5614C22.0629 14.2147 22.2002 13.7986 22.5091 13.6253C22.8524 13.4173 23.2643 13.5559 23.4359 13.868L37.1665 37.4438C37.3724 37.7905 37.2351 38.2065 36.9262 38.3799C36.8232 38.4492 36.6859 38.4839 36.5829 38.4839Z"
      fill={secondaryColor}
    />
    <path
      d="M30.7474 22.0851H11.5247C11.1471 22.0851 10.8381 21.773 10.8381 21.3917C10.8381 21.0103 11.1471 20.6982 11.5247 20.6982H30.7474C31.125 20.6982 31.434 21.0103 31.434 21.3917C31.434 21.773 31.125 22.0851 30.7474 22.0851Z"
      fill={secondaryColor}
    />
    <path
      d="M21.1362 23.9222C20.1751 23.9222 19.4199 23.1595 19.4199 22.1887V20.4552C19.4199 19.4844 20.1751 18.7217 21.1362 18.7217C22.0974 18.7217 22.8526 19.4844 22.8526 20.4552V22.1887C22.8526 23.1595 22.0974 23.9222 21.1362 23.9222Z"
      fill={tertiaryColor}
    />
    <path
      d="M8.57258 35.0518C8.19499 35.0518 7.85172 34.9478 7.54279 34.7397C6.6503 34.185 6.34136 33.0062 6.89058 32.0701L16.0557 16.0871C16.0901 16.0178 16.193 15.9484 16.2617 15.9138C16.3647 15.8791 16.4333 15.9138 16.5363 15.9484L19.2824 17.682C19.454 17.786 19.4884 17.994 19.3854 18.1673L10.2546 34.081C9.97996 34.5664 9.53371 34.8784 9.01882 35.0171C8.88151 35.0518 8.70988 35.0518 8.57258 35.0518Z"
      fill={primaryColor}
    />
    <path
      d="M33.6995 35.0516C33.5279 35.0516 33.3906 35.0169 33.219 34.9822C32.7041 34.8436 32.2578 34.5315 31.9832 34.0461L22.8867 18.2018C22.7838 18.0285 22.8524 17.8204 22.9897 17.7164L25.7358 15.9829C25.8388 15.9482 25.9418 15.9482 26.0104 15.9482C26.1134 15.9829 26.1821 16.0176 26.2164 16.1216L35.3815 32.1046C35.9308 33.0407 35.6218 34.2195 34.7293 34.7742C34.4204 34.9476 34.0771 35.0516 33.6995 35.0516Z"
      fill={primaryColor}
    />
    <path
      d="M21.136 15.9488C18.8705 15.9488 17.0168 14.0766 17.0168 11.7884C17.0168 9.50013 18.8705 7.62793 21.136 7.62793C23.4015 7.62793 25.2552 9.50013 25.2552 11.7884C25.2552 14.0766 23.4015 15.9488 21.136 15.9488Z"
      fill={tertiaryColor}
    />
    <path
      d="M21.136 14.2152C19.7973 14.2152 18.7332 13.1404 18.7332 11.7882C18.7332 10.4361 19.7973 9.36133 21.136 9.36133C22.4747 9.36133 23.5388 10.4361 23.5388 11.7882C23.5388 13.1404 22.4747 14.2152 21.136 14.2152Z"
      fill={secondaryColor}
    />
    <path
      d="M49.2838 40.2174H20.1064C19.9691 40.2174 19.8318 40.1481 19.7974 40.0094C19.7288 39.8707 19.7631 39.732 19.8661 39.628L50.4165 8.77143C50.5195 8.66742 50.6568 8.63275 50.7941 8.70209C50.9314 8.77143 51.0001 8.87544 51.0001 9.01412V38.4839C51.0001 39.42 50.2106 40.2174 49.2838 40.2174ZM35.6905 32.9366H43.7916V24.7544L35.6905 32.9366Z"
      fill={tertiaryColor}
    />
    <path
      d="M50.9998 35.71H49.2835C48.9059 35.71 48.5969 36.022 48.5969 36.4034C48.5969 36.7847 48.9059 37.0968 49.2835 37.0968H50.9998V35.71Z"
      fill={secondaryColor}
    />
    <path
      d="M49.2835 33.2833H50.9998V31.8965H49.2835C48.9059 31.8965 48.5969 32.2085 48.5969 32.5899C48.5969 32.9713 48.9059 33.2833 49.2835 33.2833Z"
      fill={secondaryColor}
    />
    <path
      d="M49.2835 29.4688H50.9998V28.082H49.2835C48.9059 28.082 48.5969 28.3941 48.5969 28.7754C48.5969 29.1568 48.9059 29.4688 49.2835 29.4688Z"
      fill={secondaryColor}
    />
    <path
      d="M49.2835 25.6554H50.9998V24.2686H49.2835C48.9059 24.2686 48.5969 24.5806 48.5969 24.962C48.5969 25.3433 48.9059 25.6554 49.2835 25.6554Z"
      fill={secondaryColor}
    />
    <path
      d="M49.2835 21.8419H50.9998V20.4551H49.2835C48.9059 20.4551 48.5969 20.7671 48.5969 21.1485C48.5969 21.5299 48.9059 21.8419 49.2835 21.8419Z"
      fill={secondaryColor}
    />
    <path
      d="M49.2835 18.0284H50.9998V16.6416H49.2835C48.9059 16.6416 48.5969 16.9536 48.5969 17.335C48.5969 17.7164 48.9059 18.0284 49.2835 18.0284Z"
      fill={secondaryColor}
    />
    <path
      d="M48.5972 13.5206C48.5972 13.9019 48.9061 14.214 49.2837 14.214H51V12.8271H49.2837C48.9061 12.8271 48.5972 13.1392 48.5972 13.5206Z"
      fill={secondaryColor}
    />
  </svg>
);

export default GeometryIcon;
