import { useLesson } from '../providers';
import { LessonTask } from './task';
import { LessonDetails } from './details';
import { LessonModule } from './module';

type LessonProps = {
  onBackClick?: () => void;
};

export const Lesson = ({ onBackClick }: LessonProps) => {
  const { activeModule, activeTask } = useLesson();

  if (!!activeTask) {
    return <LessonTask />;
  }

  if (!!activeModule) {
    return <LessonModule />;
  }

  return <LessonDetails onBackClick={onBackClick} />;
};
