import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.ComputerScience];

const ComputerScienceIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.2985 2.20407C11.3235 2.23076 10.4862 3.05367 10.4434 4.02901C10.1632 10.5357 10.0543 17.0425 10.1166 23.5492C10.1261 24.5246 10.9549 25.3305 11.9527 25.3389C14.614 26.5461 17.2726 27.762 19.9207 28.9757C19.8277 20.0001 19.8742 11.0244 20.0602 2.04883C17.473 2.0829 14.8857 2.13453 12.2985 2.20407Z"
      fill={secondaryColor}
    />
    <path
      d="M42.7009 2.204C35.154 2.00146 27.6069 1.94971 20.06 2.04887C19.085 2.06187 18.2654 2.88197 18.2417 3.8675C18.0897 10.44 18.0306 17.0125 18.0644 23.5852C18.0697 24.5707 18.893 25.3814 19.8908 25.3855C22.4382 27.1905 24.9801 29.0002 27.4998 30.8002C32.648 29.0002 37.8417 27.1593 43.047 25.3389C44.0448 25.3306 44.8735 24.5247 44.8831 23.5492C44.9453 17.0425 44.8364 10.5358 44.5563 4.02906C44.5133 3.05348 43.676 2.23069 42.7009 2.204Z"
      fill={secondaryColor}
    />
    <path
      d="M39.339 30.7275C39.2924 33.1115 39.2303 35.4956 39.1526 37.8796C41.5186 37.8309 43.8845 37.7674 46.2505 37.6891C48.1983 37.6219 49.899 35.9769 50.0002 34.0482C46.4787 32.9893 42.9215 31.8757 39.339 30.7275Z"
      fill={secondaryColor}
    />
    <path
      d="M42.838 34.2368C37.7861 32.5661 32.662 30.8 27.5 29C19.9276 30.8 12.4108 32.5268 5 34.0488C5.10114 35.9775 6.8018 37.6225 8.7497 37.6897C18.8839 38.0252 29.0181 38.0888 39.1523 37.8802C41.1002 37.8384 42.7681 36.1922 42.838 34.2368Z"
      fill={secondaryColor}
    />
    <path
      d="M45.3766 26.1794C45.058 25.6489 44.4716 25.3268 43.8341 25.3321C41.4143 25.3521 38.9945 25.3677 36.5747 25.3788C38.7099 28.3573 40.8107 31.3157 42.8378 34.2365C45.225 34.1857 47.6123 34.123 49.9995 34.0485C48.5173 31.4563 46.968 28.8301 45.3766 26.1794Z"
      fill={tertiaryColor}
    />
    <path
      d="M27.5 25.3996C22.055 25.3996 16.6101 25.3772 11.1651 25.3321C10.5276 25.3269 9.94143 25.6496 9.62298 26.1803C8.03151 28.831 6.48239 31.4569 5 34.0489C15.4841 34.3763 25.9681 34.4747 36.4521 34.3445C36.5051 34.3655 27.5356 25.4158 27.5 25.3996Z"
      fill={tertiaryColor}
    />
    <path
      d="M38.123 26.2414C39.733 28.9254 41.3129 31.5937 42.838 34.2372C38.3327 34.3331 33.8274 34.3867 29.3223 34.398C26.8506 34.4047 24.6117 33.3836 22.9687 31.7507C21.3277 30.1161 20.2901 27.8662 20.2729 25.3865C25.7071 25.4064 31.1412 25.4039 36.5754 25.3789C37.2129 25.3761 37.8008 25.704 38.123 26.2414Z"
      fill={tertiaryColor}
    />
    <rect x="12.2107" y="4.13965" width="30.2843" height="19.6238" rx="2" fill={primaryColor} />
    <path
      d="M10.9222 28.0252C11.2804 27.4098 11.9387 27.0312 12.6507 27.0312H42.3734C43.0512 27.0312 43.6828 27.3745 44.0516 27.9432L46.0421 31.0134C46.4735 31.6787 45.996 32.5574 45.2031 32.5574H10.0233C9.25193 32.5574 8.771 31.721 9.15904 31.0543L10.9222 28.0252Z"
      fill={primaryColor}
    />
    <path
      d="M16.4257 11.7638C15.9297 11.768 15.5198 12.0597 15.5109 12.415C15.5019 12.7704 15.8987 13.0555 16.3961 13.0522C16.9208 13.0487 17.4454 13.0454 17.9701 13.0423C18.5742 12.8238 19.1779 12.6053 19.7808 12.3868C19.1847 12.1745 18.5894 11.9629 17.9953 11.752C17.4722 11.7557 16.9489 11.7596 16.4257 11.7638Z"
      fill={secondaryColor}
    />
    <path
      d="M21.4233 12.3919C21.4273 12.0361 21.0479 11.7494 20.5753 11.752C19.7712 11.7563 18.9669 11.7613 18.1627 11.7671C18.1539 12.1955 18.146 12.6238 18.1387 13.0522C18.9451 13.0474 19.7515 13.0432 20.5578 13.0396C21.0319 13.0376 21.4193 12.7477 21.4233 12.3919Z"
      fill={secondaryColor}
    />
    <path
      d="M16.4202 15.3361C15.9241 15.3389 15.5164 15.6302 15.5108 15.9867C15.5052 16.3432 15.9043 16.6303 16.4015 16.6284C16.9259 16.6263 17.4504 16.6242 17.9748 16.6225C18.577 16.4046 19.1791 16.1867 19.7808 15.9687C19.1834 15.7546 18.5867 15.541 17.9907 15.3281C17.4673 15.3306 16.9437 15.3332 16.4202 15.3361Z"
      fill={tertiaryColor}
    />
    <path
      d="M18.1545 15.3495C18.1485 15.7758 18.1432 16.2021 18.1387 16.6284C21.8775 16.6155 25.6162 16.6103 29.3551 16.613C29.7772 16.3767 30.199 16.1405 30.6204 15.9046C29.9056 15.7123 29.1913 15.5202 28.4776 15.3284C25.0366 15.3266 21.5956 15.3336 18.1545 15.3495Z"
      fill={tertiaryColor}
    />
    <path
      d="M38.2625 15.3563C35.0582 15.3392 31.8537 15.3298 28.6494 15.3281C28.899 15.7709 29.1901 16.1984 29.5204 16.6077C32.4406 16.6098 35.3607 16.6167 38.2808 16.6284C38.7717 16.6303 39.1658 16.3475 39.1603 15.9966C39.1547 15.6458 38.7523 15.359 38.2625 15.3563Z"
      fill={tertiaryColor}
    />
    <path
      d="M16.4202 8.18471C15.9241 8.18749 15.5164 8.47881 15.5108 8.8353C15.5052 9.19179 15.9043 9.47896 16.4015 9.47701C16.9259 9.4749 17.4504 9.47287 17.9748 9.4711C18.577 9.25325 19.1791 9.03531 19.7808 8.81729C19.1834 8.60324 18.5867 8.38962 17.9907 8.17676C17.4673 8.17921 16.9437 8.18183 16.4202 8.18471Z"
      fill={tertiaryColor}
    />
    <path
      d="M18.1545 8.19815C18.1485 8.62447 18.1432 9.05071 18.1387 9.47702C21.8775 9.46416 25.6162 9.45898 29.3551 9.46165C29.7772 9.22536 30.199 8.98915 30.6204 8.75318C29.9056 8.56091 29.1913 8.3688 28.4776 8.17702C25.0366 8.17527 21.5956 8.18228 18.1545 8.19815Z"
      fill={tertiaryColor}
    />
    <path
      d="M38.2625 8.20496C35.0582 8.18782 31.8537 8.17842 28.6494 8.17676C28.899 8.61955 29.1901 9.04703 29.5204 9.45638C32.4406 9.45846 35.3607 9.46537 38.2808 9.47701C38.7717 9.47893 39.1658 9.19613 39.1603 8.84527C39.1547 8.49442 38.7523 8.20762 38.2625 8.20496Z"
      fill={tertiaryColor}
    />
    <path
      d="M15.5108 19.5574C15.5083 19.9149 15.9099 20.2043 16.4068 20.2036C16.9309 20.2029 17.4551 20.2022 17.9793 20.2016C18.5797 19.9846 19.1803 19.7672 19.7808 19.5496C19.1822 19.3337 18.5839 19.1183 17.9862 18.9033C17.4624 18.9045 16.9386 18.9059 16.4147 18.9073C15.9183 18.9087 15.5132 19.1998 15.5108 19.5574Z"
      fill={secondaryColor}
    />
    <path
      d="M26.4501 20.1982C26.9392 20.1982 27.3357 19.9083 27.3357 19.5507C27.3357 19.1931 26.9395 18.9032 26.4508 18.9033C23.6824 18.9039 20.9138 18.9075 18.1454 18.914C18.1424 19.3439 18.1402 19.7737 18.1387 20.2036C20.9091 20.2002 23.6796 20.1984 26.4501 20.1982Z"
      fill={secondaryColor}
    />
  </svg>
);

export default ComputerScienceIcon;
