import { Icon } from '../Icon';

export const TypeLessonIcon: Icon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V13C24 15.2091 22.2091 17 20 17H14V19H17C17.5523 19 18 19.4477 18 20V20C18 20.5523 17.5523 21 17 21H12H7C6.44772 21 6 20.5523 6 20V20C6 19.4477 6.44772 19 7 19H10V17H4C1.79086 17 0 15.2091 0 13V4Z"
      fill="#20CE70"
    />
    <rect x="2" y="2" width="20" height="13" rx="3" fill="#E3F9ED" />
  </svg>
);
