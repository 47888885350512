import { FC } from 'react';
import { MathJax3Config, MathJaxContext } from 'better-react-mathjax';
import { S3ApiProvider, XApiProvider } from '../providers';
import { Fetcher } from '../api/core';

type PlayerWrapperProps = {
  fetcher?: Fetcher;
};

const mathJaxConfig: MathJax3Config = {
  tex: {
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)']
    ],
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]']
    ]
  },
  options: {
    enableMenu: false
  }
};

const mathJaxErrorHandler = (error: any) => console.debug('[MathJaxContext]', error);

export const PlayerWrapper: FC<PlayerWrapperProps> = ({ fetcher, children }) => {
  const apiFetcher = fetcher || fetch;

  return (
    <div className="ecl-container">
      <MathJaxContext config={mathJaxConfig} onError={mathJaxErrorHandler}>
        <XApiProvider fetcher={apiFetcher}>
          <S3ApiProvider fetcher={apiFetcher}>{children}</S3ApiProvider>
        </XApiProvider>
      </MathJaxContext>
    </div>
  );
};
