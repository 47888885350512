import classNames from 'classnames';
import { useCallback } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { LessonTaskModelExtended } from '../../models';
import { useLesson } from '../../providers';
import { LessonTaskRecommendations, LessonTaskType } from '../task';

type LessonModuleTaskListItemProps = LessonTaskModelExtended;

export const LessonModuleTaskListItem = ({ task, solved }: LessonModuleTaskListItemProps) => {
  const { onTaskSelect } = useLesson();

  const handleTaskSelect = useCallback(() => onTaskSelect(task, true), [task, onTaskSelect]);

  return (
    <ListGroup.Item className={classNames({ solved })}>
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
        <div className="lesson-module__task-description flex-grow-1 me-2">
          <div className="lesson-module__task-name fz-md">
            {task.name}
            <LessonTaskRecommendations task={task} />
          </div>
          <LessonTaskType type={task.eomType} className="fz-normal" />
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3 mt-md-0 w-100-md">
          <Button
            variant={solved ? 'outline-primary' : 'primary'}
            className="text-nowrap flex-grow-1"
            onClick={handleTaskSelect}
          >
            {solved ? 'Повторить' : 'Запустить'}
          </Button>
        </div>
      </div>
    </ListGroup.Item>
  );
};
