import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.Algebra];

const AlgebraIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="240" height="255" viewBox="0 0 240 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_1970_14791)">
      <path
        d="M126.5 83C123.95 83 122 81.1091 122 78.6364V71.3636C122 68.8909 123.95 67 126.5 67C129.05 67 131 68.8909 131 71.3636V78.6364C131 81.1091 129.05 83 126.5 83Z"
        fill={secondaryColor}
      />
      <path
        d="M126.5 102C123.95 102 122 100.109 122 97.6364V90.3636C122 87.8909 123.95 86 126.5 86C129.05 86 131 87.8909 131 90.3636V97.6364C131 100.109 129.05 102 126.5 102Z"
        fill={secondaryColor}
      />
      <path
        d="M126.5 121C123.95 121 122 119.109 122 116.636V109.364C122 106.891 123.95 105 126.5 105C129.05 105 131 106.891 131 109.364V116.636C131 119.109 129.05 121 126.5 121Z"
        fill={secondaryColor}
      />
      <path
        d="M126.5 140C123.95 140 122 138.109 122 135.636V128.364C122 125.891 123.95 124 126.5 124C129.05 124 131 125.891 131 128.364V135.636C131 138.109 129.05 140 126.5 140Z"
        fill={secondaryColor}
      />
      <path
        d="M126.5 159C123.95 159 122 157.109 122 154.636V147.364C122 144.891 123.95 143 126.5 143C129.05 143 131 144.891 131 147.364V154.636C131 157.109 129.05 159 126.5 159Z"
        fill={secondaryColor}
      />
      <path
        d="M126.5 176C123.95 176 122 174.18 122 171.8V166.2C122 163.82 123.95 162 126.5 162C129.05 162 131 163.82 131 166.2V171.8C131 174.18 129.05 176 126.5 176Z"
        fill={secondaryColor}
      />
      <path
        d="M125.821 179.206C82.2807 179.206 46.7495 132.171 44.4196 73.0495C44.274 69.7003 46.8951 67.0791 50.2444 67.0791C53.448 67.0791 55.9236 69.5546 56.0692 72.7583C58.1079 125.473 88.5424 167.557 125.821 167.557C163.1 167.557 193.534 125.473 195.573 72.7583C195.718 69.5546 198.194 67.0791 201.398 67.0791C204.747 67.0791 207.368 69.8459 207.222 73.0495C204.893 132.171 169.361 179.206 125.821 179.206Z"
        fill={secondaryColor}
      />
      <path
        d="M184.215 195.953L189.311 189.837C190.331 188.527 190.185 186.779 188.874 185.76C187.564 184.74 185.816 184.886 184.797 186.197L180.428 191.439L176.06 186.197C175.041 184.886 173.147 184.74 171.983 185.76C170.672 186.779 170.526 188.672 171.546 189.837L176.642 195.953L171.546 202.069C170.526 203.38 170.672 205.127 171.983 206.147C172.565 206.583 173.147 206.875 173.876 206.875C174.749 206.875 175.623 206.583 176.06 205.855L180.428 200.613L184.797 205.855C185.38 206.583 186.253 206.875 186.981 206.875C187.564 206.875 188.292 206.729 188.874 206.147C190.185 205.127 190.331 203.234 189.311 202.069L184.215 195.953Z"
        fill={secondaryColor}
      />
      <path
        d="M37.2844 53.1C35.9738 52.2263 34.2264 52.5175 33.207 53.8281L28.2559 60.6722L23.3049 53.8281C22.4312 52.5175 20.5381 52.2263 19.2275 53.1C17.917 53.9737 17.6257 55.8668 18.4994 57.1774L25.3436 66.497V71.4481C25.3436 73.0499 26.6541 74.3605 28.2559 74.3605C29.8578 74.3605 31.1683 73.0499 31.1683 71.4481V66.497L37.8669 57.0317C38.8862 55.8668 38.5949 53.9737 37.2844 53.1Z"
        fill={secondaryColor}
      />
      <path
        d="M172.419 150.082H157.857C156.256 150.082 154.945 148.772 154.945 147.17V145.714C154.945 140.035 159.459 135.52 165.138 135.52C167.614 135.52 169.507 133.627 169.507 131.152C169.507 129.987 169.07 128.968 168.196 128.094C167.323 127.22 166.303 126.783 165.138 126.783C162.663 126.783 160.77 128.676 160.77 131.152C160.77 132.754 159.459 134.064 157.857 134.064C156.256 134.064 154.945 132.754 154.945 131.152C154.945 125.473 159.459 120.958 165.138 120.958C167.76 120.958 170.381 121.978 172.274 124.017C174.167 126.055 175.332 128.531 175.332 131.152C175.332 136.831 170.818 141.345 165.138 141.345C163.245 141.345 161.644 142.51 161.061 144.258H172.419C174.021 144.258 175.332 145.568 175.332 147.17C175.332 148.772 174.021 150.082 172.419 150.082Z"
        fill={tertiaryColor}
      />
      <path
        d="M186.981 129.696H179.7C178.827 129.696 178.244 129.113 178.244 128.24V127.512C178.244 124.745 180.574 122.415 183.341 122.415C184.069 122.415 184.651 122.124 185.088 121.541C185.525 120.959 185.671 120.376 185.525 119.648C185.38 118.92 184.797 118.337 184.069 118.192C183.341 118.046 182.613 118.192 182.176 118.629C181.593 119.066 181.302 119.648 181.302 120.376C181.302 121.25 180.72 121.832 179.846 121.832C178.972 121.832 178.39 121.25 178.39 120.376C178.39 118.774 179.118 117.318 180.428 116.299C181.739 115.279 183.341 114.988 184.943 115.425C186.69 115.862 188.146 117.318 188.583 119.066C189.02 120.667 188.729 122.269 187.709 123.58C186.69 124.89 185.234 125.618 183.632 125.618C182.613 125.618 181.885 126.201 181.593 127.075H187.273C188.146 127.075 188.729 127.657 188.729 128.531C188.729 129.405 187.855 129.696 186.981 129.696Z"
        fill={tertiaryColor}
      />
      <path
        d="M217.266 169.865L205.586 158.151C203.251 155.808 199.601 155.808 197.265 158.151C194.929 160.494 194.929 164.154 197.265 166.497L199.017 168.254H55.505V43.2067L57.2569 44.9638C58.4248 45.9888 59.8848 46.5745 61.3447 46.5745C62.8047 46.5745 64.2646 45.9888 65.4325 44.8174C67.7684 42.4746 67.7684 38.814 65.4325 36.4711L53.753 24.7571C51.4172 22.4143 47.7673 22.4143 45.4314 24.7571L33.7519 36.4711C31.416 38.814 31.416 42.4746 33.7519 44.8174C36.0878 47.1602 39.7377 47.1602 42.0736 44.8174L43.8255 43.0603V168.108H37.9857C34.7739 168.108 32.146 170.743 32.146 173.965C32.146 177.186 34.7739 179.822 37.9857 179.822H43.8255V187.143C43.8255 190.364 46.4534 193 49.6652 193C52.8771 193 55.505 190.364 55.505 187.143V179.822H199.017L197.265 181.579C194.929 183.922 194.929 187.582 197.265 189.925C198.433 191.096 199.893 191.682 201.353 191.682C202.813 191.682 204.272 191.096 205.44 189.925L217.12 178.211C219.602 175.868 219.602 172.061 217.266 169.865Z"
        fill={tertiaryColor}
      />
      <path
        d="M101.794 88.9219H74.1261C72.8155 88.9219 71.6505 89.65 70.9224 90.6693L50.0988 126.201L42.381 114.551C41.216 112.804 39.0317 112.367 37.2843 113.532C35.5368 114.697 35.1 116.881 36.2649 118.628L47.1864 135.083C47.9145 136.103 49.0795 136.685 50.2444 136.685C50.2444 136.685 50.2444 136.685 50.39 136.685C51.7006 136.685 52.8656 135.957 53.4481 134.938L76.3103 96.3485H101.794C103.832 96.3485 105.434 94.7467 105.434 92.708C105.434 90.6693 103.832 88.9219 101.794 88.9219Z"
        fill={secondaryColor}
      />
      <path
        d="M87.086 99.9893C79.9507 99.9893 74.2715 105.814 74.2715 112.804C74.2715 119.939 80.0963 125.618 87.086 125.618C88.9791 125.618 90.8721 125.181 92.4739 124.453C92.3283 125.764 91.7459 126.783 90.8721 127.803C88.8335 129.841 85.193 129.841 83.0087 127.803C81.5525 126.346 79.2226 126.346 77.7664 127.803C76.3102 129.259 76.3102 131.589 77.7664 133.045C80.2419 135.52 83.4455 136.831 86.9404 136.831C90.4353 136.831 93.6389 135.52 96.1144 133.045C98.59 130.569 99.9006 127.366 99.9006 123.871V112.804C99.9006 105.668 94.0758 99.9893 87.086 99.9893ZM87.086 118.337C84.028 118.337 81.5525 115.862 81.5525 112.804C81.5525 109.746 84.028 107.27 87.086 107.27C90.144 107.27 92.6196 109.746 92.6196 112.804C92.6196 115.862 90.144 118.337 87.086 118.337Z"
        fill={secondaryColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1970_14791"
        x="-12"
        y="23"
        width="261"
        height="243.875"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.4 0 0 0 0 0.588235 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1970_14791" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1970_14791" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default AlgebraIcon;
