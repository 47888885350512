import { StringifiableRecord } from '../models';
import { buildUrl } from '../utils';

export type Fetcher = (url: string, init?: RequestInit) => Promise<Response>;

export abstract class BaseApi {
  protected constructor(protected readonly fetcher: Fetcher, protected readonly baseUrl?: string) {}

  protected request = async <T>(path: string, config: RequestInit): Promise<T> => {
    const requestUrl = [this.baseUrl, path].filter(Boolean).join('');
    const response = await this.fetcher.call(null, requestUrl, config);

    if (response.ok) {
      return response.json();
    }

    throw new Error(response.statusText);
  };

  protected get = <T>(path: string, params?: StringifiableRecord): Promise<T> => {
    return this.request(buildUrl(path, params), { method: 'get' });
  };

  protected delete = <T>(path: string, params?: StringifiableRecord): Promise<T> => {
    return this.request(buildUrl(path, params), { method: 'delete' });
  };

  protected post = <T, DT = T>(path: string, data?: DT, params?: StringifiableRecord): Promise<T> => {
    return this.request(buildUrl(path, params), {
      method: 'post',
      body: JSON.stringify(data)
    });
  };

  protected put = <T, DT = T>(path: string, data?: DT, params?: StringifiableRecord): Promise<T> => {
    return this.request(buildUrl(path, params), {
      method: 'put',
      body: JSON.stringify(data)
    });
  };

  protected patch = <T, DT = T>(path: string, data?: DT): Promise<T> => {
    return this.request(path, {
      method: 'patch',
      body: JSON.stringify(data)
    });
  };
}
