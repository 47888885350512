import classNames from 'classnames';
import { FC } from 'react';
import { CollapsibleItemProvider } from './CollapsibleItemProvider';

type CollapsibleListItemProps = {
  collapsed?: boolean;
  className?: string;
};

export const CollapsibleItem: FC<CollapsibleListItemProps> = ({ collapsed, children, className }) => (
  <CollapsibleItemProvider collapsed={collapsed}>
    <div className={classNames('accordion-item', className)}>{children}</div>
  </CollapsibleItemProvider>
);
