import classNames from 'classnames';
import { useCallback, useRef } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useLesson } from '../../providers';
import { useTextOverflow } from '../../hooks/useTextOverflow';
import { LessonSubjectColors } from '../../constants';
import { SubjectIcon } from '../SubjectIcon';
import { TooltipTrigger } from '../TooltipTrigger';
import { Text } from '../Text';

export const LessonDetailsHeader = () => {
  const { role, lesson, onStart } = useLesson();
  const headerRef = useRef<HTMLHeadingElement>(null);
  const headerOverflow = useTextOverflow(headerRef);

  const handleStart = useCallback(() => onStart(), [onStart]);

  if (!lesson) {
    return null;
  }

  const { baseColorName = 'green', ...iconColors } = LessonSubjectColors[lesson.subject?.code];

  return (
    <section
      className={`lesson-details__header d-flex flex-column p-3 py-4 p-md-45 pb-4 pb-lg-45 lesson-details__header--${baseColorName}`}
    >
      <SubjectIcon code={lesson.subject?.code} size="lg" className="lesson-details__header-bg" {...iconColors} />
      <div className="d-flex flex-wrap pe-lg-13">
        <Badge bg="light" className="mb-2">
          Урок
        </Badge>
        {lesson.subject && (
          <Badge bg="light" className="mb-2">
            {lesson.subject.name}
          </Badge>
        )}
        {lesson.classNumber && (
          <Badge bg="light" className="mb-2">
            {lesson.classNumber.name} класс
          </Badge>
        )}
        {lesson.studyLevel && (
          <Badge bg="light" className="mb-2">
            {lesson.studyLevel.name}
          </Badge>
        )}
      </div>
      <div className="flex-grow-1 mt-3 pe-lg-13">
        {lesson.lessonTopic && (
          <h1 ref={headerRef} className={classNames({ 'over-text': headerOverflow })}>
            <Text>{lesson.lessonTopic.name}</Text>
            <div className="lesson__edit-actions">
              {headerOverflow && (
                <TooltipTrigger id={lesson.uid} placement="left-start" title="Полное название урока">
                  <Text>{lesson.lessonTopic.name}</Text>
                </TooltipTrigger>
              )}
            </div>
          </h1>
        )}
        {lesson.author && <h2 className="subheader-2 fw-normal mt-3">Автор: {lesson.author}</h2>}
        {lesson.authorLeader && (
          <h2 className="subheader-3 fw-normal mt-3">Руководитель авторского коллектива: {lesson.authorLeader}</h2>
        )}
      </div>
      <div className="lesson-details__header-buttons d-flex flex-column flex-md-row align-items-start justify-content-end align-items-lg-center mt-3 ">
        <Button
          data-onboarding-id="lesson-start-button"
          variant="primary"
          size="lg"
          className="ms-lg-3 fw-normal"
          onClick={handleStart}
        >
          {role === 'teacher' ? 'Посмотреть' : 'Приступить к выполнению'}
        </Button>
      </div>
    </section>
  );
};
