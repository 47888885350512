export enum LessonSubjectCode {
  Algebra = '02.2', // Алгебра
  Biology = '06', // Биология
  Chemistry = '04', // Химия
  ComputerScience = '05', // Информатика
  English = '09', // Английский язык
  GeneralHistory = '07.2', // Всеобщая история
  Geometry = '02.3', // Геометрия
  Maths = '02.1', // Математика
  Physics = '03', // Физика
  ProbabilityAndStatistics = '02.4', // Вероятность и статистика
  RussiaHistory = '07.1', // История России
  RussianLanguage = '01', // Русский язык
  ClassHour = '55', // Классный час
  Music = '56', // Музыка
  RussianSpiritualAndMoralCulture = '57', // Основы духовно-нравственной культуры народов России
  RussianReligiousCultureAndSecularEthics = '58' // Основы религиозных культур и светской этики. Основы светской этики
}
