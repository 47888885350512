import { useCallback, useEffect, useState } from 'react';

type LoadScript = {
  ready: boolean;
  error?: Error;
};

export const useLoadScript = (src: string, module = false, check = false): LoadScript => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState<Error>();

  const handleLoad = () => setReady(true);

  const handleError = useCallback(() => setError(new Error(`Unable to load script "${src}".`)), [src]);

  useEffect(() => {
    if (check && document.querySelector(`script[src="${src}"]`)) {
      setReady(true);
      return;
    }

    const script = document.createElement('script');

    script.setAttribute('src', src);
    script.setAttribute('async', 'async');

    if (module) {
      script.setAttribute('type', 'module');
    }

    script.addEventListener('load', handleLoad);
    script.addEventListener('error', handleError);

    document.body.appendChild(script);

    return () => {
      script.removeEventListener('load', handleLoad);
      script.removeEventListener('error', handleError);
    };
  }, [src, check, module, handleError]);

  return { ready, error };
};
