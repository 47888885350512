import { LessonSubjectCode, LessonSubjectColors } from '../../../../constants';
import { SubjectIcon } from '../types';

const iconColors = LessonSubjectColors[LessonSubjectCode.ProbabilityAndStatistics];

const ProbabilityAndStatisticsIcon: SubjectIcon = ({
  primaryColor = iconColors.primaryColor,
  secondaryColor = iconColors.secondaryColor,
  tertiaryColor = iconColors.tertiaryColor,
  quaternaryColor = iconColors.quaternaryColor,
  ...props
}) => (
  <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24.9003 19.8836H20.627C20.0577 19.8836 19.592 19.4191 19.592 18.8513V14.9182H17.9061C17.3369 14.9182 16.8711 14.4537 16.8711 13.8859V9.50213H14.8053H10.035C9.46573 9.50213 9 9.03759 9 8.46981C9 7.90204 9.46573 7.4375 10.035 7.4375H14.872H17.9061C18.4753 7.4375 18.9411 7.90204 18.9411 8.46981V12.8536H20.627C21.1962 12.8536 21.6619 13.3181 21.6619 13.8859V17.819H23.8653V13.8859C23.8653 13.3181 24.331 12.8536 24.9003 12.8536H29.5576C30.1268 12.8536 30.5925 13.3181 30.5925 13.8859C30.5925 14.4537 30.1268 14.9182 29.5576 14.9182H25.9352V18.8513C25.9352 19.4191 25.4695 19.8836 24.9003 19.8836Z"
      fill={primaryColor}
    />
    <path
      d="M11.7483 4.94879L12.1479 4.58344V32.4874H14.8117V4.58344L15.2113 4.982C15.4777 5.2145 15.8107 5.34735 16.1437 5.34735C16.4767 5.34735 16.8097 5.2145 17.076 4.94879C17.6088 4.41737 17.6088 3.58704 17.076 3.05563L14.4122 0.39856C13.8794 -0.132853 13.0469 -0.132853 12.5141 0.39856L9.85027 3.05563C9.31749 3.58704 9.31749 4.41737 9.85027 4.94879C10.383 5.4802 11.2488 5.4802 11.7483 4.94879Z"
      fill={tertiaryColor}
    />
    <path
      d="M45.5539 32.2722L45.9192 32.6718L18.2407 32.6718L18.2407 35.3357L45.9192 35.3357L45.5207 35.7352C45.2882 36.0016 45.1553 36.3346 45.1553 36.6676C45.1553 37.0006 45.2882 37.3336 45.5539 37.6C46.0853 38.1327 46.9156 38.1327 47.4471 37.6L50.1041 34.9361C50.6355 34.4033 50.6355 33.5708 50.1041 33.0381L47.4471 30.3742C46.9156 29.8414 46.0853 29.8414 45.5539 30.3742C45.0225 30.907 45.0225 31.7727 45.5539 32.2722Z"
      fill={tertiaryColor}
    />
    <path
      d="M23.9278 35.3839H14.2273C12.5559 35.3839 12.1147 34.9462 12.1147 33.2791V23.8236C12.1147 22.1564 12.5559 21.7188 14.2273 21.7188H23.9278C25.5992 21.7188 25.815 22.1564 25.815 23.8236V33.2791C25.815 34.9462 25.5992 35.3839 23.9278 35.3839Z"
      fill={secondaryColor}
    />
    <path
      d="M17.001 27.4952C16.4376 27.4952 15.9868 27.0444 15.9868 26.481C15.9868 25.9175 16.4376 25.4668 17.001 25.4668C17.5645 25.4668 18.0152 25.9175 18.0152 26.481C18.0152 27.0444 17.5645 27.4952 17.001 27.4952Z"
      fill={primaryColor}
    />
    <path
      d="M17.001 31.1016C16.4376 31.1016 15.9868 30.6509 15.9868 30.0874C15.9868 29.524 16.4376 29.0732 17.001 29.0732C17.5645 29.0732 18.0152 29.524 18.0152 30.0874C18.0152 30.6509 17.5645 31.1016 17.001 31.1016Z"
      fill={primaryColor}
    />
    <path
      d="M20.7574 27.4952C20.1939 27.4952 19.7432 27.0444 19.7432 26.481C19.7432 25.9175 20.1939 25.4668 20.7574 25.4668C21.3208 25.4668 21.7716 25.9175 21.7716 26.481C21.7716 27.0444 21.3208 27.4952 20.7574 27.4952Z"
      fill={primaryColor}
    />
    <path
      d="M20.8321 31.1016C20.2686 31.1016 19.8179 30.6509 19.8179 30.0874C19.8179 29.524 20.2686 29.0732 20.8321 29.0732C21.3955 29.0732 21.8463 29.524 21.8463 30.0874C21.8463 30.6509 21.3955 31.1016 20.8321 31.1016Z"
      fill={primaryColor}
    />
    <path
      d="M38.843 35.3839H29.3679C27.6965 35.3839 27.2554 34.9462 27.2554 33.2791V24.049C27.2554 22.3818 27.6965 21.7188 29.3679 21.7188H38.843C40.5145 21.7188 40.9556 22.3818 40.9556 24.049V33.0537C40.9556 34.7209 40.5145 35.3839 38.843 35.3839Z"
      fill={primaryColor}
    />
    <path
      d="M31.8758 31.6915C31.3123 31.6915 30.8616 31.2407 30.8616 30.6773C30.8616 30.1138 31.3123 29.6631 31.8758 29.6631C32.4392 29.6631 32.89 30.1138 32.89 30.6773C32.89 31.2407 32.4392 31.6915 31.8758 31.6915Z"
      fill={tertiaryColor}
    />
    <path
      d="M36.5337 27.2706C35.9703 27.2706 35.5195 26.8198 35.5195 26.2564C35.5195 25.6929 35.9703 25.2422 36.5337 25.2422C37.0972 25.2422 37.5479 25.6929 37.5479 26.2564C37.5479 26.8198 37.0972 27.2706 36.5337 27.2706Z"
      fill={tertiaryColor}
    />
    <path
      d="M34.2046 29.3673C33.6412 29.3673 33.1904 28.9165 33.1904 28.3531C33.1904 27.7896 33.6412 27.3389 34.2046 27.3389C34.7681 27.3389 35.2188 27.7896 35.2188 28.3531C35.2188 28.9165 34.7681 29.3673 34.2046 29.3673Z"
      fill={tertiaryColor}
    />
    <path
      d="M34.1171 20.2164C33.3225 20.2164 32.5552 19.9157 31.9798 19.3418L26.5545 13.9304C25.9791 13.3564 25.6777 12.6185 25.6777 11.7986C25.6777 10.9787 25.9791 10.2408 26.5545 9.66684L31.9798 4.25543C32.5552 3.68149 33.295 3.38086 34.1171 3.38086C34.9391 3.38086 35.6789 3.68149 36.2543 4.25543L41.6796 9.66684C42.255 10.2408 42.5564 10.9787 42.5564 11.7986C42.5564 12.6185 42.255 13.3564 41.6796 13.9304L36.2543 19.3418C35.6789 19.9157 34.9117 20.2164 34.1171 20.2164Z"
      fill={secondaryColor}
    />
    <path
      d="M30.9742 12.6212C30.7067 12.6212 30.4615 12.5315 30.2609 12.3298C29.8597 11.9264 29.8597 11.2988 30.2609 10.8954C30.6621 10.4919 31.2862 10.4919 31.6874 10.8954C32.0887 11.2988 32.0887 11.9264 31.6874 12.3298C31.4868 12.5315 31.2193 12.6212 30.9742 12.6212Z"
      fill={primaryColor}
    />
    <path
      d="M34.1294 15.7999C33.859 15.7999 33.6111 15.6872 33.4082 15.5069C33.2054 15.3266 33.1152 15.0561 33.1152 14.7857C33.1152 14.5152 33.2279 14.2673 33.4082 14.0645C33.5885 13.8616 33.859 13.7715 34.1294 13.7715C34.3999 13.7715 34.6478 13.8842 34.8506 14.0645C35.0535 14.2673 35.1436 14.5152 35.1436 14.7857C35.1436 15.0561 35.0309 15.304 34.8506 15.5069C34.6478 15.7097 34.3999 15.7999 34.1294 15.7999Z"
      fill={primaryColor}
    />
    <path
      d="M34.1294 9.46589C33.859 9.46589 33.6111 9.3532 33.4082 9.1729C33.2054 8.9926 33.1152 8.72214 33.1152 8.45169C33.1152 8.18124 33.2279 7.93333 33.4082 7.73049C33.5885 7.52765 33.859 7.4375 34.1294 7.4375C34.3999 7.4375 34.6478 7.55019 34.8506 7.73049C35.0535 7.93333 35.1436 8.18124 35.1436 8.45169C35.1436 8.72214 35.0309 8.97006 34.8506 9.1729C34.6478 9.3532 34.3999 9.46589 34.1294 9.46589Z"
      fill={primaryColor}
    />
    <path
      d="M34.1294 12.6212C33.8619 12.6212 33.6168 12.5085 33.4161 12.3282C33.0149 11.9225 33.0149 11.2914 33.4161 10.8858C33.5945 10.6829 33.8619 10.5928 34.1294 10.5928C34.3969 10.5928 34.6421 10.7055 34.8427 10.8858C35.2439 11.2914 35.2439 11.9225 34.8427 12.3282C34.6421 12.5085 34.3969 12.6212 34.1294 12.6212Z"
      fill={primaryColor}
    />
    <path
      d="M37.1726 12.6212C36.9052 12.6212 36.66 12.5315 36.4594 12.3298C36.0581 11.9264 36.0581 11.2988 36.4594 10.8954C36.8606 10.4919 37.4847 10.4919 37.8859 10.8954C38.2871 11.2988 38.2871 11.9264 37.8859 12.3298C37.6853 12.5315 37.4401 12.6212 37.1726 12.6212Z"
      fill={primaryColor}
    />
  </svg>
);

export default ProbabilityAndStatisticsIcon;
