import { MediaType } from 'plyr';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { EomComponentProps } from './EomComponentProps';
import { useLesson } from '../../../providers';
import { LessonTaskPlaybackMethodCode, Verb } from '../../../constants';
import { EomMessageData } from './EomEvent';
import { PlyrPlayer, PlyrPlayerProps } from '../../player';

export const EomMediaPlayer = ({ onMessage }: EomComponentProps) => {
  const { activeTask, result } = useLesson();
  const launched = useRef(false);
  const seekStartTime = useRef(0);

  const dispatchEomEvent = useCallback(
    (verb: Verb, info?: EomMessageData['info']) => {
      if (!activeTask) return;

      onMessage?.({ eomId: activeTask.id, message: { verb, info } });
    },
    [activeTask, onMessage]
  );

  const type = useMemo(
    () => getMediaType(activeTask?.eomType.taskPlaybackMethod?.code),
    [activeTask?.eomType.taskPlaybackMethod?.code]
  );

  const plyrProps = useMemo<PlyrPlayerProps | undefined>(() => {
    if (!activeTask || !type) return undefined;

    return {
      source: {
        type,
        poster: getPosterUrl(activeTask.fileList),
        sources: getSources(activeTask.fileList)
      },
      options: {
        listeners: {
          seek() {
            seekStartTime.current = (this as unknown as Plyr).currentTime;
          }
        }
      },
      listeners: {
        play: () => {
          const verb = launched.current ? Verb.Played : Verb.Launched;

          if (!launched.current) {
            launched.current = true;
          }

          dispatchEomEvent(verb);
        },
        pause: () => dispatchEomEvent(Verb.Paused),
        seeked: (evt) => {
          const value = evt.detail.plyr.currentTime;
          const diff = value - seekStartTime.current;
          const direction = diff <= 0 ? 'backward' : 'forward';

          dispatchEomEvent(Verb.Seeked, { value, diff, direction });
        },
        ratechange: (evt) => dispatchEomEvent(Verb.SpeedChanged, { value: evt.detail.plyr.speed }),
        ended: () => dispatchEomEvent(Verb.Passed),
        captionsenabled: () => dispatchEomEvent(Verb.SubtitlesChanged, { value: true }),
        captionsdisabled: () => dispatchEomEvent(Verb.SubtitlesChanged, { value: false }),
        ready: [() => dispatchEomEvent(Verb.Started), true]
      }
    };
  }, [activeTask, dispatchEomEvent, type]);

  useEffect(() => {
    if (!activeTask?.id) return;

    return () => {
      onMessage?.({ eomId: activeTask.id, message: { verb: Verb.Completed } });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    launched.current = false;
  }, [result, activeTask]);

  if (!activeTask || !plyrProps) return null;

  return (
    <Container>
      <h3 className="mt-4">{activeTask.name}</h3>

      <div className={`lesson-task__content lesson-task__content_${type}`}>
        <PlyrPlayer key={activeTask.uid} {...plyrProps} />
      </div>
    </Container>
  );
};

const getMediaType = (playbackMethod?: LessonTaskPlaybackMethodCode): MediaType | undefined => {
  switch (playbackMethod) {
    case LessonTaskPlaybackMethodCode.Audio:
      return 'audio';
    case LessonTaskPlaybackMethodCode.Video:
      return 'video';
    default:
      return undefined;
  }
};

const getFileType = (src?: string) => {
  const [ext] = (src || '').toLowerCase().split('.').reverse();

  switch (ext) {
    // Audio
    case 'mp3':
      return 'audio/mp3';
    case 'ogg':
      return 'audio/ogg';

    // Video
    case 'mp4':
      return 'video/mp4';
    case 'webm':
      return 'video/webm';

    default:
      return undefined;
  }
};

const getVideoSize = (src: string) => {
  const [size] = ((src || '').match(/(mp4|webm)_(\d+)/i) || []).reverse();

  return parseInt(size, 10) || undefined;
};

const getSources = (fileList: string[]) => {
  const sources = [];

  for (const src of fileList) {
    const type = getFileType(src);

    if (!type) {
      continue;
    }

    if (type.startsWith('video/')) {
      const size = getVideoSize(src);

      sources.push({ src, type, size });
    } else {
      sources.push({ src, type });
    }
  }

  return sources;
};

const getPosterUrl = (fileList: string[]) => {
  return fileList.find((path) => /\/poster\.(jpe?g|png)$/i.test(path));
};
