import { useState } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

export const useOptimizedResize = (): [number, number] => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useIsomorphicLayoutEffect(() => {
    let timer: number;
    let canceled = false;

    function handleResize() {
      timer = requestAnimationFrame(() => {
        if (canceled) return;

        const { innerHeight, innerWidth } = window;

        setWidth(innerWidth);
        setHeight(innerHeight);
      });
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    handleResize();

    return () => {
      canceled = true;

      if (timer) {
        cancelAnimationFrame(timer);
      }

      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [width, height];
};
