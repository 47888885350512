import { BaseApi } from './BaseApi';
import { IModelApi } from './ModelApi';
import { IListApi } from './ListApi';
import { StringifiableRecord } from '../models';

export interface IModelListApi<T, LT, ID = string, Q extends StringifiableRecord = StringifiableRecord>
  extends IModelApi<T, ID>,
    IListApi<LT, Q> {}

export abstract class ModelListApi<T, LT = T, ID = string, Q extends StringifiableRecord = StringifiableRecord>
  extends BaseApi
  implements IModelListApi<T, LT, ID, Q>
{
  public getOne = (id: ID) => this.get<T>(`/${id}`);

  public getList = (query?: Q) => this.get<LT[]>('', query);
}
