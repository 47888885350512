import { Options, SourceInfo } from 'plyr';

import { DefaultPlyrOptions } from './DefaultPlyrOptions';

export const getPlyrOptions = (source?: SourceInfo, options?: Options): Options => {
  if (!source || source.type === 'audio') {
    return { ...DefaultPlyrOptions, ...options };
  }

  const qualityOptions = Array.from(new Set(source.sources.map((s) => s.size || 480)).values()).sort((a, b) => b - a);

  const screenHeight = typeof window !== 'undefined' ? window.screen.height : 900;
  const defaultQuality = qualityOptions.reduce((a, b) => {
    return Math.abs(a - screenHeight) < Math.abs(b - screenHeight) ? a : b;
  });

  return {
    ...DefaultPlyrOptions,
    quality: {
      default: defaultQuality,
      options: qualityOptions
    },
    ...options
  };
};
