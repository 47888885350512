import { useCallback, VFC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TaskPlayer } from '@ru-edu/ecl-player';
import { LoadingIndicator } from '../LoadingIndicator';
import { Error404 } from '../error';
import { useAuth, useLessonApi } from '../providers';
import { useRequest } from '../../hooks';
import { UserRole } from '../../constants';

type TaskDetailsParams = {
  uid: string;
};

export const TaskDetails: VFC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { uid } = useParams<TaskDetailsParams>();
  const { lessonTaskApi } = useLessonApi();
  const { result: task, loading } = useRequest(
    () => (uid ? lessonTaskApi.getOne(uid) : undefined),
    [lessonTaskApi, uid]
  );

  const handleBackClick = useCallback(() => navigate('/tasks'), [navigate]);

  if (loading) return <LoadingIndicator />;
  if (!task) return <Error404 />;

  const userIsTeacher = user?.roles.includes(UserRole.Teacher);

  return <TaskPlayer role={userIsTeacher ? 'teacher' : 'student'} task={task} onBackClick={handleBackClick} />;
};
